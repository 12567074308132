import React from "react";
import {GlobalIcon, RegionalIcon, LocalIcon} from './LocationIcons'

export const LocationIcon = props => {
	const type = props.type;

	switch (type) {
		case 'global':

			return (<GlobalIcon />);
		case 'regional':
		case 'region':
		{

			return (<RegionalIcon />);
		}
		default:

			return (<LocalIcon />);
	}
}

export default LocationIcon;
