import {SET_PREFIX_COLUMN, SET_PREFIX_DIRECTION, SET_MONITORING_COLUMN, SET_MONITORING_DIRECTION} from "../constants";

export const setPrefixColumn = (data) => {

    return {
        type: SET_PREFIX_COLUMN,
        payload: data,
    };
};

export const setPrefixDirection = () => {

    return {
        type: SET_PREFIX_DIRECTION,
        payload: 1,
    };
};

export const setMonitoringColumn = (data) => {

    return {
        type: SET_MONITORING_COLUMN,
        payload: data,
    };
};

export const setMonitoringDirection = () => {

    return {
        type: SET_MONITORING_DIRECTION,
        payload: 1,
    };
};

export default {setPrefixColumn, setPrefixDirection, setMonitoringColumn, setMonitoringDirection};
