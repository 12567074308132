import React from "react";
import { observer } from "mobx-react-lite";
import {connect} from "react-redux";

const CommonTable = observer((props) => {
    const { 
        tableModel, headerRenderers, columnRenderers, 
        className, appendClasses
    } = props
    const data = tableModel.getPageElements();
    const getClassForTd = (row) => {
        if(appendClasses) {
            const classes = appendClasses.filter(ac => row[ac.key] === ac.value).map(ac => ac.class);
            return classes.join(' ');
        }
        return '';
    };
    //alert(1);
    //localStorage.props =JSON.stringify(props);

    return (
        <React.Fragment>
            <div className={["CommonTable", ...([className] ?? [])].join(" ")}>
                <table className="table-first-hidden">
                    <thead>
                        <tr>
                            {headerRenderers.map((r, i) => (
                                <th key={i}>
                                    {r({ tableModel })}
                                </th>
                            ))}
                            {!props.disableSpaceTd && <th className="CommonTable_SpaceTd"></th>}
                        </tr>
                    </thead>
                    <tbody className="hidden">
                    {data.map((row, i) => (
                        <tr key={row[tableModel.idColumn].toString()} className={getClassForTd(row)}>
                            {columnRenderers.map((r, i) => (
                                <td key={row[tableModel.idColumn].toString() + '_' + i.toString()}>
                                    {r({ tableModel, row })}
                                </td>
                            ))}
                            {!props.disableSpaceTd && <td className="CommonTable_SpaceTd"></td>}
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div className="scroll-table__Container">
                    <div className="scroll-table">
                        <table className="table-last-normal">
                            <thead className="hidden">
                                <tr>
                                    {headerRenderers.map((r, i) => (
                                        <th key={i}>
                                            {r({ tableModel })}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                            {data.map((row, i) => (
                                <tr
                                    key={row[tableModel.idColumn].toString()}
                                    className={getClassForTd(row)}
                                >
                                    {columnRenderers.map((r, i) => (
                                        <td key={row[tableModel.idColumn].toString() + '_' + i.toString()}>
                                            {r({ tableModel, row })}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
});

const mapStateToProps = (state) => {
    return {
        showSortPage: state.showSortPage,
    };
};

const mapDispatchToProps = dispatch => {
    return { };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommonTable);
