import getPrefixType from "../../Prefix/getPrefixType";

const monitoringSortPrefix = (a, b, order) => {
	let result;
	if (getPrefixType(String(a)) !== getPrefixType(String(b))) {
		return sortByType(a, b, order);
	} else {
		result = sortBySubNet(a, b, order);
	}
	// Проверяем сортировку по подсети
	if (result) {

		return result;
	} else {
		//иначе сортируем по ip
		return sortByIp(a, b, order)
	}

}

const sortByType = (a, b, order) => {
	if (order === 'asc') {

		return getPrefixType(a) - getPrefixType(b);
	} else {

		return getPrefixType(b) - getPrefixType(a);
	}
}

const sortBySubNet = (a, b, order) => {
	const subNetA = a.split('/')[1];
	const subNetB = b.split('/')[1];
	if (order === 'asc') {

		return subNetA - subNetB;
	} else {

		return subNetB - subNetA;
	}

}

const sortByIp = (a, b, order) => {
	if (getPrefixType(a) === 4) {

		return sortByIp4(a, b, order);
	} else {

		return sortByIp6(a, b, order);
	}
}
const sortByIp4 = (a, b, order) => {
	const ipAPieces = a.split('/')[0].split('.');
	const ipBPieces = b.split('/')[0].split('.');
	for (let i = 0; i < ipAPieces.length; i++) {
		if (order === 'asc') {
			if (normalizeLength4(ipAPieces[i]).localeCompare(normalizeLength4(ipBPieces[i]))) {
				return normalizeLength4(ipAPieces[i]).localeCompare(normalizeLength4(ipBPieces[i]));
			}
		} else {
			if (normalizeLength4(ipAPieces[i]).localeCompare(normalizeLength4(ipBPieces[i]))) {
				return normalizeLength4(ipAPieces[i]).localeCompare(normalizeLength4(ipBPieces[i])) * -1;
			}
		}

	}
}

const normalizeLength4 = (ip) => {
	if (ip.length === 1) {
		ip = '00' + ip;
	}
	if (ip.length === 2) {
		ip = '0' + ip;
	}

	return ip;
};

const sortByIp6 = (a, b, order) => {
	const ipAPieces = a.split('/')[0].split('::');
	const ipBPieces = b.split('/')[0].split('::');
	//console.log(ipAPieces, ipBPieces);
	const aLong = normalizeLength6(ipAPieces).split(':').map(ip => {
		return normalizeLengthPieces6(ip);
	}).join(':');
	const bLong = normalizeLength6(ipBPieces).split(':').map(ip => {
		return normalizeLengthPieces6(ip);
	}).join(':');
	if (order === 'asc') {
		return aLong.localeCompare(bLong);
	} else {
		return bLong.localeCompare(aLong);
	}
};

const normalizeLength6 = (ipArr) => {
	const left = ipArr[0].split(':').length;
	const right = ipArr[1].split(':').length;
	const center = 8 - left - right;
	let centerStr = '';
	for (let i = 0; i <= center; i++) {
		centerStr += ':0000';
	}
	centerStr += (ipArr[1] !== '') ? ':' : '';
	const result = ipArr[0] + centerStr + ipArr[1];

	return result;
}

const normalizeLengthPieces6 = (ip) => {
	while (ip.length < 4 && ip !== '') {
		ip = '0' + ip;
	}

	return ip;
}

const deleteLastEmptyElement = () => {
	return 0;
}

export default monitoringSortPrefix;
