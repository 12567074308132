import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import store from './redux/store';

import App from './App';
import './styles/index.css';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter } from 'react-router-dom';

//if(document.location.host === 'as-path.ru' && document.location.hash !== '#debug') {
if(document.location.host === 'daspath.ru' && document.location.hash !== '#debug') {
    console.log = console.warn = console.error = () => {};
}

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();