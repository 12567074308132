import React, {Fragment} from "react";
import {GlobalIcon, RegionalIcon, LocalIcon} from "../UI/LocationIcons";
import * as PoolAPI from "../../helpers/api/Pool";
import Commentary from "../Commentary/Commentary";

const PrefixInfo = props => {
    const {prefix, setPrefix} = props;

        return (
            <Fragment>
                <div className="prefix-info-desktop">
                    <p className="prefix-info__first-row">
                        <span className="prefix-info__name"
                              title="Исследуемый префикс">{prefix.name}</span>
                        <span className="prefix-info__as"
                              title="Автономная система, содержащая префикс">{prefix.as}</span>
                        <Commentary text={prefix.comment} onSave={(text) => {
                            PoolAPI.updateResource(localStorage.poolId, {
                                resource: prefix.name,
                                type: "prefix",
                                comment: text
                            });
                            setPrefix({ ...prefix, comment: text });
                        }}/>
                    </p>
                        <div className="prefix--places-container">
                            <span key="1" className="as_length__group"
                                  title="Минимальный, средний и максимальный путь до глобальных точек наблюдения">
                                <GlobalIcon/>
                                {prefix.global.map((item, index) => (
                                    <span className="as_length" key={index}>{item}</span>
                                ))}
                            </span>
                                <span key="2" className="as_length__group"
                                      title="Минимальный, средний и максимальный путь до российских точек наблюдения">
                                <RegionalIcon/>
                                    {prefix.regional.map((item, index) => (
                                        <span className="as_length" key={index}>{item}</span>
                                    ))}
                            </span>
                                <span key="3" className="as_length__group"
                                      title="Минимальный, средний и максимальный путь до точек обмена трафиком">
                                <LocalIcon/>
                                    {prefix.local.map((item, index) => (
                                        <span className="as_length" key={index}>{item}</span>
                                    ))}
                            </span>
					    </div>
                    <div className="prefix-info__second-row">

                    </div>
                </div>
            </Fragment>
        );

};
export default PrefixInfo;
