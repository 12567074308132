// Странциа "О проекте"

import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import * as MiscAPI from "../../helpers/api/Misc";
import { EMAIL_REGEX } from "../../helpers/RegEx";
import Headers from "../../helpers/Headers";
import Routes from '../../helpers/Routes/Routes';
import CrossIcon from "../UI/CrossIcon";
import EnvelopeIcon from "../UI/EnvelopeIcon";
import Multicaptcha from "../Multicaptcha/Multicaptcha";
import "../../styles/about.css";
import aboutPrefix from '../../img/about-prefix.png';

const About = (props) => {
	const {user} = props;
	const openedStyles = {
		span: {
			transform: "rotate(45deg)",
			transitionDelay: 0,
			transitionDuration: '0.5s',
		},
		div: {
			height: '100%',
			transitionDelay: 0,
			transitionDuration: '0.5s',
		}
	}

	const [isShown, setIsShown] = useState(false);
	if (user.login && !isShown) {
		setIsShown(true);
	}

	window.addEventListener('resize', () => {
		let divs = document.getElementsByClassName('about__text');
		for (let div of divs) {
			if (div.style.height !== '0px' && div.clientHeight) {
				div.style.height = '0';
				div.style.height = '100%';
				div.style.height = div.offsetHeight + 40 + 'px';
			}
		}
	})

	const afterMount = () => {
		let divs = document.getElementsByClassName('about__text');
		for (let div of divs) {
			if (div.style.height === '100%') {
				div.style.height = '0';
				div.style.height = '100%';
				div.style.height = div.scrollHeight + 10 + 'px';
			}
		}
	};
	useEffect(afterMount);

	const click = (e) => {
		let divs = document.getElementsByClassName('about__text');
		if (e.currentTarget.children[0].style.transform === 'rotate(45deg)') {
			e.currentTarget.children[0].style.transform = 'rotate(0deg)';
			e.currentTarget.parentNode.nextSibling.style.height = e.currentTarget.parentNode.nextSibling.clientHeight + 'px';
			e.currentTarget.parentNode.nextSibling.style.height = '0';
		} else {
			e.currentTarget.children[0].style.transform = 'rotate(45deg)';
			for (let div of divs) {
				if (e.currentTarget.parentNode.nextSibling === div) {
					e.currentTarget.parentNode.nextSibling.style.height = (div.scrollHeight + 50) + 'px';
				}
			}
		}
		e.currentTarget.children[0].style.transitionDelay = 0;
		e.currentTarget.children[0].style.transitionDuration = '0.5s';
	}
	const initialStateForm = {
		lock: false,
		textValue: {
			name: "Текст",
			v: "",
			error: "",
		},
		emailValue: {
			name: "E-mail",
			v: "",
			error: "",
		},
	};
	const [stateForm, setStateForm] = useState(initialStateForm);
	const [stateCaptcha, setStateCaptcha] = useState({needRerender: false, attempts: 0});
	if (isShown && !stateForm.lock && user.tmpMode === false) {
		setStateForm({
			...stateForm,
			lock: true,
			emailValue: {
				...stateForm.emailValue,
				v: user.login,
			},
		});
	}
	useEffect(() => {
		document.title = Headers.about;
	}, [])

	const captchaCallback = (data) => {
		setStateCaptcha({
			...stateCaptcha,
			...data,
			needRerender: false,
			verified: false
		});
	};

	const sendFeedback = async () => {
		const data = {
			email: stateForm.emailValue.v,
			txt: stateForm.textValue.v
		};

		const response = await MiscAPI.sendFeedback(data);
		const {status, reason} = response.data;

		if (status === "error") {
			switch (reason.code) {
				// Неверно указана почта
				case 1022:
					setStateForm({
						...stateForm,
						emailValue: {
							...stateForm.emailValue,
							error: reason.msg,
						}
					})
					break;
				default:
					toast.error(`Code: ${reason.code} / ${reason.msg}`);
					break;
			}
			return;
		}

		toast.success('Спасибо за обратную связь!');
		setStateForm(initialStateForm);
		return;
	}

	const formHandler = async (e) => {
		e.preventDefault();
		const errors = [];

		setStateForm({
			...stateForm,
			textValue: {
				...stateForm.textValue,
				error: (!stateForm.textValue.v && errors.push('')) && 'Поле не заполнено',
			},
			emailValue: {
				...stateForm.emailValue,
				error: (!stateForm.emailValue.v && errors.push('')) && 'Поле не заполнено',
			},
		})
		if (errors.length)
			return;

		if (!EMAIL_REGEX.test(stateForm.emailValue.v)) {

			setStateForm({
				...stateForm,
				emailValue: {
					...stateForm.emailValue,
					error: 'Введен некорректный e-mail',
				},
			});
			return;
		}

		if (!user.isLogin) {
			if (stateCaptcha.verified) {
				sendFeedback();
				return;
			}

		} else {
			sendFeedback();
		}
	};


	return (
			<Fragment>
				{afterMount()}
				<h1 className="main-title">О проекте</h1>
				<section className="about">
					<div className="about__bottom-border">
						<h2 className="secondary-title">Цель проекта
						</h2>
						<span onClick={e => click(e)}>
							<CrossIcon style={openedStyles.span} />
						</span>
					</div>
					<p className="about__text" id="target" style={openedStyles.div}>
						DASpath – это инструмент для мониторинга связности сетевых ресурсов:
						IP-адресов, IP-сетей (блоков IP-адресов) и автономных систем.
						DASPath помогает операторам связи, провайдерам облачных сервисов
						и другим владельцам  сетей принимать правильные решения о том,
						как организовать связность с другими сетями.
						<br />
					</p>
					<div className="about__bottom-border">
						<h2 className="secondary-title">Как использовать сервис DASpath
						</h2>
						<span onClick={e => click(e)}>
							<CrossIcon/>
						</span>
					</div>
					<div className={"about__text"} >
						<p>
Укажите сетевой ресурс (доменное имя, IP-адрес, IP-префикс, автономную систему) и DASpath покажет актуальную длину маршрутов
до него из сетей глобальных и российских операторов связи и точек обмена трафиком. Если ресурсу соответствуют несколько IP-сетей,
DASpath объединит их в пул и покажет минимальные, средние и максимальные значения длин маршрутов до каждого префикса из различных
групп точек наблюдения (крупнейшие мировые операторы, крупнейшие российские операторы, российские точки обмена трафиком).
						</p>
						<p>
Информация сгруппирована на двух основных страницах: на странице мониторинга связности для пула префиксов
(<b><NavLink to={Routes.first}>Мониторинг</NavLink></b>),
а также на странице маршрутной информации для конкретного префикса (<b>Префикс</b>).
На странице <b>Мониторинг</b> приведены минимальная, средняя и максимальная длины маршрутов от групп точек наблюдения
до префиксов из пользовательского пула.
При клике на строку с префиксом вы попадаете на страницу <b>Префикс</b>, на которой показаны маршруты (AS_PATH)
от точек наблюдения до конкретного префикса.
						</p>
						<p>
Для зарегистрированных пользователей DASpath даёт возможность осуществлять мониторинг изменения маршрутов,
а также сравнивать текущее состояния маршрутов с историческими данными.
						</p>
						<p>
Чтобы начать использовать этот функционал, зарегистрируйтесь и добавьте ваши ресурсы в пул.
DASpath начнет анализировать изменения маршрутной информации до ваших префиксов.
<b style={{color: "red"}}>Маршрутная информация собирается один раз в сутки</b> в ночное время.
В случае изменения маршрутов в определенную дату, такая дата отмечается в календаре как дата изменения маршрутной информации.
Изменением маршрутной информации считается изменение конкретных маршрутов,
либо появление данных о маршруте от точки наблюдения до префикса, например при добавлении префикса в пул ресурсов.
						</p>
						<p>
Вы сможете выбирать такие даты в календаре для сравнения маршрутов на конкретную дату с текущим состоянием.
При включенном режиме сравнения текущие маршруты выводятся в верхней строке таблицы маршрутов (AS_PATH) конкретного префикса,
а маршруты на дату сравнения - серым цветом непосредственно под текущим маршрутом.
						</p>
						<p className="img-about_prefix_p">
							<img src={aboutPrefix} className="img-about_prefix"/>
						</p>
						<p>
В настройках пользователя вы можете подписаться на e-mail нотификации об изменениях маршрутов до ваших префиксов.
В случае изменения маршрутной информации вам будет приходить письмо с описанием того, как изменились маршруты.
Кроме того, можно выбрать дни недели для получения полного отчета связности ваших префиксов по e-mail.
						</p>
					</div>

					<form
						action=""
						className="about__form"
						onSubmit={(event) => formHandler(event)}
						noValidate
						>
						<h2 className="secondary-title">Обратная связь</h2>
						<p className="about__form-text">
							Есть вопросы или идеи по улучшению сервиса? Будем рады
							с ними ознакомиться.
						</p>
						<label className={`label-field ${stateForm.textValue.error && 'label-field--error'}`}>
							<div className="label-field__header">
								<span className="label-field__name">
									Напишите здесь сообщение
								</span>
							</div>
							<textarea
								className="textarea"
								value={stateForm.textValue.v}
								maxLength="16000"
								onChange={(e) =>
										setStateForm({
											...stateForm,
											textValue: {
												...stateForm.textValue,
												v: e.target.value,
												error: stateForm.textValue.v && '',
											},
										})
																																																																						}
								></textarea>
							{stateForm.textValue.error && <span className="error label-field__error">{stateForm.textValue.error}</span>}
						</label>
						<label className={`label-field ${stateForm.emailValue.error && 'label-field--error'}`}>
							<div className="label-field__header">
								<span className="label-field__name">
									E-mail для обратной связи
								</span>
							</div>
							<input
								className={"field " + (!stateForm.emailValue.v ? 'email-envelope' : '')}
								type="email"
								id="email"
								value={stateForm.emailValue.v}
								onChange={(e) =>
										setStateForm({
											...stateForm,
											emailValue: {
												...stateForm.emailValue,
												v: e.target.value,
												error: stateForm.emailValue.v && '',
											},
										})
																																																																						}
								/>
							{stateForm.emailValue.error && <span className="error label-field__error">{stateForm.emailValue.error}</span>}
						</label>
						<button className="button about__button__send">
							Отправить
						</button>
						{!user.isLogin && typeof user.isLogin !== "undefined" && !stateCaptcha.verified && (
							<Multicaptcha
								onVerified={(captcha) => {
													toast.success('Капча пройдена!');
													setStateCaptcha({
														...stateCaptcha,
														...captcha,
														needRerender: false,
														verified: true
													});
												}}
								/>
									)}
					</form>
				</section>
			</Fragment>
			);
};

const mapStateToProps = (state) => {
	return {
		user: state.user,
	};
};

export default connect(mapStateToProps, null)(About);
