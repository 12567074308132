import {CAPTCHA_RECEIVED, CAPTCHA_NOT_FOUND, CAPTCHA_ERROR} from '../constants';

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case CAPTCHA_RECEIVED:
      return payload;
    case CAPTCHA_NOT_FOUND:
        return state;
    case CAPTCHA_ERROR: 
        return payload;
    default:
      return state;
  }
};
