import React from 'react';
import { observer } from "mobx-react-lite";

const LabelWithSort = observer(({ label, column, tableModel, labelComment }) => {
    const isActive = tableModel.sort.by === column;
    const isAsc = tableModel.sort.order === 'asc';
    //console.log(tableModel.getSortOrder(), tableModel.sort.order);
    return (
        <div className="MonitoringTable__LabelWithSort">
            <span onClick={() => {
                /*if(isActive && !isAsc) tableModel.resetSort();
                else*/
                if (isActive) tableModel.setSortOrder(isAsc ? "desc" : "asc");
                else {
                    tableModel.setSortOrder("asc");
                    tableModel.setSortBy(column);
               }
            }}
            title={labelComment}>
                {label}
            </span>
            <div className="MonitoringTable__LabelWithSort__ArrowsContainer">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={() => {
                        tableModel.setSortBy(column);
                        tableModel.setSortOrder('asc');
                     }}>
                    <path d="M0 5L5 0L10 5H0Z" fill={(isActive && isAsc) ? '#1A75FF' : '#ABB0BA'}/>
                </svg>

                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={() => {
                         tableModel.setSortBy(column);
                         tableModel.setSortOrder('desc');
                     }}>
                    <path d="M0 5L5 0L10 5H0Z" fill={(isActive && !isAsc) ? '#1A75FF' : '#ABB0BA'}/>
                </svg>
            </div>
        </div>
    );
});

export default LabelWithSort;
