import React from 'react';

const CommonTableNavItem = ({ value, active, onClick }) => {
    const classes = [
        'CommonTableNav__Item',
        ...(active ? ['active'] : []),
        ...(Number.isInteger(value) ? ['number'] : [])
    ];

    return (
        <div className={classes.join(' ')} onClick={onClick}>
            {value}
        </div>
    );
};

export default CommonTableNavItem;