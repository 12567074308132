import React from "react";
import { observer } from "mobx-react-lite";
import CommonTableNavItem from "./CommonTableNavItem";


const CommonTableNav = observer(({ tableModel }) => {
    const pages = tableModel.getPagesCount();
    const page = tableModel.getPage();

    let pageRange = 3;
    let from = page - pageRange;
    if (from < 1) {
        pageRange += (from * -1) + 1;
        from = 1;
    }
    let to = page + pageRange + 2;
    if (to > pages + 1) {
        to = pages + 1;
        from = pages + 1 - pageRange * 2;
    }

    const nodes = [];

    // left arrow
    nodes.push(<CommonTableNavItem key={"arrow-left"} value={(
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41 10.59L2.83 6L7.41 1.41L6 0L0 6L6 12L7.41 10.59Z" fill="#223354"/>
        </svg>
    )} active={page > 0} onClick={() => page > 0 && tableModel.backPage()}/>);

    // numbers
    for(let i = from;i < to;i++) {
        if(i <= 0) continue;
        nodes.push(<CommonTableNavItem key={i} value={i} active={i === page + 1}
                                       onClick={() => tableModel.setPage(i - 1)}/>);
    }
    // right arrow
    nodes.push(<CommonTableNavItem key={"arrow-right"} value={(
        <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.590088 10.59L5.17009 6L0.590088 1.41L2.00009 0L8.00009 6L2.00009 12L0.590088 10.59Z" fill="#223354"/>
        </svg>
    )} active={page+1 < pages} onClick={() => page+1 < pages && tableModel.nextPage()}/>);

    return (
        <div className="CommonTableNav">
            {nodes}
        </div>
    );
});

export default CommonTableNav;