// Страница для ввода e-mail для восстановления пароля

import React, {createRef, useState} from "react";
import * as UserAPI from "../../helpers/api/User";
import {toast} from "react-toastify";
import Captcha from "../Captcha/Captcha";
import logoAuth from "../../img/logo.svg";
import * as CaptchaAPI from "../../helpers/api/Captcha";
import { EMAIL_REGEX } from "../../helpers/RegEx";
import Headers from "../../helpers/Headers";
import Multicaptcha from "../Multicaptcha/Multicaptcha";
import * as userActions from "../../redux/actions/userActions";
import * as ipvActions from "../../redux/actions/ipvActions";
import * as asnActions from "../../redux/actions/asnActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import * as modalActions from "../../redux/actions/modalActions";

class Recovery extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            isSend: false,
            captcha: {
                needRerender: false,
            },
            error: ''
        }

        this.inputRef = createRef();
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
//        this.captchaCallback = this.captchaCallback.bind(this);
    }

    componentDidMount() {
        document.title = Headers.passwordRecovery;
        if (!this.state.email) {
            UserAPI.get().then(async responce => {
                if (responce?.data?.data && !responce.data.data.tmp_user) {
                    this.setState({
                        ...this.state,
                        email: responce.data.data.login,
                    })
                }
            });
        }
    }

  /*  captchaCallback(data) {
        this.setState({
            captcha: {
                ...data,
                needRerender: false,
                verified: false,
            },
        });
    };*/

    recoveryUser(data) {
        UserAPI.recoveryPassword(data)
            .then(response => {
                const { status, reason } = response.data;

                if (status === "error") {
                    switch (reason.code) {
                        // 
                        case 1006:
                            this.setState({
                                error: reason.msg
                            })
                        break;
                        default:
                            toast.error(`Code: ${reason.code} / ${reason.msg}`);
                        break;
                    }
                    return;
                }

                this.setState({
                    isSend: true
                });
            })
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            email: event.target.value,
            error: this.state.email && '',
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.email) {
            this.setState({
                error: 'Поле не заполнено'
            })
            return;
        }

        if (!EMAIL_REGEX.test(this.state.email)) {
            this.setState({
                ...this.state,
                error: 'Введен некорректный e-mail'
            })
            return;
        }

        if (!this.state.captcha.verified) {
            console.log(this.state);
            CaptchaAPI.verifyCaptcha(this.state.captcha)
                .then(response => {
                    const { status, reason, limit } = response.data;

                    if (status === "error") {
                        toast.error(`${reason.msg}`);
                        if (!limit) {
                            this.setState({
                                captcha: {
                                    needRerender: true,
                                },
                            });
                        }
                        return;
                    }

                    this.setState({
                        captcha: {
                            ...this.state.captcha,
                            verified: true,
                        },
                    });

                    this.recoveryUser(this.state.email);
                })
            return;
        }

        this.recoveryUser(this.state.email);
    }

    render() {
        let html;

        html =
            <section className="start-page">
                <div className="start-page__logo-container">
                    <img src={logoAuth} alt="" className="logo start-page__logo"/>
                </div>
                <p className="start-page__text">Введите ваш email адрес, и мы вышлем на него ссылку для восстановления
                    пароля.</p>
                <form onSubmit={this.handleSubmit} className="start-page__form" noValidate>
                    <label className={`label-field ${this.state.error && 'label-field--error'}`}>
                        <div className="label-field__header">
                            <span className="label-field__name">E-mail</span>
                        </div>
                        <input ref={this.inputRef} className="field" type="email"
                               value={this.state.email}
                               onChange={this.handleChange} />
                        {this.state.error && <span className="error label-field__error">{this.state.error}</span>}
                    </label>
                    <Multicaptcha
                        onVerified={(captcha) => {
                            toast.success('Капча пройдена!');
                            this.setState({
                                ...this.state,
                                captcha: {
                                    ...captcha,
                                    needRerender: false,
                                    verified: true
                                }
                            });
                        }}
                    />
                    <button type="submit" /*disabled={!this.state.formValid}*/ className="button">Отправить ссылку</button>
                </form>
            </section>;

        if (this.state.isSend) {
            html =
                <section className="start-page">
                    <div className="start-page__logo-container">
                        <img src={logoAuth} alt="" className="logo start-page__logo"/>
                    </div>
                    <p className="start-page__text">Ссылка для восстановления пароля отправлена — проверьте ваш почтовый ящик.</p>
                </section>;
        }

        return html;
    }
}


export default Recovery;
