import {FILTER_RECEIVED, FILTER_UPDATED, FILTER_EMPTY} from '../constants';

export default (state = {}, action) => {
    const {type, payload} = action;
    switch (type) {
        case FILTER_RECEIVED:
            return {
                ...state,
                ...payload,
            };
        case FILTER_UPDATED:
            return {
                ...state,
                ...payload,
            };
        case FILTER_EMPTY:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
};
