import axios from "axios";
import { API_URL } from "../../redux/constants";
import { toast } from "react-toastify";

export const getCaptcha = async () => {
    const data = {
        captchaID: "",
        captchaToken: "",
        status: "",
        question: "",
        imagesURL: []
    };

    try {
        const response = await axios.get(`${API_URL}/api/captcha/get/`);

        if (response.data.status === "error") {
            toast.error("Не удалось получить ключ капчи.");
            return;
        }

        data.status = response.data.status;
        data.question = response.data.data.question;
        data.captchaID = response.data.data.id;
        data.captchaToken = response.data.data.captcha;

        for (let i = 0; i < 6; i++) {
            const captchaID = response.data.data.id;
            const encodeHashCaptcha = encodeURIComponent(response.data.data.captcha);
            data.imagesURL.push({
                id: i,
                url: `${API_URL}/api/captcha/img/?c=${encodeHashCaptcha}&cid=${captchaID}&id=${i}`,
            });
        }
    } catch (error) {
        toast.error("Не удалось загрузить капчу.");
        console.error(`${error}`);
    }

    return data;
};

export const verifyCaptcha = async (payload) => {
    const checkedImages = document.querySelectorAll(
        'input[name="captcha[]"]:checked'
    );

    if (!checkedImages.length && !payload['g-recaptcha']) {
        return {
            data: {
                limit: 0,
                status: 'error',
                reason: {
                    msg: 'Не выбрано ни одной картинки.',
                },
            },
        };
    }

    const keys = [];
    const encodeCaptcha = encodeURIComponent(payload.captchaToken);

    checkedImages.forEach((element) => {
        keys.push(element.dataset.key);
    });

    let queryParams = payload['g-recaptcha']
        ? `g-recaptcha=${payload['g-recaptcha']}`
        : `id=${payload.captchaID}&cypher=${encodeCaptcha}&data=[${keys}]`;

    try {
        const response = await axios.get(
            `${API_URL}/api/captcha/verify/?${queryParams}`,
            {
                withCredentials: true,
            }
        );

        if (response.data.status === "error") {
            if (!response.data.limit) return response;
        }

        return response;
    } catch (e) {
        toast.error(`${e}`);
    }
};
