import { IPV_CHANGED, IPV_GET } from "../constants";

export default (state = {}, action) => {

    const { type, payload } = action;
    switch (type) {
        case IPV_CHANGED:
            return {
                ...state,
                [payload.component]: payload.value
            };
        case IPV_GET:
            return payload;
        default:
            return state;
    }
};
