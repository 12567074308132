// Уведомления для незарега о потере данных

import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Routes from '../../helpers/Routes/Routes';
import { connect } from 'react-redux';

const Alert = (props) => {
    const { user } = props;
    const [alertState, setAlertState] = useState(false);
    let alertClasses = '';

    if ((!user.isLogin && typeof user.isLogin !== 'undefined') || user.tmpMode === true) {
        if (!user.isLogin && alertState) {
            alertClasses = '';
            return null;
        }
        alertClasses = 'AlertVisible'
    }

    return (
        <div className={`Alert ${alertClasses || ''}`}>
            <div className="container container--alert">
                <span className="AlertText">
                    Вы вошли как незарегистрированный пользователь. Ваши данные будут удалены через неделю. <NavLink className="link" to={Routes.registration}>Зарегистрируйтесь</NavLink> или <NavLink className="link" to={Routes.auth}>войдите</NavLink>, чтобы ничего не потерять.
                </span>
                <button className="button-close" onClick={() => setAlertState(true)}></button>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps, null)(Alert);