// Стартовая страница "Начало работы"

import React, {Fragment, useState, useEffect, useRef} from "react";
import {connect, batch} from "react-redux";
import {useHistory} from "react-router-dom";
import * as ReportAPI from '../../helpers/api/Report';
/*import * as PoolAPI from '../../helpers/api/Pool';
import * as UserAPI from '../../helpers/api/User';
import * as InfoAPI from '../../helpers/api/Info';
import Routes from "../../helpers/Routes/Routes";*/
import * as Utils from "../../helpers/Utils";
import * as modalActions from '../../redux/actions/modalActions';
import * as userActions from '../../redux/actions/userActions';
//import {toast} from "react-toastify";
import Headers from "../../helpers/Headers";
/*import Captcha from "../../components/Captcha/Captcha";
import * as CaptchaAPI from "../../helpers/api/Captcha";
import History from "../History/History";*/
import MonitoringTable from "./MonitoringTable/MonitoringTable";
import MonitoringModel from "./MonitoringTable/MonitoringModel";
import Loader from "../Loader/Loader";
import {observer} from "mobx-react-lite"
/*import DatePicker from "../UI/DatePicker";
import {getRequestDataById} from "../../helpers/api/Info";*/
import ajaxCache from "../../helpers/api/AjaxCache";
import Multicaptcha from "../Multicaptcha/Multicaptcha";
import {reportToTableElements} from "../../helpers/ReportUtils";
import {observable, toJS, transaction} from "mobx";
import {datePickerSetupIncludeDates, getDatePickerStartDate, handleDatePicker} from "../../helpers/DatePickerHelper";
import {monitoringScrollYAction} from "../../redux/actions/monitoringScrollYAction";
//import {trashSVGIcon} from "./MonitoringTable/Icons1";
import TrashIcon from "../UI/TrashIcon";
import H1 from "../UI/H1";

const Monitoring = observer((props) => {
	const {user, compareDate, checked, showModal, setTmpMode, getUser} = props;
	const {scrollY, setMonitoringScrollY} = props;
	const {column, direction} = props;
	const history = useHistory();
	const input = useRef(null);
	const [state, setState] = useState({
		value: '',
		error: '',
		asn: {
			ip: '',
			assets: [''],
			autnums: [''],
		},
		isLoaded: false,
		tmpUser: undefined
	});
	const [captcha, setCaptcha] = useState({
		needVerify: false,
		verified: false
	});
	const [reportDatesState, setReportDatesState] = useState(null);
	const [m] = useState(new MonitoringModel([], "id"));
	const [compareModeState, setCompareModeState] = useState(false);
	const [forceCompare, setForceCompare] = useState(false);
	const [focusBottom, setFocusBottom] = useState(false);
	const x = m.lengthCache;
	document.title = 'DASPATH. префиксов - ' + x;
	const smallSize = props.smallHook;

	useEffect(() => {
		document.title = Headers.getStarted;
		if (typeof user.asn !== 'undefined')
			setState({
				...state,
				asn: user.asn
			});
	}, [user])

	if(state.isLoaded && document.location.hash.length > 1 && m.getPage() != document.location.hash.replaceAll('#', '')-1) {
		const p = document.location.hash.replaceAll('#', '');
		if(p > m.getPagesCount()) {
			m.setPage(0);
		} else if(p <= 0) {
			m.setPage(0);
		} else {
			m.setPage(document.location.hash.replaceAll('#', '') - 1);
		}
	}

	useEffect(() => {
		const onScroll = (e) => {
			setMonitoringScrollY(document.documentElement.scrollTop);
		};

		window.addEventListener('scroll', onScroll);
		return () => window.removeEventListener('scroll', onScroll);
	});

	m.setPageSize(10);

	const fetchElements = () => {
		ReportAPI.get(localStorage['reportId'], 4).then(report4 => {
			ReportAPI.get(localStorage['reportId'], 6).then(async (report6) => {
				if(!state.isLoaded) {
					const elements4 = await reportToTableElements(report4);
					const elements6 = await reportToTableElements(report6);
					const elements = [...elements4, ...elements6].map((e, id) => ({id, ...e}));

					m.setData(elements);
					setState({
						...state,
						isLoaded: true
					});
					if (m.lengthCache && !forceCompare) {
						setForceCompare(true);
					}
					document.documentElement.scrollTop = scrollY;
				}
			});
		});
	};

	if (!('isLogin' in user) || user.tmpMode === undefined) {
		return (<div/>);
	}

	if (user.tmpMode !== undefined && !state.isLoaded) {
		if (user.isLogin && localStorage['poolId'] && localStorage['reportId']) {
			ReportAPI.getList().then(reportsList => {
				if (reportsList && reportsList.data && reportsList.data.length) {
					const lastReport = reportsList.data[0];
					localStorage['poolId'] = lastReport.pool_id;
					localStorage['reportId'] = lastReport.report_id;
					fetchElements();
				}
			});
		} else {
			if (user.isLogin) {
				ReportAPI.getList().then(reportsList => {
					if (reportsList && reportsList.data) {
						if (reportsList.data.length) {
							const lastReport = reportsList.data[0];
							//console.log('setFromList', reportsList, lastReport);
							localStorage['poolId'] = lastReport.pool_id;
							localStorage['reportId'] = lastReport.report_id;
							fetchElements();
						} else {
							ReportAPI.create().then((response) => {
								fetchElements();
							});
						}

					} else {
						setTmpMode(true);
					}
				});
			} else {
				setState({...state, isLoaded: true});
			}
		}
	}

	let startDate;
	if(m.lengthCache) {
		datePickerSetupIncludeDates([reportDatesState, setReportDatesState], compareDate, user);
		handleDatePicker([compareModeState, setCompareModeState], compareDate, checked, forceCompare, setForceCompare).then(async (response) => {
			if (response !== null && (!response || m.getVirtualProperty('COMPARE_DATE') !== response.dateStr)) {
				transaction(async () => {
					m.setVirtualProperty('COMPARE_DATE', response.dateStr);
					const elements4 = await reportToTableElements(response.report4);
					const elements6 = await reportToTableElements(response.report6);
					const elements = [...elements4, ...elements6].map((e, id) => ({id, ...e}));

					for(const row of m.elements) {
						try {
							m.setVirtualProperty(row['id'], {});
						} catch {

						}
					}

					for (const row of m.elements) {
						const likeElements = elements.filter(e => e['prefix'] === row['prefix']);
						if (likeElements.length) {
							const likeElement = likeElements[0];
							try {
								m.setVirtualProperty(row['id'], {
									prev_global_min: likeElement.global_min,
									prev_global_mid: likeElement.global_mid,
									prev_global_max: likeElement.global_max,

									prev_region_min: likeElement.region_min,
									prev_region_mid: likeElement.region_mid,
									prev_region_max: likeElement.region_max,

									prev_local_min: likeElement.local_min,
									prev_local_mid: likeElement.local_mid,
									prev_local_max: likeElement.local_max,
									prev_full_path: likeElement.full_path
								});
							} catch {

							}
						}
					}
					setCompareModeState({...compareModeState, elements, compareDate});
				});
			}
		});
		startDate = getDatePickerStartDate(compareDate, reportDatesState);
	}

	const formHandler = event => {
		event && event.preventDefault();

		let state_value = state.value.trim();
		if (!state_value && user.asn && user.asn.autnums)
			state_value = 'AS' + user.asn.autnums[0];

		if (!Utils.getResourceType(state_value)) {
			setState({
				...state,
				error: "Некорректный ресурс",
			});
			return;
		}

		showModal({
			type: 'add_v2',
			resource: {
				value: state_value,
				currentReport: {
					reportId: localStorage.getItem('reportId'),
					poolId: localStorage.getItem('poolId') ?? null,
				},
			},
			callBack: () => {
				setState({...state, isLoaded: false});
				setCompareModeState({
					...compareModeState,
					elements: false,
					report: false
				});
				ajaxCache.refresh();
				fetchElements();
			}
		});
	}

	const insertToField = (event) => {
		event.preventDefault();
		input.current.value = event.target.textContent;
		setState({
			...state,
			value: event.target.textContent,
		});
	}

	const showCaptchaHandler = (event, pass) => {
		if (!pass) {
			if (!user.isLogin && !captcha.verified) {
				setCaptcha({
					needVerify: true
				});
				return false;
			}
		}

		if (pass || captcha.verified || user.isLogin) {
			return formHandler(event);
		}
	}

	if (state.error) {
		showModal({
			type: 'incorrect_resource',
			resource: state.error,
			callBack: () => {
			}
		});
		setState({...state, error: false});
	}

    const showInput = () => {
        return (
            <Fragment>
                <input
                    ref={input}
                    type="text"
                    className="field report__field Monitoring__ResourceInput"
                    placeholder=""
                    value={state.value}
					title={"Введите префикс, автономную систему или веб-сайт"}
                    onChange={e => setState({
                        ...state,
                        value: e.target.value,
                        error: state.value && '',
                    })}
                    onFocus={() => setFocusBottom(true)}
                    onBlur={() => setFocusBottom(false)}
                />

                <button type="button"
                        className="button report__button_new_left_sticky report__button report__button_new"
                        onClick={event => showCaptchaHandler(event)}>
                    Добавить
                </button>
            </Fragment>
        )
    }

    const showLabel = () => {
        return (
            <Fragment>
                <span className="Monitoring__YourIP__Desktop__View">
                    <span className="Monitoring__YourIP__OpacityText">Ресурс, с которого вы вошли:</span>
                    <span className="Monitoring__YourIP__Host">
                        <a href="" onClick={insertToField}
                           className="report__markup-link">{state.asn.ip ? state.asn.ip : 'неизвестно'}</a>,
                        <a href="" onClick={insertToField}
                           className="report__markup-link">{(user.asn && user.asn.autnums) ? 'AS' + user.asn.autnums[0] : 'неизвестно'}</a>
                    </span>
                </span>
                <span className="Monitoring__YourIP__Mobile__View">
                    <span className="Monitoring__YourIP__OpacityText">Ваша AS:</span>
                    <span className="Monitoring__YourIP__Host">
                        <a href="" onClick={insertToField}
                           className="report__markup-link">{(user.asn && user.asn.autnums) ? 'AS' + user.asn.autnums[0] : 'неизвестно'}</a>
                        </span>
                </span>
            </Fragment>
        )
    }

    return (
        <Fragment>
			{/* fix svg  - если убрать, другие svg пропадут */}
			<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
			style={{
				width: 0,
				height: 0,
			}}
			>
				<g clipPath="url(#clip0)">
					<path
						d="M0.5 9C0.5 4.58172 4.08172 1 8.5 1C12.9183 1 16.5 4.58172 16.5 9C16.5 13.4183 12.9183 17 8.5 17C4.08172 17 0.5 13.4183 0.5 9Z"
						fill="url(#paint0_linear)"/>
					<path
						d="M15.4383 4.79981C15.3214 4.58856 15.0779 4.49132 14.8539 4.57179L13.2499 5.14182L11.9674 4.92052L11.5453 4.27001C11.4057 4.05541 11.133 3.985 10.9154 4.10236L7.52251 5.93987C7.34393 6.03711 7.24003 6.2383 7.26601 6.44954C7.29198 6.66079 7.43809 6.82844 7.63615 6.87539L9.83101 7.41524L10.4641 8.42788C10.5778 8.60895 10.7888 8.69613 10.9934 8.64248L11.8603 8.41782L12.2694 8.69949V9.54112C12.2694 9.66854 12.3148 9.78925 12.3993 9.88314L13.8246 11.4792V14.0008C13.8246 14.2154 13.9545 14.4065 14.1525 14.4769C14.2045 14.497 14.2597 14.5037 14.3116 14.5037C14.4577 14.5037 14.5974 14.4367 14.6915 14.3126C15.2662 13.5682 15.711 12.74 16.0195 11.8514C16.3377 10.9327 16.5 9.96697 16.5 8.9845C16.5 7.52254 16.1331 6.07399 15.4383 4.79981ZM8.01278 9.8429L6.49326 9.52771L5.21076 8.0691C5.1166 7.9618 4.98348 7.90144 4.84387 7.9048L3.61981 7.92156L3.77566 6.29194L5.4835 5.41007C5.58415 5.35978 5.66207 5.2726 5.70753 5.16865L6.12637 4.21301L7.55498 3.62957C7.71732 3.56251 7.8342 3.40826 7.85693 3.23055C7.87966 3.05283 7.80823 2.87176 7.66862 2.76111L5.5322 1.10131C5.39583 0.994014 5.21401 0.970542 5.05491 1.0376C3.04188 1.8893 1.39573 3.5625 0.538571 5.63138C0.493115 5.74539 0.486621 5.87281 0.525583 5.98681L1.00611 7.47895C1.04183 7.58625 1.10677 7.67678 1.19768 7.73714L2.59382 8.69613V9.77584C2.59382 9.90661 2.64252 10.034 2.73018 10.1279L3.93476 11.4088V12.8305C3.93476 12.9479 3.97372 13.0619 4.0484 13.1524L5.39259 14.8189C5.48674 14.9363 5.62636 15 5.76597 15C5.82117 15 5.87636 14.9899 5.93156 14.9698C6.12312 14.8994 6.253 14.7083 6.253 14.497V13.3603L7.13289 12.6796C7.25627 12.5858 7.3277 12.4349 7.3277 12.2773V11.6536L8.25954 10.6912C8.38617 10.5605 8.43487 10.366 8.37967 10.1883C8.33097 10.0139 8.18811 9.87978 8.01278 9.8429Z"
						fill="url(#paint1_linear)"/>
				</g>
				<path
					d="M8.5 16.5C4.35786 16.5 1 13.1421 1 9H0C0 13.6944 3.80558 17.5 8.5 17.5V16.5ZM16 9C16 13.1421 12.6421 16.5 8.5 16.5V17.5C13.1944 17.5 17 13.6944 17 9H16ZM8.5 1.5C12.6421 1.5 16 4.85786 16 9H17C17 4.30558 13.1944 0.5 8.5 0.5V1.5ZM8.5 0.5C3.80558 0.5 0 4.30558 0 9H1C1 4.85786 4.35786 1.5 8.5 1.5V0.5Z"
					fill="#BDBDBD"/>
				<defs>
					<linearGradient id="paint0_linear" x1="8.50049" y1="17" x2="8.50049" y2="1" gradientUnits="userSpaceOnUse">
						<stop stopColor="#5558FF"/>
						<stop offset="1" stopColor="#00C0FF"/>
					</linearGradient>
					<linearGradient id="paint1_linear" x1="8.50029" y1="15.003" x2="8.50029" y2="0.997377"
									gradientUnits="userSpaceOnUse">
						<stop stopColor="#ADDCFF"/>
						<stop offset="0.5028" stopColor="#EAF6FF"/>
						<stop offset="1" stopColor="#EAF6FF"/>
					</linearGradient>
					<clipPath id="clip0">
						<path
							d="M0.5 9C0.5 4.58172 4.08172 1 8.5 1C12.9183 1 16.5 4.58172 16.5 9C16.5 13.4183 12.9183 17 8.5 17C4.08172 17 0.5 13.4183 0.5 9Z"
							fill="white"/>
					</clipPath>
				</defs>
			</svg>
			{/* end fix svg  */}
            <div className="container container-1024">
                {(state.isLoaded) ? <H1
                    text="Мониторинг"
                    smallSize={smallSize}
                    startDate={startDate}
                    reportDates={reportDatesState === null ? [] : reportDatesState.map(r => r.date)}
                    user={user}
                /> : ''}

                {(state.isLoaded && !m.lengthCache) && <Fragment>
                    <div className="Monitoring0Text">Чтобы начать мониторинг - добавьте ваши префиксы в формате подсетей IPv4, IPv6 или AS в формате AS12345</div>
                    <div className="MonitoringTableMobile__Top">
                        <div className="MonitoringTableMobile__Bottom__Container">
                            {!focusBottom && !state.value.length && <span className="report__markup Monitoring__YourIP">
                                        {showLabel()}
											</span>}
                            {showInput()}
                        </div>
                    </div>
                </Fragment>}
            </div>
            {state.isLoaded ?
                <form action="" noValidate className="Monitoring__Form" onSubmit={(e) => showCaptchaHandler(e)}>
                    <div className="MonitoringTable__ContainerCenter">
                        <MonitoringTable
                            column={column}
                            direction={direction}
                            compareMode={compareModeState}
                            tableModel={m}
                            inputValue={state.value}
                            inputOnChange={(e) => setState({
                                ...state,
                                value: e.target.value,
                                error: state.value && '',
                            })}
                            buttonClick={(e) => showCaptchaHandler(e)}
                            onDeletePrefix={(prefixesNames, processDelete) => {
                                const resources = [];
                                prefixesNames.forEach(name => {
                                    resources.push({
                                        resource: name,
                                        type: 'prefix',
                                        pool_id: localStorage['poolId']
                                    });
                                });

								if (resources.length === 1) {
									showModal({
										type: 'delete',
										resource: {
											resource: prefixesNames[0],
											type: 'prefix',
											pool_id: localStorage['poolId']
										},
										callBack: () => {
											ajaxCache.refresh();
											processDelete();
										}
									});
								} else if (resources.length > 1) {
									showModal({
										type: 'delete_many',
										resource: resources,
										callBack: () => {
											ajaxCache.refresh();
											processDelete();
										}
									});
								}
							}}/>
					</div>

                    {m.lengthCache ? <div className="MonitoringTableMobile__Bottom">
                        <div className="MonitoringTableMobile__Bottom__Container">
                            {!focusBottom && !state.value.length && <span className="report__markup Monitoring__YourIP">
											{showLabel()}
										</span>}
                            {showInput()}
							<div className={["MonitoringTableMobile__Bottom__Trash", m.isMarked ? "" : "MonitoringTableMobile__Bottom__Trash__Hidden"].join(" ")} onClick={() => m.removeMarked((prefixesNames, processDelete) => {
								const resources = [];
								prefixesNames.forEach(name => {
									resources.push({
										resource: name,
										type: 'prefix',
										pool_id: localStorage['poolId']
									});
								});

								if (resources.length === 1) {
									showModal({
										type: 'delete',
										resource: {
											resource: prefixesNames[0],
											type: 'prefix',
											pool_id: localStorage['poolId']
										},
										callBack: () => {
											ajaxCache.refresh();
											processDelete();
										}
									});
								} else if (resources.length > 1) {
									showModal({
										type: 'delete_many',
										resource: resources,
										callBack: () => {
											ajaxCache.refresh();
											processDelete();
										}
									});
								}
							})}>
								{TrashIcon}
							</div>
						</div>
					</div> : null }
				</form>
				: <Loader class="Loader"/> }

			{ captcha.needVerify && <Multicaptcha disable={!captcha.needVerify} onVerified={() => {
				setCaptcha({
					needVerify: false,
					verified: true
				});

				ReportAPI.create().then((response) => {
					if (response.status === "ok") {
						ajaxCache.refresh();
						getUser().then(() => {
							showCaptchaHandler(null, true);
						});
						return (<div/>);
					} else {
						if (response.reason.code === 1004) {
							setCaptcha({
								needVerify: true,
								verified: false
							});
						}
					}
				});
			}}/> }
		</Fragment>
	);
});

const mapStateToProps = (state) => {
	return {
		user: state.user,
		compareDate: state.compareDate,
		checked: state.checked,
		column: state.monitoringColumn,
		direction: state.monitoringDirection,
		scrollY: state.monitoringScrollY
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showModal: data => dispatch(modalActions.showModal(data)),
		setTmpMode: data => dispatch(userActions.setTmpMode(data)),
		getUser: () => dispatch(userActions.getUser()),
		setMonitoringScrollY: data => dispatch(monitoringScrollYAction(data))
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring);