// Компонент таблицы для точек наблюдения

import React from "react";
import {toast} from "react-toastify";
import * as Views from "../../../helpers/api/Views";
import ViewPointsRow from "./Row/ViewPountsRow";
import * as viewPointsActions from "../../../redux/actions/viewPointsActions";
import * as Utils from "../../../helpers/Utils";
import { connect } from "react-redux";
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css'
import LocationIcon from "../../UI/LocationIcon";

class ViewPointsTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            bindError: "Не удалось изменить список точек наблюдения, попробуйте позднее"
        };

        this.bindAllPoints = this.bindAllPoints.bind(this);
    }

    bindAllPoints(e) {
        const target = e.currentTarget;
        const data = {view: "all", af: this.props.ipv};

        try {
            let action = target.checked ? "addView" : "removeView";
            Views[action](localStorage.getItem("reportId"), data)
                .then(result => {

                        if (!Utils.checkResponse(result))
                            return;

                        // обновим глобальный state
                        let allViewPoints = JSON.parse(JSON.stringify(this.props.allViewPoints));
                        Object.keys(allViewPoints[this.props.ipv].groups).forEach(group => {
                            Object.keys(allViewPoints[this.props.ipv].groups[group].items).forEach(view => {
                                allViewPoints[this.props.ipv].groups[group].items[view].checked = !target.checked;
                            })
                        })

                        this.props.updateViewPoints(allViewPoints);
                    }
                );
        } catch (e) {
            target.checked = !target.checked;
            toast.error(
                "Не удалось изменить список точек наблюдения, попробуйте позднее"
            );
        }
    }

    render() {
        const rows = [],
            viewPoints = this.props.viewPoints;

        let allChecked = true;

        Object.keys(viewPoints.groups)
            .sort((group1, group2) => {
                const order = {"global":0, "regional":1, "ix":2};
                return order[group1]-order[group2];
            })
            .map((group, index) => {
                if (!Object.keys(viewPoints.groups[group].items).length)
                    return;

                if (viewPoints.groups[group]) {
                    rows.push(
                        <tr key={`${viewPoints.name}_${group}`}>
                            <td colSpan={3}>
                                <LocationIcon type={group}/>
                                <span>{viewPoints.groups[group].name}</span>
                            </td>
                        </tr>
                    );
                }

                Object.keys(viewPoints.groups[group].items).map((viewPoint, index) => {
                    rows.push(
                        <ViewPointsRow isAuthorized={this.props.isAuthorized} key={`${viewPoints.name}_${group}_${index}`}
                                       view={viewPoints.groups[group].items[viewPoint]}
                                       allViewPoints={this.props.allViewPoints}/>
                    );
                });

                // проверяем выбраны ли все чекбосы
                Object.keys(viewPoints.groups[group].items).map((viewPoint, index) => {
                    if (!viewPoints.groups[group].items[viewPoint].checked) {
                        allChecked = false;
                        return false;
                    }
                })
            });

        return (
            <div className="observation-points__table-container">
                <h2 className="observation-points__title">
                    {this.props.viewPoints.name}
                </h2>
                <div className="observation-points__table-header">
                    <table className="observation-points__table">
                        <thead>
                            <tr key={Math.random()}>
                                <td>
                                    <label className="observation-points__label">
                                        <input
                                            type="checkbox"
                                            className="visually-hidden"
                                            onChange={this.bindAllPoints}
                                            checked={allChecked}
                                        />
                                        <span className="checkbox"></span>
                                    </label>
                                </td>
                                <td>Название</td>
                                <td>AS Number</td>
                            </tr>
                        </thead>
                    </table>
                </div>
                <SimpleBar autoHide={false} style={{ maxHeight: 'calc(100% - 90px)', minWidth: '320px', width: '100%', paddingRight: '20px' }}>
                    <table className="observation-points__table">
                        <tbody>{rows}</tbody>
                    </table>
                </SimpleBar>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateViewPoints: (data) => dispatch(viewPointsActions.updateViewPoints(data))
    };
};

export default connect(null, mapDispatchToProps)(ViewPointsTable);