import React from "react";

const IconPen = () => {
    return <svg width="17" height="16" viewBox="0 0 17 16" fill="none">
        <path d="M16.629 3.59111C16.9756 3.24444 16.9756 2.68444 16.629 2.33778L14.5492 0.257778C14.3715 0.08 14.1493 0 13.9182 0C13.6871 0 13.4649 0.0888889 13.2961 0.257778L11.6696 1.88444L15.0025 5.21778L16.629 3.59111ZM0.888916 12.6667V16H4.22179L14.0515 6.16889L10.7187 2.83556L0.888916 12.6667ZM3.48411 14.2222H2.66645V13.4044L10.7187 5.35111L11.5363 6.16889L3.48411 14.2222Z" fill="#1A75FF"/>
    </svg>;
}

const IconCheck = () => {
    return <svg width="16" height="16" x="0px" y="0px" viewBox="0 0 76.887 55.552">
        <polygon fill="#1A75FF" points="34.373,49.541 76.887,5.889 70.739,0 28.426,43.458 6.078,20.632 0,26.585 22.488,49.557 22.488,49.557 28.517,55.552 28.426,55.467 28.517,55.552 34.373,49.541"/>
    </svg>;
};

const IconCross = () => {
    return <svg width="16" height="16" viewBox="0 0 11 11">
        <path fill="#1A75FF" d="M2.2,1.19l3.3,3.3L8.8,1.2C8.9314,1.0663,9.1127,0.9938,9.3,1C9.6761,1.0243,9.9757,1.3239,10,1.7&#xA;&#x9;c0.0018,0.1806-0.0705,0.3541-0.2,0.48L6.49,5.5L9.8,8.82C9.9295,8.9459,10.0018,9.1194,10,9.3C9.9757,9.6761,9.6761,9.9757,9.3,10&#xA;&#x9;c-0.1873,0.0062-0.3686-0.0663-0.5-0.2L5.5,6.51L2.21,9.8c-0.1314,0.1337-0.3127,0.2062-0.5,0.2C1.3265,9.98,1.02,9.6735,1,9.29&#xA;&#x9;C0.9982,9.1094,1.0705,8.9359,1.2,8.81L4.51,5.5L1.19,2.18C1.0641,2.0524,0.9955,1.8792,1,1.7C1.0243,1.3239,1.3239,1.0243,1.7,1&#xA;&#x9;C1.8858,0.9912,2.0669,1.06,2.2,1.19z"/>
    </svg>;
};

export {IconPen, IconCheck, IconCross};