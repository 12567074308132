// Комопнент основной и сравнительной таблицы для отчета

import React, { useEffect, useState, Fragment } from "react";
import ReactTooltip from "react-tooltip";
import collapseIcon from '../../../img/collapse-icon.svg';
import expandedIcon from '../../../img/expanded-icon.svg';
import {toast} from "react-toastify";

const ReportTableCompare = (props) => {
    const {data, isCompare} = props;
    const [expandedType, setExpandedType] = useState({
        global: false,
        regional: false,
        local: false
    })

    useEffect(() => {
        ReactTooltip.rebuild();
    })

    if (!data || !Object.keys(data).length || !Object.keys(props.viewPoints).length)
        return null;

    let viewPoints = JSON.parse(JSON.stringify(props.viewPoints));
    const groupExpanded = (type) => {
        setExpandedType({ ...expandedType, [type]: !expandedType[type] });
    }

    const copyPath = (e) => {
        const path = e.currentTarget.getAttribute("data-tip");

        if (!path.length || path.includes('Нет данных.'))
            return;
        
        const converted = path.split(', ').join('\n');

        const textarea = document.createElement("textarea");
        textarea.value = converted;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand("copy");
        toast.success('Скопировано!');
        document.body.removeChild(textarea);
    }

    // удаляем из списка группы и анчекнутые точки
    Object.keys(viewPoints).forEach(group => {
        if (!viewPoints[group].checked) {
            delete viewPoints[group];
            return;
        }

        Object.keys(viewPoints[group].items).forEach(view => {
            if (!viewPoints[group].items[view].checked)
                delete viewPoints[group].items[view];
        })
    })

    if (!Object.keys(viewPoints).length)
        return null;

    return (
        <Fragment>
            <table className="report-detail__table">
                <thead>
                <tr>
                    <td>
                        <span className="report-detail__table-text-secondary">
                            Точки наблюдения
                        </span>
                        <span className="report-detail__table-text-secondary">
                            Ресурсы
                        </span>
                    </td>
                    {Object.keys(viewPoints).map(group => {
    
                        // не выводим неактивные точки
                        if (!Object.keys(viewPoints[group].items).length || !viewPoints[group].checked)
                            return false;
    
                        return (
                            <td key={group}>
                                <span className="report-detail__table-text-header">
                                    {viewPoints[group].name}
                                    <button className={`button-expanded ${expandedType[group] && 'button-expanded--collapse' || ''}`}
                                            onClick={() => groupExpanded(group)}>
                                        <svg width="20" height="20" viewBox="0 -2 12 14">
                                            <use xlinkHref={`${collapseIcon}#collapse-icon`} />
                                        </svg>
                                        <svg width="20" height="20" viewBox="0 -2 12 14">
                                            <use xlinkHref={`${expandedIcon}#expanded-icon`} />
                                        </svg>
                                    </button>    
                                </span>
                                <table className={`report-detail__table-nested ${expandedType[group] && 'report-detail__table-nested--hidden' || ''}`}>
                                    <tbody>
                                    <tr>
                                        {Object.keys(viewPoints[group].items).map((view, key) => {
    
                                            if (!viewPoints[group].items[view].checked)
                                                return;
    
                                            return (
                                            <td key={key}>
                                                <span
                                                    className="report-detail__table-text">{viewPoints[group].items[view].name}</span>
                                                <span
                                                    className="report-detail__table-text-secondary">{viewPoints[group].items[view].view}</span>
                                            </td>
                                            );
                                        })}
                                    </tr>
                                    </tbody>
                                </table>
                            </td>
                        );
                    })}
                </tr>
                </thead>
                {Object.keys(data).map((res, key) => (
                    <tbody key={key}>
                    <tr>
                        <td>
                            <span className={`report-detail__table-text-secondary ${data[res].hasOwnProperty("isOldReport") && data[res].isOldReport ? "report-detail__table-text--green" : ""} ${data[res].hasOwnProperty("isOldReport") && !data[res].isOldReport ? "report-detail__table-text--blue" : ""}`}>
                                {data[res].resource}
                            </span>
                        </td>
                        {Object.keys(viewPoints).map((group, key) => {
                            if (!Object.keys(viewPoints[group].items).length || !viewPoints[group].checked)
                                return;
    
                            return <td key={key}></td>;
                        })}
                    </tr>
                    {Object.keys(data[res].prefixes).map((prefix, key) => (
                        <tr key={key}>
                            <td>
                                <span className={`report-detail__table-text ${data[res].hasOwnProperty("isOldReport") && data[res].isOldReport ? "report-detail__table-text--green" : ""} ${data[res].hasOwnProperty("isOldReport") && !data[res].isOldReport ? "report-detail__table-text--blue" : ""}`}>{prefix}</span>
                            </td>
                            {Object.keys(viewPoints).map((group, key) => {
                                if (!Object.keys(viewPoints[group].items).length || !viewPoints[group].checked)
                                    return false;
    
                                let viewsObj = data[res].prefixes[prefix].views;
                                let viewsObjCompare = data[res].prefixes[prefix].viewsCompared;
                                let avgPathToGroup = typeof data?.[res]?.prefixes?.[prefix]?.avgPathToGroup?.[group] !== "undefined" ? data[res].prefixes[prefix].avgPathToGroup[group] : "-";
                                let avgPathToGroupDiff = isCompare && typeof data[res]?.prefixes[prefix]?.avgPathToGroupDiff?.[group] !== "undefined" ? data[res]?.prefixes[prefix]?.avgPathToGroupDiff?.[group] : 0;
    
                                return (
                                    <td key={key}>
                                        {expandedType[group] &&
                                        <Fragment>
                                        <span className={`report-detail__table-text ${avgPathToGroupDiff > 0 ? "report-detail__table-text--up" : ""} ${avgPathToGroupDiff < 0 ? "report-detail__table-text--down" : ""}`}>
                                            {avgPathToGroup}
                                        </span>
                                        {isCompare && avgPathToGroupDiff !== 0 &&
                                        <span className="report-detail__table-text-count">
                                            {avgPathToGroupDiff}
                                        </span>
                                        }
                                        </Fragment>}
                                        <table className={`report-detail__table-nested ${expandedType[group] && 'report-detail__table-nested--hidden' || ''}`}>
                                            <tbody>
                                            <tr>
                                                {Object.keys(viewPoints[group].items).map(view => {
    
                                                    if (!viewPoints[group].items[view].checked)
                                                        return;

                                                    let asPathString = typeof viewsObj?.[view]?.["as-path"] !== "undefined" ? viewsObj[view]["as-path"] : "";
                                                    let compareAsPathString = typeof viewsObjCompare?.[view]?.["as-path"] !== "undefined" ? viewsObjCompare[view]["as-path"] : "";
    
                                                    return (
                                                        <td key={`${view}_${key}`}>
                                                        <span data-tip={(asPathString || compareAsPathString) && `${asPathString}, ${viewsObjCompare && `${compareAsPathString}` || ''}` || 'Нет данных.'} onClick={copyPath} data-for="hint" className={`report-detail__table-text 
                                                        ${data[res].hasOwnProperty("isOldReport") && data[res].isOldReport
                                                            ? "report-detail__table-text--green"
                                                            : ""}
                                                        ${data[res].hasOwnProperty("isOldReport") && !data[res].isOldReport
                                                            ? "report-detail__table-text--blue"
                                                            : ""} 
                                                        ${viewsObj.hasOwnProperty(view) && viewsObj[view].hasOwnProperty("aspathDiff") && parseFloat(viewsObj[view].aspathDiff) > 0 ? "report-detail__table-text--up" : ""} 
                                                        ${viewsObj.hasOwnProperty(view) && viewsObj[view].hasOwnProperty("aspathDiff") && parseFloat(viewsObj[view].aspathDiff) < 0 ? "report-detail__table-text--down" : ""}`}>
                                                            {viewsObj.hasOwnProperty(view)
                                                                ? (viewsObj[view].checked
                                                                        ? viewsObj[view].aspath
                                                                        : "-"
                                                                )
                                                                : "-"
                                                            }
                                                        </span>
                                                            {viewsObj.hasOwnProperty(view) && viewsObj[view].hasOwnProperty("aspathDiff") && viewsObj[view].aspathDiff !== 0 &&
                                                            <span className="report-detail__table-text-count">{viewsObj[view].aspathDiff}</span>
                                                            }
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                )
                            })}
                        </tr>
                    ))}
                    </tbody>
                ))}
            </table>
            <ReactTooltip place="bottom" className="CustomTooltip" id="hint"  getContent={dataTip => {
                
                let data = [];
    
                if (dataTip !== null && typeof dataTip === "string") {
                    data = dataTip.split(', ');
                };
    
                return (
                    <Fragment>
                        <span style={{ display: !data[0] && 'none'}} className={`${isCompare && 'CustomTooltipBlue' || ''}`}>{data[0]}</span>
                        <span style={{ display: !data[1] && 'none'}} className={`${isCompare && 'CustomTooltipGreen' || ''}`}>{data[1]}</span>
                    </Fragment>
                );
            }} />
        </Fragment>
    );
};

export default ReportTableCompare;