// Компоненнт ListBox

import React, { Fragment, useEffect, useState } from 'react';
import { observable } from "mobx"
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as InfoAPI from '../../helpers/api/Info';
import * as PoolAPI from '../../helpers/api/Pool';
import * as ReportAPI from '../../helpers/api/Report';
import ArrayModel from "../CommonTable/Models/ArrayModel";
import Loader from '../Loader/Loader';
import MultipleSelectPrefixesTable from "./MultipleSelectPrefixesTable";
import ajaxCache from "../../helpers/api/AjaxCache";
import { observer } from "mobx-react-lite"
import {fetchPrefixes} from "../../helpers/ReportUtils";


const MultipleSelectPrefixes = observer(props => {
    const { modal, resource, doClose } = props;
    const [state] = useState(observable({
        tableModel: new ArrayModel([], "id"),
        isLoaded: false
    }));

    state.tableModel.setPageSize(99999);

    useEffect(() => {
        (
            async () => {
                if (modal.active) {
                    let data = await getPrefixes(resource);
                    if(data) {
                        console.log({data});
                        data = [...(data.v4 ?? []), ...(data.v6 ?? [])];
                        state.tableModel.setData(data.map((name, i) => {
                            return {
                                id: i,
                                prefix: name
                            }
                        }));
                        console.log(state.tableModel);

                        let poolPrefixes = [];
                        if(localStorage['poolId']) poolPrefixes = await fetchPrefixes();
                        for(let i = 0;i < state.tableModel.getCount();i++) {
                            let readonly = false;
                            let info = false;
                            let as = undefined;

                            if(state.tableModel.elements[i].prefix in poolPrefixes) {
                                readonly = true;
                                info = poolPrefixes[state.tableModel.elements[i].prefix]['info'];
                                as = poolPrefixes[state.tableModel.elements[i].prefix]['as'];
                            }

                            state.tableModel.setVirtualProperty(i, {
                                checked: false,
                                readonly,
                                info,
                                as
                            });
                        }
                        state.isLoaded = true;
                    }
                }
            }
        )()
    }, [modal.active])

    const getPrefixes = async (resource) => {
        try {
            let response = await InfoAPI.generateData(resource);
            if(response.status === "error") {
                toast.error("Не удалось сгенерировать список префиксов");
                ajaxCache.refresh();
                doClose();
                return;
            }

            const requestId = response.data.request_id;
            response = await InfoAPI.getData(requestId);
            if (response.status === "error") {
                if(response.reason.code == "1029") {
                    while(!('data' in response) && response.reason.code == "1029") {
                        await new Promise(resolve => setTimeout(resolve, 1000));
                        ajaxCache.refresh();
                        response = await InfoAPI.getData(requestId);
                    }
                } else {
                    toast.error('Не удалось получить список префиксов');
                    ajaxCache.refresh();
                    doClose();
                    return;
                }
            }

            return response.data;
        } catch (error) {
            toast.error('Не удалось получить список префиксов');
            ajaxCache.refresh();
            doClose();
        }
    }

    const setSelectedPrefixName = (prefixName) => {
        const prevPrefix = document.querySelector('.list-box__option--active');
        prevPrefix && prevPrefix.classList.remove('list-box__option--active');
        localStorage.setItem('selectedPrefixName', prefixName);
    }

    const doApply = async () => {
        let prefixes = [];

        state.isLoaded = false;

        for(const row of state.tableModel.elements) {
            if(state.tableModel.getVirtualProperty(row['id'])['checked']) {
                if(localStorage['poolId']) {
                    await PoolAPI.addResource(localStorage['poolId'], {
                        name: row['prefix'],
                        resource: row['prefix'],
                        type: 'prefix',
                        comment: resource
                    });
                } else {
                    prefixes.push(row['prefix']);
                }
            }
        }

        if(!localStorage['poolId']) {
            const response = await ReportAPI.createTmp({
                prefixes: prefixes.join(',')
            });
            if(response.status === "error") {

            } else {
                localStorage["tmpReportHash"] = response.data.hash;
            }
        }

        setTimeout(() => doClose(), 1000);
    };

    return (
        <Fragment>
            <p className="modal__text">
                Выберите префиксы которые вы хотите добавить
            </p>
            <div className="modal__selector">
                <div className="MultipleSelectPrefixesContainer">
                    {state.isLoaded && (
                        <MultipleSelectPrefixesTable tableModel={state.tableModel}/>
                    ) || <Loader class="Loader"/>}
                </div>
            </div>
            <div className="modal__buttons">
                <button className="button button--apply modal__button" onClick={doApply}>Добавить</button>
                <button className="button button--cancel button--white modal__button" onClick={() => doClose('WITHOUT_CALLBACK')}>Отмена</button>
            </div>
        </Fragment>
    );
})

const mapStateToProps = state => {
    return {
        modal: state.modal,
    };
};

export default connect(mapStateToProps, null)(MultipleSelectPrefixes);
