import {MONITORING_LAST_ACTIVE} from '../constants';

export default (state = {}, action) => {

    const { type, payload } = action;
    if (type === MONITORING_LAST_ACTIVE) {
        return payload;
    }

    return localStorage['monitoringLastActive'] ?? state;
};
