import base32 from "base32";

export const prefixEncode = prefix => {
    return base32.encode(prefix);
}

export const prefixDecode = prefix => {
    return base32.decode(prefix);
}

