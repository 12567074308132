import Axios from "axios";
import {API_URL} from "../../redux/constants";
import * as ReportAPI from "./Report";

export const get = async (ipv = "ipv4") => {
    try {
        const response = await Axios.get(`${API_URL}/api/views/list/?af=${ipv}`);

        if (response.data.status !== 'ok')
            return console.error("Views.get API error");

        // определим выбранные и недоступные точки наблюдения
        response.data.data = await setCheckedPoints(response.data.data);

        return response.data;
    } catch (e) {
        console.error(`getViewsList error ${e}`);
    }
}

// найти используемые точки наблюдения
// TODO данный метод вероятно будет изменен
export const getUsed = async () => {
    try {
        let allReports = await ReportAPI.getList();

        if (allReports.status !== "ok")
            return console.error("Views.setCheckedPoints API error");

        return allReports.data[0].views;
    } catch (e) {
        console.error(`getViewsList error ${e}`);
    }
}

// TODO вернуть условие активности точек
// определим выбранные и недоступные точки наблюдения, только для авторизованных
export const setCheckedPoints = async (arViews) => {
    try {
        // получаем выбранные точки из отчеты
        const allReports = await ReportAPI.getList();
        const curReport = allReports.data.filter(el => el.report_id === +localStorage.getItem('reportId'))[0];

        if (allReports.status !== "ok")
            return console.error("Views.setCheckedPoints API error");


        Object.keys(arViews).forEach(view => {

            arViews[view].disabled = false;
            arViews[view].checked = false;

            if (typeof curReport?.views === "undefined") {
                arViews[view].checked = Boolean(arViews[view].default);
                arViews[view].disabled = true;
            }

            // найдем выделенные чекбоксы
            if (curReport?.views && curReport.views.length) {
                curReport.views.map(reportView => {
                    if (reportView.view === arViews[view].view
                        && reportView.type === arViews[view].type
                        && reportView.af === arViews[view].af) {
                        arViews[view].checked = true;
                    }
                })
            }

            // TODO раскомментить
            // if ((new Date() - new Date(view.updated)) > 3600 * 24 * 2) {
            //     view.disabled = true;
            // }

        })

        return arViews;
    } catch (e) {

    }
}

export const groupByType = (views = {}) => {
    /*let groupedViews = {
        'global': {
            'checked': true,
            'name': 'Глобальные',
            'items': {}
        },
        ...
    };*/

    let groupedViews = {};

    if (!Object.keys(views).length) {
        return groupedViews;
    }

    Object.keys(views).map(view => {
        if (!groupedViews.hasOwnProperty(views[view].type))
            groupedViews[views[view].type] = {
                'checked': true,
                'name': views[view].hasOwnProperty("type_name")
                    ? views[view].type_name
                    : "Неизвестная группа",
                'items': {}
            };

        groupedViews[views[view].type].items[view] = views[view];
    })


    return groupedViews;
}

export const getList = async () => {
    try {

        const ipv4 = await Axios.get(`${API_URL}/api/views/list/?af=ipv4`);
        const ipv6 = await Axios.get(`${API_URL}/api/views/list/?af=ipv6`);

        if (ipv4.data.status !== 'ok' || ipv6.data.status !== 'ok')
            return console.error("getViewsList API error");

        let result = {v4: ipv4.data.data, v6: ipv6.data.data};

        Object.keys(result).map(ipv => {
            let views = {};
            Object.keys(result[ipv]).map(view => {
                views[result[ipv][view].view] = result[ipv][view];
            })

            result[ipv] = views;
        })

        return result;
    } catch (error) {
        console.error(`getViewsList error ${error}`);
    }
}

// добавление точки к отчету
// reportId - ID репорта
// view - {} объект с данными о точке
export const addView = async (reportId, view) => {
    let result = [];

    try {
        const res = await Axios.post(
            `${API_URL}/api/report/addview/`,
            new URLSearchParams({
                reportid: reportId,
                view: JSON.stringify(view)
            }),
            {
                withCredentials: true
            }
        );

        result = res.data;

        return result;
    } catch (error) {
        console.error(`addViewPoint API error ${error}`);
    }
}

// удаление точки из отчета
// reportId - ID репорта
// view - {} объект с данными о точке
export const removeView = async (reportId, view) => {
    let result = [];

    try {
        const res = await Axios.post(
            `${API_URL}/api/report/delview/`,
            new URLSearchParams({
                reportid: reportId,
                view: JSON.stringify(view)
            }),
            {
                withCredentials: true
            }
        );

        result = res.data;

        return result;
    } catch (error) {
        console.error(`removeViewPoint API error ${error}`);
    }
}