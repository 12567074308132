// Страница "Регистрация"

import React, { Fragment } from "react";
import logoAuth from "../../img/logo.svg";
import Input from "../../components/UI/Input/Input";
import { NavLink, withRouter } from "react-router-dom";
import * as User from "../../helpers/api/User";
import Captcha from "../../components/Captcha/Captcha";
import * as CaptchaAPI from "../../helpers/api/Captcha";
import { toast } from "react-toastify";
import Routes from "../../helpers/Routes/Routes";
import { EMAIL_REGEX } from "../../helpers/RegEx";
import Headers from "../../helpers/Headers";
import Multicaptcha from "../Multicaptcha/Multicaptcha";
/*import * as modalActions from "../../redux/actions/modalActions";
import * as userActions from "../../redux/actions/userActions";
import {monitoringScrollYAction} from "../../redux/actions/monitoringScrollYAction";*/
import {connect} from "react-redux";
import {setJustRegisterAction} from "../../redux/actions/setJustRegisterAction";

class SignUp extends React.Component {
    state = {
        controls: {
            name: {
                controlType: "input",
                controlConfig: {
                    type: "text",
                },
                title: "Имя и фамилия",
                value: "",
                error: "",
            },
            login: {
                controlType: "input",
                controlConfig: {
                    type: "email",
                },
                title: "E-mail",
                value: "",
                error: "",
            },
            password: {
                controlType: "input",
                controlConfig: {
                    type: "password",
                },
                title: "Придумайте пароль",
                value: "",
                error: "",
            },
            telegram: {
                controlType: "input",
                controlConfig: {
                    type: "text",
                    placeholder: "Например, @ivan_petrov",
                },
                title: "Адрес в Telegram (необязательно)",
                value: "",
                error: "",
            },
        },
        captcha: {
            needRerender: false,
            showRecaptcha: false,
            attempts: 0,
        }
    };

    componentDidMount() {
        document.title = Headers.registration;
    }

    captchaCallback(data) {
        this.setState({
            captcha: {
                ...this.state.captcha,
                ...data,
                needRerender: false,
                verified: false,
            },
        });
    }

    createUser(data) {
        User.create(data).then((response) => {
            const { status, reason } = response.data;

            if (status === "error") {
                switch (reason.code) {
                    // Текущая почта существует
                    case 1010:
                        this.setState({
                            controls: {
                                ...this.state.controls,
                                login: {
                                    ...this.state.controls.login,
                                    error: reason.msg,
                                }
                            }
                        })
                        break;
                    default:
                        toast.error(`Code: ${reason.code} / ${reason.msg}`)
                        break;
                }
                return;
            }

            toast.info("Подтвердите ваш e-mail!");
            this.props.setJustRegister(true);
            this.props.history.push(Routes.checkMail);
        });
    }

    inputHandler(event, controlName) {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                error: this.state.controls[controlName].value && '',
            },
        };
        this.setState({ controls: updatedControls });
        sessionStorage.setItem('userEmail', this.state.controls.login.value);
    }

    formHandler(event) {
        event.preventDefault();

        const errors = [];
        const data = {
            name: this.state.controls.name.value,
            login: this.state.controls.login.value,
            password: this.state.controls.password.value,
            telegram: this.state.controls.telegram.value || "",
        };
        let nestedState = { ...this.state };

        for (const key in data) {
            nestedState = {
                controls: {
                    ...nestedState.controls,
                    [key]: {
                        ...nestedState.controls[key],
                        error: data[key] || key === 'telegram' ? '' : 'Поле не заполнено',
                    },
                },
            };

            data[key] ? errors.splice(0, 1) : errors.push('');
            key === "telegram" && errors.splice(0, 1);
        }

        if (errors.length) {
            this.setState(nestedState);
            return;
        }

        if (!EMAIL_REGEX.test(data.login)) {
            this.setState({
                controls: {
                    ...this.state.controls,
                    login: {
                        ...this.state.controls.login,
                        error: 'Введен некорректный e-mail'
                    }
                }
            })
            return;
        }

        this.setState(nestedState);

        if (!this.state.captcha.verified) {
            CaptchaAPI.verifyCaptcha(this.state.captcha).then((response) => {
                const { status, reason, limit } = response.data;

                if (status === "error") {
                    // Сюда скидываем введеный email и на всякий код ошибки с прохождения капчи.
                    const date = new Date();
                    sessionStorage.setItem('CaptchaLog', JSON.stringify({
                        status: reason.msg,
                        userEmail: sessionStorage.getItem('userEmail'),
                        dateTime: date.toString(),
                    }));
                    toast.error(`${reason.msg}`);

                    if (!limit) {
                        this.setState({
                            captcha: {
                                ...this.state.captcha,
                                needRerender: false,
                            },
                        });
                        return;
                    }

                    if (this.state.captcha.attempts === 2) {
                        this.setState({
                            captcha: {
                                ...this.state.captcha,
                                showRecaptcha: true,
                                reCaptchaValue: ""
                            },
                        });
                        return;
                    }
                    this.setState({
                        captcha: {
                            ...this.state.captcha,
                            needRerender: true,
                            attempts: this.state.captcha.attempts + 1,
                        },
                    });

                    return;
                }

                this.setState({
                    captcha: {
                        ...this.state.captcha,
                        verified: true,
                    },
                });

                this.createUser(data);
            });
            return;
        }

        if (this.state.captcha.showRecaptcha) {
            if (!this.state.captcha.reCaptchaValue)
                return toast.error("Не пройдена рекапча");

            data.reCaptchaValue = this.state.captcha.reCaptchaValue;
        }

        this.createUser(data);
    }

    render() {
        let formElementsArray = [];

        for (let type in this.state.controls) {
            formElementsArray.push({
                id: type,
                config: this.state.controls[type],
            });
        }

        const form = formElementsArray.map((formElement) => (
            <Input
                key={formElement.id}
                elementType={formElement.config.controlType}
                elementConfig={formElement.config.controlConfig}
                elementTitle={formElement.config.title}
                elementError={formElement.config.error}
                recovery={formElement.config.recovery}
                value={formElement.config.value}
                changed={(event) => {
                    this.inputHandler(event, formElement.id);
                }}
            />
        ));

        return (
            <Fragment>
                <section className="start-page">
                    <div className="start-page__logo-container">
                        <img
                            src={logoAuth}
                            alt=""
                            className="logo start-page__logo"
                        />
                    </div>
                    <p className="start-page__text">
                        Зарегистрируйтесь, чтобы начать работу с системой AS-Path.
                    </p>
                </section>
                <form
                    action=""
                    className="start-page__form start-page__form--registration"
                    onSubmit={this.formHandler.bind(this)}
                    noValidate
                >
                    <div className="start-page__form-wrapper">
                        {form}
                    </div>
                    <Multicaptcha
                        onVerified={(captcha) => {
                            toast.success('Капча пройдена!');
                            this.setState({
                                ...this.state,
                                captcha: {
                                    ...this.state.captcha,
                                    needRerender: false,
                                    verified: true
                                }
                            });
                        }}
                    />
                    <div className="start-page__form-wrapper">
                        <button type="submit" className="button">
                            Зарегистрироваться
                        </button>
                        <p className="start-page__text-bottom">
                            Уже зарегистрированы?{" "}
                            <NavLink className="link" to={Routes.auth}>
                                Войти
                            </NavLink>
                            .
                        </p>
                    </div>
                </form>
            </Fragment>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        justRegister: state.justRegister,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setJustRegister: data => dispatch(setJustRegisterAction(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
