import { MODAL_SHOW, MODAL_HIDE } from "../constants";

export default (state = {}, action) => {

    const { type, payload } = action;
    switch (type) {
        case MODAL_SHOW:
            return {
                ...payload,
                count: state.count + 1,
                active: true
            };
        case MODAL_HIDE:
            return {
                ...state,
                active: false
            };
        default:
            return {
                ...state,
                count: 0
            };
    }
};
