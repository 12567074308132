import ArrayModel from "../../CommonTable/Models/ArrayModel";
import {makeObservable, observable} from "mobx";

class MonitoringModel extends ArrayModel {
    constructor(array, idColumn) {
        super(array, idColumn);
        this.isMarked = false;

        makeObservable(this, {
            isMarked: observable
        })
    }

    updateIsMarked() {
        this.isMarked = this.elements.filter(e => this.getVirtualProperty(e[this.idColumn])['remove']).length;
    }

    async removeMarked(onDeletePrefix) {
        const forRemove = this.elements.filter(e => this.getVirtualProperty(e[this.idColumn])['remove'])
        for(const row of forRemove) {
            onDeletePrefix(forRemove.map(row => row['prefix']), () => super.removeMarked());
        };
        this.lengthCache = this.elements.length;
        this.updateIsMarked();
    }

    setVirtualProperty(id, value) {
        super.setVirtualProperty(id, value);
        this.updateIsMarked();
    }

    setPage(n) {
        if(n != 0) {
            document.location.hash = n + 1;
        } else {
            const noHashURL = window.location.href.replace(/#.*$/, '');
            window.history.replaceState('', document.title, noHashURL);
        }
        super.setPage(n);
    }
}

export default MonitoringModel;
