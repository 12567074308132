import {MONITORING_POSITION_SCROLL_Y} from '../constants';

export default (state = {}, action) => {

    const { type, payload } = action;
    if (type === MONITORING_POSITION_SCROLL_Y) {

        return payload;
    }

    return state;
};
