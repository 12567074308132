// Страница "Точки наблюдения"

import React, {Fragment} from "react";
import * as ViewsAPI from "../../helpers/api/Views";
import {toast} from "react-toastify";
import ViewPointsTable from "./ViewPointsTable/ViewPointsTable";
import {connect} from "react-redux";
import * as viewPointsActions from "../../redux/actions/viewPointsActions";
import Headers from "../../helpers/Headers";
import Loader from '../Loader/Loader';
import Routes from '../../helpers/Routes/Routes';
import ajaxCache from "../../helpers/api/AjaxCache";
import AuthGuard from "../../helpers/AuthGuard";
import H1 from "../UI/H1";
import {isSmallScreen} from "../UI/ui-helper";
import {useWindowSize} from "../../customHooks/useWindowSize";

class ViewPoints extends React.Component {
    constructor(props) {
        super(props);
        this.getData = this.getData.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.user.isLogin) !== JSON.stringify(this.props.user.isLogin)
        ) {
            this.getData();
        }
    }

    async componentDidMount() {
        ajaxCache.refresh();
        document.title = Headers.viewPoints;
        await this.getData();
    }

    async getData() {
        try {
            if (typeof this.props?.user?.isLogin === "undefined")
                return;

            let arViews = {},
                viewPoints = {
                    'v4': {
                        'name': 'IPv4',
                        'groups': {}
                    },
                    'v6': {
                        'name': 'IPv6',
                        'groups': {}
                    }
                };

            arViews = await ViewsAPI.getList();

            if (!Object.keys(arViews).length) {
                toast.error("При получении точек наблюдения возникла ошибка");
                return false;
            }

            // группировка
            await Promise.all(Object.keys(arViews).map(async (ipv) => {

                viewPoints[ipv].groups = arViews[ipv];
                // если юзер авторизован, то получим выбранные точки наблюдения
                if (this.props.user.isLogin)
                    viewPoints[ipv].groups = await ViewsAPI.setCheckedPoints(arViews[ipv]);

                viewPoints[ipv].groups = ViewsAPI.groupByType(viewPoints[ipv].groups);
            }));

            this.props.setViewPoints(viewPoints);
        } catch (e) {
            console.error(e);
        }

    }

    renderAfterAuth() {
        let viewPoints = this.props.allViewPoints;

        if(this.props.user.isLogin && this.props.user.tmpMode === false) {
            return (
                <Fragment>
                    <H1
                        text="Точки наблюдения"
                        smallSize={this.props.hook}
                        user={{}}
                    />
                    <section className="observation-points">
                        {(viewPoints && Object.keys(viewPoints).length > 0) &&
                        <section className="observation-points__tables">
                            {Object.keys(viewPoints).map((ipv, index) => {
                                    return (
                                        <ViewPointsTable key={index} isAuthorized={this.props.user.isLogin}
                                                         viewPoints={viewPoints[ipv]} ipv={ipv}
                                                         allViewPoints={this.props.allViewPoints}/>
                                    )
                                }
                            )}
                        </section> || <Loader class="Loader"/>
                        }
                    </section>
                </Fragment>
            );
        } else {
            this.props.history.push(Routes.registration);
            return <Loader class="Loader"/>;
        }
    }

    render() {
        return <AuthGuard
            onAuth={() => this.renderAfterAuth()}
            onTmp={() => this.props.history.push('/registration')}
            skipAuth={true} />;
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        allViewPoints: state.viewPoints
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setViewPoints: (data) => dispatch(viewPointsActions.setViewPoints(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPoints);
