// Прелоадер

import React from "react";
import { Puff } from 'svg-loaders-react';

const Loader = (props) => {
    return (
        <div className={props.class}>
            <Puff width="80" height="80" stroke="#53a2ea" fill="#53a2ea" />
        </div>
    );
};

export default Loader;