import {VIEW_POINTS_SET, VIEW_POINTS_CHANGED} from "../constants";
import {toast} from "react-toastify";

export const ViewPointsSetted = (payload) => {
    return {
        type: VIEW_POINTS_SET,
        payload: payload
    };
};

export const ViewPointsUpdated = (payload) => {
    return {
        type: VIEW_POINTS_CHANGED,
        payload: payload,
    };
};


export const setViewPoints = (data) => {
    return async (dispatch) => {
        try {
            dispatch(ViewPointsSetted(data));
        } catch (e) {
            return toast.error(`${e}`);
        }
    };
};

export const updateViewPoints = (data) => {
    return async (dispatch) => {
        try {
            dispatch(ViewPointsUpdated(data));
        } catch (e) {
            return toast.error(`${e}`);
        }
    };
};