const formDate = (date) => {
    // Мы не знаем, какие настройки вывода даты в браузере будут, поэтому строку формируем сами
    return date.getDate() + '-' + (date.getMonth()+1) + '-' + date.getFullYear();
};

export const showDate = (startDate, compareDate) => {
    //Если дата была установлена в календаре
    if ({}.toString.call(compareDate).slice(8, -1) === 'Date'){

        return formDate(compareDate);
    }

    return formDate(startDate);
};

export const showDateRaw = (startDate, compareDate) => {
    //Если дата была установлена в календаре
    return (compareDate!==undefined) ? compareDate : startDate;
};
