import React, {Fragment, useState} from "react";
import * as userActions from "../redux/actions/userActions";
import {connect} from "react-redux";
import Loader from "../components/Loader/Loader";
import * as ReportAPI from "./api/Report";
import ajaxCache from "./api/AjaxCache";
import Multicaptcha from "../components/Multicaptcha/Multicaptcha";

const AuthGuard = ({user, getUser, onAuth, onTmp, skipAuth}) => {
    const [state, setState] = useState({
        actualReport: 'no',
        captcha: {
            needVerify: false
        }
    });

    const createReport = () => {
        ReportAPI.create().then((response) => {
            ajaxCache.refresh();
            if(response.status === 'ok') {
                getUser().then(() => {
                    setState({ ...state, actualReport: 'yes' });
                });
            } else if(response.reason.code === 1004) {
                setState({
                    ...state,
                    actualReport: 'loading',
                    captcha: {
                        ...state.captcha,
                        needVerify: true
                    }
                });
            }
        });
    };

    if(state.actualReport === 'yes' || skipAuth) {
        let fragment = null;
        if (user.tmpMode === true && onTmp) {
            fragment = onTmp();
        } else if (user.tmpMode === false && onAuth) {
            fragment = onAuth();
        }
        return fragment ?? <Loader className="Loader"/>;
    }

    if(!('isLogin' in user) || user.tmpMode === undefined) {
        return <Loader className="Loader"/>
    }

    if(state.actualReport === 'no') {
        setState({ ...state, actualReport: 'loading' });
        if(user.isLogin) {
            if(user.tmpMode === false) {
                ReportAPI.getList().then(response => {
                    if (response && response.data && response.data.length) {
                        const lastReport = response.data[0];
                        localStorage['poolId'] = lastReport.pool_id;
                        localStorage['reportId'] = lastReport.report_id;
                        setState({...state, actualReport: 'yes'})
                    } else if(!response.data.length) {
                        createReport();
                    }
                });
            } else {
                setState({ ...state, actualReport: 'yes' });
            }
        } else {
            createReport();
        }
    }

    let additionalElement = null;
    if(state.captcha.needVerify) {
        additionalElement = <Multicaptcha onVerified={(captcha) => {
            setState({
                ...state,
                actualReport: 'no',
                captcha: {
                    ...state.captcha,
                    needVerify: false
                }
            });
        }}/>;
    }

    return <Fragment>
        { additionalElement }
        <Loader className="Loader"/>
    </Fragment>;
};

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setTmpMode: data => dispatch(userActions.setTmpMode(data)),
        getUser: () => dispatch(userActions.getUser()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);

