import React, {Fragment, useState} from 'react';
import { toast } from "react-toastify";
import LocationIcon from '../LocationIcon';

const PrefixDataGridRow = (props) => {
    const INDEX_MARK_STATUS = 2;

    let [over,setOver] = useState(false);
    
    const makeLengthView = (number, as_path_new) => {
        if (as_path_new.length) {
            if (number > 0) {
                return '+' + item.as_length_change;
            } else if (number < 0) {
                return "–" + Math.abs(item.as_length_change);
            } else if (JSON.stringify(item.as_path) !== JSON.stringify(as_path_new)) {
                return '0';
            }
        }
        return " ";
    }

    /**
     * Функция приводит элементы as-path к единому виду, в данном случае убирает '*1'
     * @param arrayAs - массив as-path
     * @returns {*[]|*} - возвращает изменённый массив as-path или исходные данные, если нечего обрабатывать
     */
    const normalizeAs = (arrayAs) => {
        let newArray = [];
        if (!arrayAs.length) {

            return arrayAs;
        }
        arrayAs.map((item, index) => {
            if (typeof (item) === 'string') {
                let pieces = (item.split('*'));
                pieces[1] = (pieces[1] === undefined) ? null : pieces[1];
                newArray.push(pieces);
            }

			return item;
        });
        return newArray;
    }

    /** Функция приводит длину пути к одному значению
     *
     */
    const normalizeLength = (array1, array2) => {
        if (array1.length && array2.length && array1.length < array2.length) {
            //array1;
            while (array1.length < array2.length) {
                array1.push([]);
            }
        }
        return array1.reverse();
    }

    const markAs = (arNew, arOld) => {
        if (!arOld.length) {

            return [arNew.reverse(), []];
        }
        const arNew1 = JSON.parse(JSON.stringify(arNew));
        const arOld1 = JSON.parse(JSON.stringify(arOld));
        // Проверка элементов одной строки на существование в другой, проверяется только первая часть
        const nonExistsNew = [];
        const nonExistsOld = [];
        arNew1.forEach((itemNew, i) => {
            let exists = false;
            arOld1.forEach((itemOld, i2) => {
                if (itemNew[0] === itemOld[0]){
                    exists = true;
                }
            });
            if (!exists){
                nonExistsNew.push(i);
            }
        });
        arOld1.forEach((itemNew, i) => {
            let exists = false;
            arNew1.forEach((itemOld, i2) => {
                if (itemNew[0] === itemOld[0]){
                    exists = true;
                }
            });
            if (!exists){
                nonExistsOld.push(i);
            }
        });
        nonExistsNew.forEach(item => {
            arNew1[item][INDEX_MARK_STATUS] = 'all';
        });
        nonExistsOld.forEach(item => {
            arOld1[item][INDEX_MARK_STATUS] = 'all';
        });
        //Остальная проверка элементов для раскраски
        for (let i=0; i < arNew1.length; i++) {
            if (arNew1[i].join('*') !== arOld1[i].join('*') && !arOld1[i][INDEX_MARK_STATUS] && !arNew1[i][INDEX_MARK_STATUS]){
                //если есть пустые значения
                if (!arNew1[i].length) {
                    arOld1[i][INDEX_MARK_STATUS] = 'all'
                } else if (arOld1[i] === []){
                    arNew1[i][INDEX_MARK_STATUS] = 'all'
                }
                if (!arOld1[i].length) {
                    arNew1[i][INDEX_MARK_STATUS] = 'all'
                } else if (arOld1[i] === []){
                    arOld1[i][INDEX_MARK_STATUS] = 'all'
                }
                // если нет пустых значений
                if (arNew1[i][0] !== arOld1[i][0]){
                    arNew1[i][INDEX_MARK_STATUS] = 'all';
                    arOld1[i][INDEX_MARK_STATUS] = 'all';
                } else {
                    arOld1[i][INDEX_MARK_STATUS] = '*'
                    arNew1[i][INDEX_MARK_STATUS] = '*';
                }
                /*if (arNew1[i][1] !== arOld1[i][1] && arNew1[i][1]){
                    arNew1[i][INDEX_MARK_STATUS] = '*'
                } else if (arNew1[i][1] !== arOld1[i][1] && !arNew1[i][1]){
                    arOld1[i][INDEX_MARK_STATUS] = '*'
                }*/
            }
        }
        return [arNew1.reverse(), arOld1.reverse()];
    }

    const countElements = array => {
        let count = 0;
        if (!array.length) {
            return count;
        }
        array.forEach(item => {
            if (item.length) {
                count++;
            }
        });
        return count;
    }

    const smallsize = false;
    let item = JSON.parse(JSON.stringify(props.data));
    const key = props.innerKey;
    const showChange = props.showChange;
    item.as_path = item.as_path ? normalizeAs(item.as_path) : [];
    item.as_path_new = item.as_path_new ? normalizeAs(item.as_path_new) : [];
    const exists_old = (item.as_path.length
        && item.as_path[0][0]!=='-');     // сейчас путь есть
    const exists_new = (item.as_path_new.length
        && item.as_path_new[0][0]!=='-'); // на дату сравнения путь есть
    const is_compare = !!item.as_path_new.length;
    item.as_path = normalizeLength(item.as_path, item.as_path_new);
    item.as_path_new = normalizeLength(item.as_path_new, item.as_path);
    if (JSON.stringify(item.as_path) === JSON.stringify(item.as_path_new)) {
        item.as_length_change = null;
    }
    [item.as_path, item.as_path_new] = markAs(item.as_path, item.as_path_new);
    const color = (!exists_new || item.as_length_change < 0) ? 'green' :
        (!exists_old || item.as_length_change > 0) ? 'red' : 'delta';
    if (item.as_path_new.length)
        item.as_length_change = !exists_old ? '-' : !exists_new ? '+' :
            makeLengthView(item.as_length_change, item.as_path_new);

    const copyPathToClipboard = e => {
        let txt = "";
        if (exists_old)
            txt += item.as_path.map(t => t[0]).filter(t => !!t).reverse().join(" ");
        if (exists_new) {
            if (!!txt)
                txt += "\n";
            txt += item.as_path_new.map(t => t[0]).filter(t => !!t).reverse().join(" ");
        }
        navigator.clipboard.writeText(txt).then(
            () => {
                toast.success(exists_old && exists_new ? "as_path скопированы" : "as_path скопирован");
            },
            (reason) => {
                toast.error("Ошибка");
                console.log(reason);
            }
        );
    }

    const buttonStyle={
        visibility: over ? 'visible' : 'hidden',
        top: exists_new && exists_old ? '-33px' : is_compare && !exists_new ? '-43px' : '-23px'
    }

    return (
        <Fragment>
            <div className={"prefix__data-grid__item__wrapper"}>
                <div>
                    {smallsize && <div className="prefix__data-grid__item__first-row">
                        <LocationIcon type={item.type}/>
                        <span className="prefix__data-grid__item__first-row__name">{item.title}</span>
                        <span className="prefix__data-grid__item__first-row__length">
                            <span
                                className="prefix__data-grid__item__first-row__length-old">{item.as_length}</span>
                            <span
                                className={'w9px ' + color}>{( item.as_length_change!== null) ? item.as_length_change : ' '}</span>
                        </span>
                        <span className="prefix__data-grid__item__first-row__as">{item.as}</span>
                    </div>}
                    <div key={key} className="prefix__data-grid__item" style={{
                      //      scrollbarWidth: (countElements(item.as_path) > 5 || countElements(item.as_path_new) > 5) ? 'thin' : 'none',
                    }}>
                        {!smallsize && <div className="prefix__data-grid__item__first-row">
                            <LocationIcon type={item.type}/>
                            <span className="prefix__data-grid__item__first-row__name">{item.title}</span>
                            <span className="prefix__data-grid__item__first-row__length">
                                <span
                                    className="prefix__data-grid__item__first-row__length-old"
                                    title="Длина пути на сегодня">{item.as_length}</span>
                                <span
                                    className={'w9px ' + color}
                                    title={
                                        !item.as_length_change ? "" :
                                        item.as_length_change==="+" ? "Данных для точки наблюдения не было на дату сравнения" :
                                        item.as_length_change==="-" ? "Нет данных на текущую дату" :
                                        item.as_length_change.slice(0,1)==="+" ? "Сегодня путь длинее, чем на дату сравнения" :
                                                                             // vvv "длинный минус"
                                        item.as_length_change.slice(0,1)==="–" ? "Сегодня путь короче, чем на дату сравнения" :
                                        item.as_length_change==="0" ? "Путь изменился, но длина осталась прежней" : ""}
                                >{( item.as_length_change!== null) ? item.as_length_change : ' '}</span>
                            </span>
                            <span className="prefix__data-grid__item__first-row__as"
                                  title={item.as.substr(0,2)==="AS"
                                      ?"AS точки наблюдения"
                                      :"Аббревиатура города для "+item.title}>{item.as}</span>
                        </div>}
                        <div
                            data-count={Math.max(countElements(item.as_path), countElements(item.as_path_new))}
                            onMouseOver={()=>setOver(true)}
                            onMouseOut={()=>setOver(false)}
                        >
                            <div className="prefix__data-grid__item__second_row"
                                 data-count={countElements(item.as_path)}>
                                {item.as_path.map((i, index) => (
                                    <span key={index + 'first' + key}
                                          title="Путь на сегодня">
                                            <span className={"prefix__data-grid__item__second_row__path_item0"}>
                                                <span className={((['all'].includes(i[INDEX_MARK_STATUS]) && i[0]!=='-') ?  (' light' + color) : '')}>
                                                    {i[0]!=='-' ? i[0] : <div>&nbsp;</div>}
                                                </span>
                                            </span>
                                            <span className={((['all', '*'].includes(i[INDEX_MARK_STATUS]) && i[1]) ?  (' light' + color) : 'count_hops')}>
                                                        {i[1] ? '*' + i[1] : i[1]}
                                            </span>
                                    </span>
                                ))}
                            </div>
                            <div className="prefix__data-grid__item__three_row"
                                 data-count={countElements(item.as_path_new)}>
                                {item.as_path_new.map((i, index) => (
                                    <span key={index + 'first' + key}
                                          title="Путь на дату сравнения">
                                                <span className={"prefix__data-grid__item__second_row__path_item0"}>
                                                    <span className={((['all'].includes(i[INDEX_MARK_STATUS]) && i[0]!=='-') ?  (' light' + color) : 'www')}>
                                                        {i[0]!=='-' ? i[0] : <div>&nbsp;</div>}
                                                    </span>
                                                </span>
                                                <span className={"prefix__data-grid__item__second_row__path_item1"}>
                                                    <span className={((['all', '*'].includes(i[INDEX_MARK_STATUS]) && i[1]) ?  (' light' + color) : '')}>
                                                        {i[1] ? '*' + i[1] : i[1]}
                                                    </span>
                                                </span>
                                    </span>
                                ))}
                            </div>
                            <div className="btn-copy-wrapper">
                                <button type="button"
                                        className="btn-copy"
                                        style={buttonStyle}
                                        title="Скопировать"
                                        onClick={copyPathToClipboard}></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    marginRight: '16px',
                    display: 'flex',
                    justifyContent: 'end',
                    overflow: 'auto',
                }}>
                </div>
                {/*	</div>
                 </div> */}
            </div>
        </Fragment>
    );
}


export default PrefixDataGridRow;
