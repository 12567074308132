import chroma from 'chroma-js';

const fromYellowToGreen = chroma.scale(['#314756', '#FBF883']).mode('lch').colors(5);
const fromBeigeToOrange = chroma.scale(['#BF4E3D','#F5DEC6']).mode('lch').colors(5);
const fromLightVioletToViolet = chroma.scale(['#534084','#F3DDDA']).mode('lch').colors(5);
const fromLightBlueToBlue = chroma.scale(['#1A3373','#A7C8CD']).mode('lch').colors(5);
const fromLightBrownToBrown = chroma.scale(['#4D2139','#C9B9A5']).mode('lch').colors(5);


// const arrayOfColors = [
//     ...fromYellowToGreen,
//     ...fromBeigeToOrange,
//     ...fromLightVioletToViolet,
//     ...fromLightBlueToBlue,
//     ...fromLightBrownToBrown
// ].sort(() => Math.random() - 0.5);

const arrayOfColors = [];
const pallet = [
    fromYellowToGreen,
    fromBeigeToOrange,
    fromLightVioletToViolet,
    fromLightBlueToBlue,
    fromLightBrownToBrown
];

fromBeigeToOrange.forEach((color, colorIndex) => {
    pallet.forEach(group => {
        arrayOfColors.push(group[colorIndex]);
    })
})

export default arrayOfColors;