import React, { useState } from "react";
import * as UserAPI from "../../helpers/api/User";
import {toast} from "react-toastify";

const ProfileFreqType = ({userTransport, getUser}) => {
    const [transport, setTransport] = useState(userTransport);

    const saveTransport = async (newTransport) => {
        const data = {
            reports_transport: newTransport,
        };
        const response = await UserAPI.update(data);
        const { status, reason } = response.data;

        if (status === "error") return toast.error(`${reason.msg}`);

        toast.success("Метод нотификации изменен");
        getUser();
    };

    const updateTransport = (newTransport) => {
        setTransport(newTransport);
        saveTransport(newTransport);
    };

    return (
        <div className="profile--freq_type">
            <p>Куда присылать нотификацию об изменениях:</p>
            <div className="profile--freq_type--call">
                <div className="profile--freq_type--c1">
                    <input type="checkbox" className="MonitoringCheckbox" onChange={() => updateTransport(0)} checked={transport === 0}/> На e-mail
                </div>
                <div className="profile--freq_type--c2">
                    <input type="checkbox" className="MonitoringCheckbox" onChange={() => updateTransport(1)} checked={transport === 1}/> Не уведомлять
                </div>
            </div>
        </div>
    )
};

export default ProfileFreqType;
