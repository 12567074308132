import React from "react";


export const TrashIcon = (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.3" d="M11.3335 13.3332H20.2224V24.4443H11.3335V13.3332Z" fill="#F4002C"/>
        <path
            d="M19.6667 7.77762L18.5556 6.6665H13L11.8889 7.77762H8V9.99984H23.5556V7.77762H19.6667ZM9.11111 24.4443C9.11111 25.6665 10.1111 26.6665 11.3333 26.6665H20.2222C21.4444 26.6665 22.4444 25.6665 22.4444 24.4443V11.1109H9.11111V24.4443ZM11.3333 13.3332H20.2222V24.4443H11.3333V13.3332Z"
            fill="#FF1A43"/>
    </svg>
);

export default TrashIcon;
