import { makeObservable, observable, action } from "mobx";

class AbstractModel {
    sort = {
        by: localStorage.monitoringColumn ?? 'prefix',
        order:  ['asc', 'desc'].includes(localStorage.monitoringDirection) ? localStorage.monitoringDirection : 'asc'
    };

    virtualProperties = {};

    pageSize = 8;
    page = 0;

    constructor() {
        makeObservable(this, {
            sort: observable,
            pageSize: observable,
            page: observable,
            virtualProperties: observable,
            setSortBy: action,
            setSortOrder: action,
            resetSort: action,
            setPage: action,
            setPageSize: action,
            backPage: action,
            nextPage: action,
            setRowValue: action,
            setVirtualProperty: action,
            removeRow: action
        });
    }

    getSortBy() {
        return localStorage.monitoringColumn ?? this.sort.by;
    }

    getSortOrder() {
        return this.sort.order;
    }

    setSortBy(by) {
		localStorage.monitoringColumn = by;
        this.sort.by = by;
    }

    setSortOrder(order) {
		localStorage.monitoringDirection = order;
        this.sort.order = order;
    }

    resetSort() {
        this.sort.order = 'asc';
        this.sort.by = null;
    }

    getPage() {
        return this.page;
    }

    setPage(n) {
        this.page = n;
    }

    getPageSize() {
        return this.pageSize;
    }

    setPageSize(n) {
        this.pageSize = n;
    }

    nextPage() {
        this.setPage(this.page + 1);
    }

    backPage() {
        this.setPage(this.page - 1);
    }

    getCount() {
        return 0;
    }

    getPagesCount() {
        return Math.ceil(this.getCount() / this.getPageSize());
    }

    getRows(startIndex, count) {
        return [];
    }

    setRowValue(id, column, value) {

    }

    getPageElements() {
        return this.getRows(this.getPage() * this.getPageSize(), this.getPageSize());
    }

    setVirtualProperty(id, value) {
        this.virtualProperties = {
            ...this.virtualProperties,
            [id]: JSON.parse(JSON.stringify(value))
        };
    }

    getVirtualProperty(id) {
        if(id in this.virtualProperties) {
            return this.virtualProperties[id];
        }
        return {};
    }

    removeRow(id) {
        if(this.getPage() > 1 && this.getPage() <= this.getPagesCount()) {
            this.setPage(this.getPagesCount()-1);
        }
    }
}

export default AbstractModel;
