export default {
    reportToDate: '/report/:date',
    report: '/report',
    changePasswordFromProfile: '/changepass/profile',
    changePassword: '/changepass',
    first: '/first',
    auth: '/entry',
    registration: '/registration',
    userSettings: '/user_settings',
    recovery: '/recovery',
    confirmEmail: '/verifymail',
    unsubscribe: '/unsubscribe',
    compare: '/compare',
    viewpoints: '/viewpoints',
    about: '/about',
    history: '/reports_history',
    resource: '/resource',
    index: '/',
    monitoring: '/monitoring',
	prefix: '/prefix/:prefixName',
    checkMail: '/checkMail',
};