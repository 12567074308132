// Компонент шапки

import React, {useRef} from "react";
import {NavLink, useLocation, withRouter} from 'react-router-dom';
import Navbar from "../Navbar/Navbar";
import logoHeader from '../../img/logo-header.svg';
import Routes from '../../helpers/Routes/Routes';
import ReportSelector from "../ReportSelector/ReportSelector";
import IpToggle from "../IpToggle/IpToggle";
import {connect} from "react-redux";
import CopyButton from "../CopyButton/CopyButton";

const Header = (props) => {
    const burgerButton = useRef(null);
    const nav = document.querySelector('.header__nav');
    const location = useLocation();

    const showBurgerMenu = () => {
        burgerButton.current.classList.toggle('burger-button--active');
        document.querySelector('body').classList.toggle('no-scroll');
        nav.classList.toggle('header__nav--visible');
    }

    const getComponentNameByRoute = () => {
        let component = '';

        switch (props.match.path) {
            case Routes.report:
            case Routes.reportToDate:
                component = "Report";
                break;
            case Routes.history:
                component = "History";
                break;
			default: 
				break;
        }

        return component;
    }

    return (
        <header className={`header ${location.pathname.includes(Routes.report || Routes.history) ? 'header--mobile' : ''}`}>
            <div className="container-header">
                <NavLink className="header__link" to={Routes.first}>
                    <img className="logo header__logo" src={logoHeader} alt=""/>
                </NavLink>
                {location.pathname.includes(Routes.report || Routes.history) &&
                    <div className="header__report-settings">
                        {props.user.isLogin &&
                            <ReportSelector />
                        }
                        <IpToggle component={getComponentNameByRoute()}/>
                        {location.pathname.includes(Routes.report) &&
                            <div className="xs-hide xs-landscape-show">
                                <CopyButton copyValue={document.location.origin + document.location.pathname + "?hash=" + (localStorage.getItem("reportHash") || localStorage.getItem('tmpReportHash'))}/>
                            </div>
                        }
                    </div>
                }
                <Navbar burgerButton={burgerButton}/>
                <button onClick={showBurgerMenu} ref={burgerButton} className="burger-button header__burger-button">
                    <span className="burger-button__line"></span>
                </button>
            </div>
        </header>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
};

export default connect(mapStateToProps)(withRouter(Header));
