// Формирование точек наблюдения в фильтре

import React from "react";
import * as filterActions from "../../../redux/actions/filterActions";
import {connect} from "react-redux";

class ViewPointsGroup extends React.Component {
    constructor(props) {
        super(props);

        this.bindViewToReport = this.bindViewToReport.bind(this);
        this.bindGroupToReport = this.bindGroupToReport.bind(this);
    }

    bindViewToReport(e) {
        let target = e.currentTarget,
            isChecked = target.checked,
            currentView = target.getAttribute("data-view"),
            currentGroup = target.getAttribute("data-group"),
            filterData = JSON.parse(JSON.stringify(this.props.filterData)),
            filterKeys = ["resources", "resourcesCompare"];

        filterKeys.forEach(key => {
            if (!filterData.hasOwnProperty(key)) return;

            Object.keys(filterData[key]).forEach(resource => {
                if (!filterData[key].hasOwnProperty(resource)) return;

                Object.keys(filterData[key][resource].prefixes).forEach(prefix => {
                    if (!filterData[key][resource].prefixes.hasOwnProperty(prefix)) return;

                    Object.keys(filterData[key][resource].prefixes[prefix].views).forEach(view => {
                        if (!filterData[key][resource].prefixes[prefix].views.hasOwnProperty(view)) return;

                        if (view === currentView) {
                            filterData[key][resource].prefixes[prefix].views[view].checked = isChecked;
                        }
                    })
                })
            })
        })

        filterData.viewPoints[currentGroup].items[currentView].checked = isChecked;

        // is all checked?
        /*const filteredArray = Object.keys(filterData.viewPoints[currentGroup].items).filter(el => filterData.viewPoints[currentGroup].items[el].checked)
        if (!filteredArray.length) {
            this.setState({ expanded: false});
            filterData.viewPoints[currentGroup].checked = false;
        }*/

        this.props.updateFilter(filterData);
    }

    bindGroupToReport(e) {
        let target = e.currentTarget,
            isChecked = target.checked,
            currentGroup = target.getAttribute("data-group"),
            filterData = JSON.parse(JSON.stringify(this.props.filterData)),
            filterKeys = ["resources", "resourcesCompare"];

        /*filterKeys.forEach(key => {
            if (!filterData.hasOwnProperty(key)) return;

            Object.keys(filterData[key]).forEach(resource => {
                if (!filterData[key].hasOwnProperty(resource)) return;

                Object.keys(filterData[key][resource].prefixes).forEach(prefix => {
                    if (!filterData[key][resource].prefixes.hasOwnProperty(prefix)) return;

                    Object.keys(filterData[key][resource].prefixes[prefix].views).forEach(view => {
                        if (!filterData[key][resource].prefixes[prefix].views.hasOwnProperty(view)) return;

                        if (filterData.viewPoints[currentGroup].items.hasOwnProperty(view)) {
                            filterData[key][resource].prefixes[prefix].views[view].checked = isChecked;
                        }
                    })
                })
            })
        })*/

        filterData.viewPoints[currentGroup].checked = isChecked;
        /*Object.keys(filterData.viewPoints[currentGroup].items).forEach(view => {
            filterData.viewPoints[currentGroup].items[view].checked = isChecked;
        })*/

        //this.setState({ expanded: !this.state.expanded })
        this.props.updateFilter(filterData);
    }

    render() {
        let viewPoints = this.props.viewPoints;

        if (!Object.keys(viewPoints.items).length)
            return null;

        return (
            <li key={this.props.group}
                className={`filter__item ${viewPoints.checked ? "filter__item--expanded" : ""}`}>
                <div className="filter__item-header">
                    <label className="filter__label">
                        <input type="checkbox" data-group={this.props.group} className="visually-hidden" checked={viewPoints.checked} onChange={this.bindGroupToReport} />
                        <span className="checkbox"></span>
                    </label>
                    <span
                        className={`filter__item-title filter__item-title--nested filter__item-title--points ${viewPoints.checked ? "filter__item-title--expanded" : ""}`}>{this.props.name}</span>
                </div>

                <ul className={`filter__list filter__list--nested ${viewPoints.checked && "filter__item-title--expanded"}`}>
                    {
                        Object.keys(viewPoints.items).map((view, key) => {
                            return (
                                <li key={key} className="filter__item">
                                    <div className="filter__item-header">
                                        <label className="filter__label">
                                            <input type="checkbox"
                                                   checked={viewPoints.items[view].checked}
                                                   className="visually-hidden"
                                                   disabled={viewPoints.items[view].disabled}
                                                   onChange={this.bindViewToReport}
                                                   data-view={viewPoints.items[view].view}
                                                   data-group={this.props.group}
                                            />
                                            <span className="checkbox"></span>
                                        </label>
                                        <span className="filter__item-title">{viewPoints.items[view].name}</span>
                                    </div>
                                    {viewPoints.items[view].cities &&
                                    <span className="filter__location">{viewPoints.items[view].cities}</span>
                                    }
                                </li>
                            );
                        })
                    }
                </ul>
            </li>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (data) => dispatch(filterActions.updateFilter(data))
    };
};

export default connect(null, mapDispatchToProps)(ViewPointsGroup);