import React from "react";
// import Switch from '@material-ui/core/Switch';
import Switch from "react-switch";
import {connect} from "react-redux";
import switchClicked from "../../redux/actions/switchActions";

const Switcher = props => {  
	const handleChange = (checked)  => {
	  return !checked;
  };

  return (
          <Switch
            checked={props.checked ? true : false}
			onChange={handleChange}
            onColor="#cccccc"
			offColor="#cccccc"
            onHandleColor="#ffffff"
			offHandleColor="#1A75FF"
            handleDiameter={18}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 2px 2px 0px #9FA2BF52"
            activeBoxShadow="0px 2px 2px 0px #9FA2BF52"
            height={8}
            width={33}
            className="react-switch"
            id="material-switch"
          />
    )
};
/**
 * отправка maps в props
 **/
function mapDispatchToProps(dispatch) {
	return {
		switchStatus: (data) => dispatch(switchClicked(data)),
	}
}
/**
 * maps передаёт свойство в props
 **/
function mapStateToProps(state) {
	return {
		checked: state.checked
	}
}
export default connect(mapStateToProps, mapDispatchToProps)(Switcher)
