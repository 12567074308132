import React, {useState} from "react";
import {observer} from "mobx-react-lite";
import Captcha from "../Captcha/Captcha";
import * as CaptchaAPI from "../../helpers/api/Captcha";
import {toast} from "react-toastify";
import {observable} from "mobx";

const Multicaptcha = observer(({ onVerified, disable }) => {
    const [state] = useState(observable({
        attemps: 0,
        disable: false,
        incAttemps() {
            this.attemps++;
        },
        setDisable() {
            this.disable = true;
        }
    }));

    const captchaOnSubmit = (captcha, selectedImages) => {
        CaptchaAPI.verifyCaptcha({
            ...captcha,
            data: selectedImages
        }).then((response) => {
            if(response.data.status === "ok") {
                state.setDisable();
                if(onVerified) {
                    if(onVerified(captcha) !== false) {
                        state.incAttemps();
                    }
                }
            } else {
                state.incAttemps();
            }
        });
    };

    const captchaCallback = (response) => {
        if('g-recaptcha' in response) {
            captchaOnSubmit(response, false);
        } else
          if(response.status === "error") {
              toast.error(response.reason.msg);
          }
    };

    if(!state.disable && !disable) {
        return <div className="Multicaptcha__Container">
            <Captcha
                key={state.attemps}
                callback={captchaCallback}
                onSubmit={captchaOnSubmit}
                showRecaptcha={true}
            />
        </div>;
    } else {
        return <div className="Multicaptcha__Container"></div>;
    }
});

export default Multicaptcha;
