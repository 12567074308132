import React from 'react';
import AuthGuard from "../../helpers/AuthGuard";
import Prefix from "./Prefix";

const PrefixAuth = ({sortableColumn, direction, smallHook}) => {

      function render() {
            return <Prefix hook={smallHook} sortableColumn={sortableColumn} direction={direction}/>;
      }

      return <AuthGuard onAuth={() => render()}
                        onTmp={() => render()}/>;
};

export default PrefixAuth;
