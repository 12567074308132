import React from "react";
import {observer} from "mobx-react-lite";
import * as PoolAPI from "../../../helpers/api/Pool";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {setMonitoringLastActive} from '../../../redux/actions/monitoringLastActive';
import Commentary from "../../Commentary/Commentary";
import {prefixEncode} from '../../../helpers/prefix'

const ColumnPrefixRenderer = observer(({ row, tableModel, setMonitoringLastActive }) => {
    return (
        <div className="MonitoringTable__ColumnPrefix">
            {
                row.pending_update ?
                    <b>{row["prefix"]}</b>
                    :
                    <Link to={'/prefix/' + prefixEncode(row['prefix'])}
                          title="Подробности по префиксу..."
                          onClick={() => setMonitoringLastActive(row['prefix'])}>{row["prefix"]}</Link>
            }
            <Commentary text={row['info']} onSave={(text) => {
                tableModel.setRowValue(row["id"], "info", text);
                PoolAPI.updateResource(localStorage.poolId, {
                    resource: row["prefix"],
                    type: "prefix",
                    comment: text
                });
            }}/>
        </div>
    );
});

const mapStateToProps = (state) => {
    return {
        monitoringLastActive: state.monitoringLastActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMonitoringLastActive: (prefixName) => dispatch(setMonitoringLastActive(prefixName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnPrefixRenderer);
