// Страница изменения пароля

import React from "react";
import * as User from "../../helpers/api/User";
import logoAuth from '../../img/logo.svg';
import { NavLink } from 'react-router-dom';
import { toast } from "react-toastify";
import Routes from "../../helpers/Routes/Routes";
import Headers from "../../helpers/Headers";

class ChangePass extends React.Component {
    constructor(props) {
        super(props);

        let hash = (new URL(document.location)).searchParams.get("hash");
        let email = (new URL(document.location)).searchParams.get("email");

        this.state = {
            password: '',
            confirm_password: '',
            errors: {
                password: '',
                confirm_password: '',
            },
            hash: hash || '',
            email: email || '',
            isSend: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        document.title = Headers.passwordRecovery;
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value,
            errors: {
                ...this.state.errors,
                [event.target.name]: this.state[event.target.name] && ''
            },
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const errors = [];

        try {
            this.setState({
                errors: {
                    password: (!this.state.password && errors.push('')) && 'Поле не заполнено',
                    confirm_password: (!this.state.confirm_password && errors.push('')) && 'Поле не заполнено'
                }
            })

            if (errors.length) return;

            if (this.state.password !== this.state.confirm_password) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        password: 'Пароли не совпадают',
                        confirm_password: 'Пароли не совпадают'
                    }
                })
                return false;
            }

            User.resetPassword(this.state.hash, this.state.email, this.state.password)
                .then(response => {
                    let res = response.data;

                    if (res.status === "error") {
                        toast.error(`${res.reason.msg}`);
                        return false;
                    }

                    if (res.status === "ok") {
                        this.setState({
                            isSend: true
                        });
                    }

                });
        } catch (e) {
            toast.error("При восстановлении пароля возникла ошибка, попробуйте позднее");
            console.error(`${e}`);
        }

    }

    render() {
        let html;

        html =
            <section className="start-page">
                <div className="start-page__logo-container">
                    <img src={logoAuth} alt="DasPath" className="logo start-page__logo" />
                </div>
                <p className="start-page__text">Придумайте новый пароль.</p>
                <form onSubmit={this.handleSubmit} className="start-page__form">
                    <label className={`label-field ${this.state.errors.password && 'label-field--error'}`}>
                        <div className="label-field__header">
                            <span className="label-field__name">Новый пароль</span>
                        </div>
                        <input className="field" onChange={this.handleChange} type="password" name="password" />
                        {this.state.errors.password && <span className="error label-field__error">{this.state.errors.password}</span>}
                    </label>
                    <label className={`label-field ${this.state.errors.confirm_password && 'label-field--error'}`}>
                        <div className="label-field__header">
                            <span className="label-field__name">Повторите пароль</span>
                        </div>
                        <input className="field" type="password" onChange={this.handleChange} name="confirm_password" />
                        {this.state.errors.confirm_password && <span className="error label-field__error">{this.state.errors.confirm_password}</span>}
                    </label>
                    <button type="submit" className="button">Войти</button>
                </form>
            </section>;

        if (!this.state.hash) {
            html =
                <section className="start-page">
                    <div className="start-page__logo-container">
                        <img src={logoAuth} alt="DasPath" className="logo start-page__logo" />
                    </div>
                    <p className="start-page__text">Некорректная ссылка для восстановления пароля</p>
                </section>;
        }

        if (this.state.isSend) {
            html =
                <section className="start-page">
                    <div className="start-page__logo-container">
                        <img src={logoAuth} alt="DasPath" className="logo start-page__logo" />
                    </div>
                    
                    <p className="start-page__text">Пароль успешно изменен, <NavLink className="link link--fz16" to={Routes.auth}>авторизуйтесь</NavLink> с новым паролем.</p>
                </section>;
        }

        return html;
    }
}

export default ChangePass;