// Компонент ресурса в таблице

import React, { useRef, useState, useEffect } from 'react';
import penIcon from "../../../img/pen-icon.svg";
import basketIcon from "../../../img/basket-icon.svg";
import transferIcon from "../../../img/transfer-icon.svg"
import * as PoolAPI from "../../../helpers/api/Pool";
import {toast} from "react-toastify";
import Loader from "../../Loader/Loader";

const PoolElement = (props) => {
    const nameRef = useRef();
    const commentRef = useRef();
    const [state, setState] = useState({
        name: {
            isEdit: false,
            prevValue: props.resource.name,
            ref: nameRef,
            text: ''
        },
        comment: {
            isEdit: false,
            prevValue: props.resource.comment || "Комментарий к ресурсу",
            ref: commentRef,
            text: '',
        },
    });

    useEffect(() => {
        setState({
            ...state,
            name: {
                ...state.name,
                text: props.resource.name,
            },
            comment: {
                ...state.comment,
                text: props.resource.comment
            }
        })
    }, [props, state])

    const modalData = {
        resource: props.resource.resource,
        type: props.resource.type,
        currentReport: {
            reportId: localStorage.getItem('reportId'),
            poolId: localStorage.getItem('poolId'),
        },
        updateSelector: props.updateReportSelector,
    };

    const cancelEdit = (e) => {
        let field = e.currentTarget.getAttribute("data-field");

        state[field].ref.current.value = state[field].prevValue;

        setState({
            ...state,
            [field]: {
                ...state[field],
                text: state[field].prevValue,
                isEdit: false
            }
        }, window.getSelection().removeAllRanges())
    }

    const saveEdit = (e) => {
        let field = e.currentTarget.getAttribute("data-field"),
            data = {
                resource: props.resource.resource,
                type: props.resource.type
            };

        data[field] = state[field].ref.current.value;

        if (!data[field]) {
            state[field].ref.current.focus();
            return toast.error(`Поле обязательно для заполнения`);
        }

        PoolAPI.updateResource(
            localStorage.getItem("poolId"),
            data
        ).then(result => {

            if (result.status === "error")
                return toast.error(result.reason.msg ? `${result.reason.msg}` : "При обновлении ресурса возникла ошибка");

            toast.success(`Ресурс ${props.resource.resource} успешно обновлён`);

            setState({
                ...state,
                [field]: {
                    ...state[field],
                    prevValue: state[field].ref.current.value,
                    isEdit: false
                }
            }, window.getSelection().removeAllRanges())
        })
    }

    const setEditable = (e) => {
        const field = e.currentTarget.getAttribute("data-field");

        setTimeout(function() {
            state[field].ref.current.selectionStart = state[field].ref.current.value.length;
            state[field].ref.current.focus();
            state[field].ref.current.select();
        }, 100);

        setState({
            ...state,
            [field]: {
                ...state[field],
                isEdit: true
            }
        })
    }

    const subnetDragStart = (event, prefix) => {
        event.dataTransfer.setData('text', prefix);
        props.spacerRef.current.classList.add('spacer--visible');
        event.currentTarget.classList.add('resource__ip-container--selected');
    }

    const subnetDragEnd = event => {
        props.spacerRef.current.classList.remove('spacer--visible');
        event.currentTarget.classList.remove('resource__ip-container--selected');
        event.dataTransfer.clearData('text');
    }
/*
    const onKeyDown = event => {
        let text = event.currentTarget.textContent,
            maxlength = parseInt(event.currentTarget.getAttribute('maxLength'));

        if (text.length > maxlength && (
            event.keyCode != 8 && event.keyCode != 37 && event.keyCode != 38 && event.keyCode != 39 && event.keyCode != 40
        )) {
            event.preventDefault();
            return false;
        }
    }*/

    const changeText = (event, type) => {
        setState({
            ...state,
            [type]: {
                ...state[type],
                text: event.target.value,
            }
        });
    }

    return (
        <tr>
            <td>
                <span className="resource__name">{props.resource.resource}</span>
                <div className="resource__name-group" style={{ paddingLeft: !state.name.isEdit && '30px' }}>
                    <textarea type="text" maxLength="24" className="field resource__secondary-text" ref={state.name.ref} disabled={!state.name.isEdit} value={state.name.text} placeholder="Задать имя" onChange={event => changeText(event, 'name')}>
                    </textarea>
                    {!state.name.isEdit && <button title="Редактировать" className="button-edit resource__button-edit" data-field="name"
                            onClick={setEditable}>
                        <svg width="16" height="12">
                            <use xlinkHref={`${penIcon}#pen-icon`}></use>
                        </svg>
                    </button>}
                    {state.name.isEdit &&
                    <div className="profile__tools">
                        <button
                            className="profile-button profile__button"
                            onClick={saveEdit}
                            data-field="name"
                        >
                            Сохранить
                        </button>
                        <button
                            className="profile-button profile__button profile-button--cancel"
                            onClick={cancelEdit}
                            data-field="name"
                        >
                            Отмена
                        </button>
                    </div>
                    }
                </div>
                {/*<span className="resource__secondary-text">ФС Москва</span>*/}
            </td>
            <td>
                {(!props?.resource?.prefixes?.v4 || !props.resource.prefixes.v4.length) &&
                <Loader class="resources-loader"/>
                }
                {props.resource.prefixes.v4 && props.resource.prefixes.v4.length > 0 &&
                props.resource.prefixes.v4.map((prefix, index) => {
                    return (
                        <div key={index}
                            className="resource__ip-container"
                            draggable
                            onDragStart={event => subnetDragStart(event, prefix)}
                            onDragEnd={event => subnetDragEnd(event)}>
                            <span className="resource__ip">{prefix}</span>
                            {/*<span className="resource__secondary-text">Краснодар-1243</span>*/}
                        </div>
                    );
                })
                }
            </td>
            <td>
                {!((props.resource.prefixes.v4 && props.resource.prefixes.v4.length > 0)
                || (props.resource.prefixes.v6 && props.resource.prefixes.v6.length > 0)) &&
                <Loader class="resources-loader"/>
                }
                {props.resource.prefixes.v6 && props.resource.prefixes.v6.length > 0 &&
                props.resource.prefixes.v6.map((prefix, index) => {
                    return (
                        <div key={index}
                            className="resource__ip-container"
                            draggable
                            onDragStart={event => subnetDragStart(event, prefix)}
                            onDragEnd={event => subnetDragEnd(event)}>
                            <span className="resource__ip">{prefix}</span>
                            {/*<span className="resource__secondary-text">Краснодар-1243</span>*/}
                        </div>
                    );
                })
                }
            </td>
            <td>
                <div className="resource__name-group">
                    <textarea type="text" maxLength="64" ref={state.comment.ref} className="field resource__commentary" disabled={!state.comment.isEdit} value={state.comment.text} placeholder="Комментарий к ресурсу" onChange={event => changeText(event, 'comment')}></textarea>
                    {!state.comment.isEdit && <button title="Редактировать" className="button-edit resource__button-edit" data-field="comment"
                            onClick={setEditable}>
                        <svg width="16" height="12">
                            <use xlinkHref={`${penIcon}#pen-icon`}></use>
                        </svg>
                    </button>}
                    {state.comment.isEdit &&
                    <div className="profile__tools">
                        <button
                            className="profile-button profile__button"
                            onClick={saveEdit}
                            data-field="comment"
                        >
                            Сохранить
                        </button>
                        <button
                            className="profile-button profile__button profile-button--cancel"
                            onClick={cancelEdit}
                            data-field="comment"
                        >
                            Отмена
                        </button>
                    </div>
                    }
                </div>
            </td>
            <td>
                <button title="Удалить ресурс" className="button-delete" data-resource={props.resource.resource}
                        data-type={props.resource.type}
                        onClick={props.removeEvent}>
                    <svg width="16" height="12">
                        <use xlinkHref={`${basketIcon}#basket-icon`}></use>
                    </svg>
                </button>
                <button title="Переместить ресурс" className="button-move" onClick={() => props.moveEvent(modalData)}>
                    <svg width="16" height="12">
                        <use xlinkHref={`${transferIcon}#transfer-icon`}></use>
                    </svg>
                </button>
            </td>
        </tr>
    );
}

export default PoolElement;