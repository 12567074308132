import React from 'react';
import { observer } from "mobx-react-lite";
import MonitoringTableDesktop from "./MonitoringTableDesktop";
import {useWindowSize} from "../../../customHooks/useWindowSize";
import MonitoringTableMobile from "./MonitoringTableMobile";
import {connect} from "react-redux";
import CommonTableNav from "../../CommonTable/CommonTableNav/CommonTableNav";
import {useHistory} from "react-router-dom";

const MonitoringTable = observer(({tableModel, onDeletePrefix, compareMode, column, direction, setTableSize, inputValue, inputOnChange, buttonClick}) => {
    const size = useWindowSize();
    const history = useHistory();

    if(!tableModel.getCount()) return <span></span>;

    const table = size.width >= 1200 ?
        <MonitoringTableDesktop column={column}
                                direction={direction}
                                tableModel={tableModel}
                                onDeletePrefix={onDeletePrefix}
                                compareMode={compareMode}
                                style={{margin: "margin auto"}}/> :
        <MonitoringTableMobile column={column}
                               direction={direction}
                               tableModel={tableModel}
                               onDeletePrefix={onDeletePrefix}
                               compareMode={compareMode}
                               inputValue={inputValue}
                               inputOnChange={inputOnChange}
                               buttonClick={buttonClick}
        /> ;

    return <React.Fragment>
        {table}
        <CommonTableNav tableModel={tableModel}></CommonTableNav>
    </React.Fragment>;
});

export default  MonitoringTable;
