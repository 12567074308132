import axios from "axios";
import { API_URL } from "../../redux/constants";
import { toast } from "react-toastify";

export const create = async (data) => {
    const parsedData = JSON.stringify(data);

    const response = await axios.post(
        `${API_URL}/api/user/create/`,
        `user=${parsedData}`,
        {
            withCredentials: true,
        }
    );

    return response;
};

export const auth = async (user, password) => {
    const response = await axios.post(
        `${API_URL}/api/user/login/`,
        new URLSearchParams({
            user: user,
            password: password,
        }),
        {
            withCredentials: true,
        }
    );

    return response;
};

export const get = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/user/get/`, {
            withCredentials: true,
        });

        return response;
    } catch (e) {
        console.error(`Не удалось получить пользователя`);
    }
};

export const update = async (data) => {
    const parsedData = JSON.stringify(data);

    try {
        const response = await axios.post(
            `${API_URL}/api/user/update/`,
            `user=${parsedData}`,
            { withCredentials: true }
        );
        return response;
    } catch (error) {
        return toast.error(`${error}`);
    }
};

// На бэке происходит автоматическая отправка письма для верификации.
// Метод утратил свою надобность.
export const verifyEmail = (email) => {
    try {
        const promise = axios.post(
            `${API_URL}/api/user/verifymail/`,
            new URLSearchParams({
                user: email,
                request: 1,
            })
        );

        return promise;
    } catch (e) {
        console.error(e);
    }
};

export const confirmEmail = async (email, hash) => {
    try {
        return await axios.post(
            `${API_URL}/api/user/verifymail/`,
            new URLSearchParams({
                user: email,
                verification_code: hash,
            })
        );
    } catch (e) {
        console.error(e);
    }
};

export const unsubscribe = async (hash) => {
    try {
        return await axios.post(
            `${API_URL}/api/user/unsubscribe/`,
            new URLSearchParams({
                hash: hash,
            })
        );
    } catch (e) {
        console.error(e);
    }
};

export const recoveryPassword = async (email) => {
    try {
        return await axios.post(
            `${API_URL}/api/user/recovery/`,
            new URLSearchParams({
                user: email,
            }),
            {
                withCredentials: true,
            }
        );
    } catch (e) {
        toast.error("При восстановлении пароля возникла ошибка");
        console.error(e);
    }
};

export const resetPassword = async (hash, email, newpassword) => {
    try {
        return await axios.post(
            `${API_URL}/api/user/recovery/`,
            new URLSearchParams({
                hash,
                user: email,
                newpassword,
            }),
            {
                withCredentials: true,
            }
        );
    } catch (e) {
        toast.error("При восстановлении пароля возникла ошибка");
        console.error(e);
    }
};

export const changePassword = async (login, oldpassword, newpassword) => {
    try {
        const response = await axios.post(`${API_URL}/api/user/changepass/`,
            new URLSearchParams({
                user: login,
                oldpassword: oldpassword,
                newpassword: newpassword,
            }),
            {
                withCredentials: true,
            }
        );
        return response;
    } catch (e) {
        return toast.error(`${e}`);
    }
};

export const logout = async () => {
    try {
        const response = await axios.get(`${API_URL}/api/user/logout/`, {
            withCredentials: true,
        });
        return response;
    } catch (e) {
        return toast.error(`${e}`);
    }
};

export const remove = async () => {
    try {
        const response = await axios.post(`${API_URL}/api/user/delete/`, '', { withCredentials: true });

        return response.data;
    } catch (err) {
        throw new Error(err);
    }
};