// Компонент подтверждения письма

import React, {Fragment} from "react";
import '../../styles/checkmail.css';
import Routes from "../../helpers/Routes/Routes";
import {setJustRegisterAction} from "../../redux/actions/setJustRegisterAction";
import {connect} from "react-redux";

const CheckMail = (props) => {
    const formHandler = event => {
        event.preventDefault();
        window.location = Routes.first;
    }
    if (props.justRegister !== true){

        // return window.location = Routes.first;
    }

    return (
        <Fragment>
            <div className={'checkmail-submit'}>
                <span className={'checkmail-submit_text'}>
                    На ваш электронный адрес отправлено письмо. Пожалуйста, подтвердите регистрацию перейдя по ссылке в письме.
                </span>
                <form
                    action=""
                    className="checkmail-submit_form"
                    onSubmit={(event) => formHandler(event)}
                    noValidate
                >
                    <button className="button about__button__send">На главную</button>
                </form>
            </div>
        </Fragment>
    );

};
const mapStateToProps = (state) => {
    return {
        justRegister: state.justRegister,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setJustRegister: data => dispatch(setJustRegisterAction(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckMail);

