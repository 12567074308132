import React from "react";
import {observer} from "mobx-react-lite";
import * as PoolAPI from "../../../helpers/api/Pool";
import {Link, useHistory} from "react-router-dom";
import {connect} from "react-redux";
import {setMonitoringLastActive} from '../../../redux/actions/monitoringLastActive';
import Commentary from "../../Commentary/Commentary";
import {prefixEncode} from "../../../helpers/prefix";
import LocationIcon from "../../UI/LocationIcon";

const ColumnBodyMobile = observer(({ row, tableModel, getCompareClass, setMonitoringLastActive }) => {
    const history = useHistory();
    const getBlock = (row, section) => {
        const columns = {
            'min': row[section + '_min'],
            'mid': row[section + '_mid'],
            'max': row[section + '_max'],
        }
        return row.pending_update ? (
            <div className="MonitoringTableMobile__ColumnBody__Top__Block"
                 title="Мы ищем ваши префиксы в базе данных. Кликните для обновления"
                 onClick={() => {
                     window.location.reload(false);
                 }}
            >
                <div className='MonitoringTableMobile__ColumnBody__Top__Block__Text'>...</div>
                <div className='MonitoringTableMobile__ColumnBody__Top__Block__Text'>...</div>
                <div className='MonitoringTableMobile__ColumnBody__Top__Block__Text'>...</div>
            </div>
            )
                :
            (
            <div className={['MonitoringTableMobile__ColumnBody__Top__Block', getCompareClass(row, section, 'place')].join(' ')}>
                {/*<div className="MonitoringTableMobile__ColumnBody__Top__Block__Icon">*/}
                    {/*<img src={icon} alt={""}/>*/}
                    <LocationIcon type={section} />
                {/*</div>*/}
                <div className={"MonitoringTableMobile__ColumnBody__Top__Block__Text" + (columns.min ? '' : ' second-text ') + getCompareClass(row, section, 'min')}>
                    {columns.min ?? '-'}
                </div>
                <div className={"MonitoringTableMobile__ColumnBody__Top__Block__Text" + (columns.mid ? '' : ' second-text ') + getCompareClass(row, section, 'mid')}>
                    {columns.mid ?? '-'}
                </div>
                <div className={"MonitoringTableMobile__ColumnBody__Top__Block__Text" + (columns.max ? '' : ' second-text ') + getCompareClass(row, section, 'max')}>
                    {columns.max ?? '-'}
                </div>
            </div>
        )
    };

    return (
        <div className="MonitoringTableMobile__ColumnBody">
            <div className="MonitoringTableMobile__ColumnBody__Top">
                <b className="MonitoringTableMobile__ColumnBody__Top__Prefix">
                    {(row.pending_update) ? <span>{row['prefix']}</span> : (
                        <Link to={'/prefix/' + prefixEncode(row['prefix'])} onClick={() => {
                            setMonitoringLastActive(row['prefix']);
                        }}>
                            {row['prefix']}
                        </Link>)
                    }
                </b>
                <div className="MonitoringTableMobile__ColumnBody__Top__InputContainer">
                    <Commentary text={row['info']} onSave={(text) => {
                        tableModel.setRowValue(row["id"], "info", text);
                        PoolAPI.updateResource(localStorage.poolId, {
                            resource: row["prefix"],
                            type: "prefix",
                            comment: text
                        });
                    }}/>
                </div>
                <div className="MonitoringTableMobile__ColumnBody__Top__As" onClick={() => {
                    if (row.pending_update) return;
                    setMonitoringLastActive(row['prefix']);
                    history.push('/prefix/' + prefixEncode(row['prefix']));
                }}>
                    {row['as']}
                </div>
                <div className="MonitoringTableMobile__ColumnBody__Bottom">
                    <input type="checkbox" checked={tableModel.getVirtualProperty(row['id'])['remove']} onClick={(e) => {
                        tableModel.setVirtualProperty(row['id'], {remove: !tableModel.getVirtualProperty(row['id'])['remove']});
                    }}/>
                </div>
                <div className="MonitoringTableMobile__ColumnBody__BlocksContainer" onClick={() => {
                    if (row.pending_update) return;
                    setMonitoringLastActive(row['prefix']);
                    history.push('/prefix/' + prefixEncode(row['prefix']));
                }}>
                    {getBlock(row, 'global')}
                    {getBlock(row, 'region')}
                    {getBlock(row, 'local')}
                </div>
            </div>
        </div>
    );
});

const mapStateToProps = (state) => {
    return {
        monitoringLastActive: state.monitoringLastActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMonitoringLastActive: (prefixName) => dispatch(setMonitoringLastActive(prefixName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ColumnBodyMobile);
