// Компонент заглушки на роуты

import React from "react";
import { useHistory } from "react-router-dom";
import { getNoun } from "../../helpers/Utils";
import Routes from "../../helpers/Routes/Routes";
import alertIcon from "../../img/alert-icon.svg";

const SomethingWentWrong = () => {
    const history = useHistory();
    const TIMEOUT = 3000;
    const zeroRegEX = /0+/;

    setTimeout(() => {
        history.push(Routes.first);
    }, TIMEOUT);

    return (
        <div className="SomethingWentWrong">
            <img src={alertIcon} alt="" className="SomethingWentWrongImg" />
            <p className="SomethingWentWrongText">
                Что-то пошло не так... <br />
                Сожалеем об этом. Скоро мы все поправим! <br /> <br />
                Вы будете перенаправлены на главную страницу через{" "}
                {TIMEOUT.toString().replace(zeroRegEX, "")} секунд
                {getNoun(
                    Number(TIMEOUT.toString().replace(zeroRegEX, "")),
                    "у",
                    "ы",
                    ""
                )}
                .
            </p>
        </div>
    );
};

export default SomethingWentWrong;
