import React, {Fragment} from "react";
import DatePickerCustom from "./DatePicker";

export const H1 = (props) => {

	if (props.smallSize) {
		return (
				<h1 className="prefix-h1">
					<div className="prefix-h1--title">
						{props.text}
					</div>
						{props.user.tmpMode === false && <DatePickerCustom
						includeDates={props.reportDates}
						startDate={props.startDate}
						/> }
				</h1>
				)
	} else {
		return (
				<Fragment>
					<h1 className="prefix-h1-desktop prefix">
						<span className="prefix-h1-desktop--title">{props.text}</span>
							{props.user.tmpMode === false && <DatePickerCustom
								includeDates={props.reportDates}
								startDate={props.startDate}
							/>}
					</h1>
				</Fragment>
				)
	}
}

export default H1;
