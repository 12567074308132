import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers/reducer';

const withDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, withDevTools(
    applyMiddleware(thunk)
));

store.subscribe(() => {
    localStorage['checked'] = JSON.stringify(store.getState()['checked']);
    localStorage['compareDate'] = JSON.stringify(store.getState()['compareDate']);
});

export default store;