// Страница подтверждения e-mail

import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useHistory } from 'react-router-dom';
import Routes from "../../helpers/Routes/Routes";
import logoAuth from '../../img/logo.svg';
import * as UserAPI from '../../helpers/api/User';
import SomethingWentWrong from "../SomethingWentWrong/SomethingWentWrong";
import Headers from "../../helpers/Headers";

const Unsubscribe = () => {
    const history = useHistory();
    const [render, setRender] = useState(false);
    const url = new URL(document.location);
    const qsParams = {
        hash: url.searchParams.get('hash')
    };

    useEffect(() => {
        document.title = Headers.emailVerification;

        UserAPI.unsubscribe(qsParams.hash)
            .then(response => {
                const { status } = response.data;

                if (status === "error") {
                    setRender(true);
                }
            })
    }, [history, qsParams.hash]);

    return (
        <Fragment>
            {render && <SomethingWentWrong />}
            {!render &&  <section className="start-page">
            <div className="start-page__logo-container">
                <img
                    src={logoAuth}
                    alt="DasPath"
                    className="logo start-page__logo"
                />
            </div>
            <p className="start-page__text">
                <br/>
                Спасибо! Вы успешно отписались от рассылки.
            </p>
            <NavLink className="link start-page__registration" to={Routes.first}>Перейти на главную страницу</NavLink>
        </section>}
        </Fragment>
    );
};

export default Unsubscribe;
