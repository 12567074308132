import AbstractModel from "./AbstractModel";
import { makeObservable, observable, action } from "mobx";
import monitoringSortPrefix from "../../Monitoring/MonitoringTable/monitoringSortPrefix";

class ArrayModel extends AbstractModel {
    constructor(array, idColumn) {
        super();
        this.elements = array;
        this.idColumn = idColumn ?? 0;
        this.lengthCache = 0;

        makeObservable(this, {
            elements: observable,
            idColumn: observable,
            removeMarked: action,
            setData: action
        });
    }

    setData(elements) {
        this.elements = elements;
        this.lengthCache = elements.length;
        this.virtualProperties = [];
    }

    getCount() {
        return this.elements.length;
    }

    getRows(startIndex, count) {
        const data = this.getSortBy() === null ?
            this.elements :
            this.elements
                .slice(0, this.elements.length)
                .sort((a, b) => {
                    a = a[this.sort.by] ?? 0;
                    b = b[this.sort.by] ?? 0;

                    if(typeof a === "string" && a.startsWith("AS")) {
                        a = Number.parseInt(a.substr(2)) ?? a;
                    }

                    if(typeof b === "string" && b.startsWith("AS")) {
                        b = Number.parseInt(b.substr(2)) ?? b;
                    }

                    if (this.getSortBy() !== 'prefix') {
                        switch (this.getSortOrder()) {
                            case "asc":
                                if (typeof a === "string") {
                                    return a.localeCompare(b);
                                } else {
                                    return a - b;
                                }
                            case "desc":
                                if (typeof b === "string") {
                                    return b.localeCompare(a);
                                } else {
                                    return b - a;
                                }
							default:
								break
                        }
                    } else {
                        return monitoringSortPrefix(a,b,this.getSortOrder());
                    }
					
					return null;
                });

        return data.filter((v, i) => i >= startIndex && i <= startIndex + count);
    }

    setRowValue(id, column, value) {
        let searchResult = this.elements
            .filter((row) => row[this.idColumn] === id);
        if (searchResult.length) searchResult[0][column] = value;
    }

    removeRow(id) {
        this.elements = this.elements.filter((v, i) => v[this.idColumn] !== id);
        super.removeRow(id);
    }

    removeMarked() {
        this.elements = this.elements.filter(e => !this.getVirtualProperty(e[this.idColumn])['remove']);
    }
}

export default ArrayModel;
