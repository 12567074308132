import React from 'react';
import { observer } from "mobx-react-lite";
import CommonTable from "../CommonTable/CommonTable";

const isDisabled = (tableModel, row) => {
    return tableModel.getVirtualProperty(row['id'])['readonly'];
}

const isFullTable = (tableModel) => tableModel.elements.filter(e => tableModel.getVirtualProperty(e['id'])['as']).length;

const MultipleSelectPrefixesCheckBox = observer(({ tableModel, row }) => {
    if(isDisabled(tableModel, row)) {
        return <input className="MonitoringCheckbox disabled" type="checkbox" disabled={true}/>
    } else {
        return <input className="MonitoringCheckbox" type="checkbox"
                      checked={tableModel.getVirtualProperty(row['id'])['checked']} onChange={(e) => {
            tableModel.setVirtualProperty(row['id'], {checked: !tableModel.getVirtualProperty(row['id'])['checked']});
        }}/>
    }
});

const MultipleSelectPrefixesTable = observer(({ tableModel }) => {
    return (
        <React.Fragment>
            <div className="Modal__AddPrefix__CheckBoxLine">
                <input className="MonitoringCheckbox" type="checkbox" onChange={(e) => {
                    const val = e.target.checked;
                    for(const row of tableModel.elements) {
                        if(!tableModel.getVirtualProperty(row['id'])['readonly']) {
                            tableModel.setVirtualProperty(row['id'], {
                                checked: val
                            });
                        }
                    }
                }}/>
                <div className="Modal__AddPrefix__CheckBoxLine__Text">
                    Выбрать все префиксы
                </div>
            </div>
            <CommonTable
                className="MultipleSelectPrefixesTable"
                tableModel={tableModel}
                headerRenderers={[
                    ({tableModel}) => (
                        <div className="MultipleSelectPrefixesTable__Header__Space"/>
                    ),
                    ({tableModel}) => (
                        <b className="MultipleSelectPrefixesTable__Header__Title">
                            Префикс
                        </b>
                    ),
                    ...(isFullTable(tableModel) ? [
                        ({tableModel}) => (
                            <b className="MultipleSelectPrefixesTable__Header__Title">
                                AS
                            </b>
                        ),
                        ({tableModel}) => (
                            <b className="MultipleSelectPrefixesTable__Header__Title">
                                Комментарий
                            </b>
                        ),
                    ] : [])
                ]}
                columnRenderers={[
                    ({tableModel, row}) => (
                        <MultipleSelectPrefixesCheckBox tableModel={tableModel} row={row}/>
                    ),
                    ({tableModel, row}) => (
                        <div className="MultipleSelectPrefixesTable__Column__Prefix">
                            <div className="MultipleSelectPrefixesTable__Column__Prefix__Name">
                                {row["prefix"]}
                            </div>
                        </div>
                    ),
                    ...(isFullTable(tableModel) ? [
                        ({tableModel, row}) => (
                            <div className="MultipleSelectPrefixesTable__Column__Prefix">
                                <div className="MultipleSelectPrefixesTable__Column__Prefix__Name">
                                    {tableModel.getVirtualProperty(row['id'])['as'] ?? ''}
                                </div>
                            </div>
                        ),
                        ({tableModel, row}) => (
                            <div className="MultipleSelectPrefixesTable__Column__Prefix">
                                <div className="MultipleSelectPrefixesTable__Column__Prefix__Name">
                                    {tableModel.getVirtualProperty(row['id'])['info'] ?? ''}
                                </div>
                            </div>
                        )
                    ] : [])
                ]}
            />
        </React.Fragment>
    );
});

export default MultipleSelectPrefixesTable;