import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment'
import 'moment/locale/ru';

const getNumberOfMonths = () => {
    const currentWidth = window.innerWidth;

    switch (true) {
        case currentWidth < 755:
            return 1;
        case currentWidth < 992:
            return 1;
        default:
            return 1;
    }
}

export default {
    lang: 'ru',
    localeUtils: MomentLocaleUtils,
    months: getNumberOfMonths(),
    format: 'DD/MM/yyyy',
    formatDate,
    parseDate,
};
