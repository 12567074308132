import React from "react";

const CrossIcon = (props) => {

	return (
			<svg style={props.style} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="20" cy="20" r="19.5" stroke="#1A75FF" strokeLinejoin="round"/>
				<path d="M27 21H21V27H19V21H13V19H19V13H21V19H27V21Z" fill="#1A75FF"/>
			</svg>
			);
}

export default CrossIcon;
