// Компоненнт ListBox

import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import * as ReportAPI from '../../helpers/api/Report';
import Loader from '../Loader/Loader';

const MultipleSelect = (props) => {
    const { modal } = props;
    const [state, setState] = useState({
        allReports: [],
    });

    useEffect(() => {
        (
            async () => {
                if (modal.active) {
                    const { data } = await getReports();
                    setState({
                        ...state,
                        allReports: data,
                    });
                }
            }
        )()
    }, [modal.active, state])

    const getReports = async () => {
        try {
            const response = await ReportAPI.getList();

            if (response.status === "error") {
                toast.error('Не удалось получить список отчетов.');
                return;
            }

            return response;
        } catch (error) {
            toast.error('Не удалось получить список отчетов.');
        }
    }

    const setReportAndPoolId = (report, pool) => {
        const prevReport = document.querySelector('.list-box__option--active');

        prevReport && prevReport.classList.remove('list-box__option--active');

        localStorage.setItem('reportId', report);
        localStorage.setItem('poolId', pool);
    }

    return (
        <Fragment>
            {state.allReports.length && (
                <select className="list-box" size="5">
                    {state.allReports.map(el => {
                        return (
                            <option key={el.report_id}
                                    className={`list-box__option ${el.report_id === +localStorage.getItem('reportId') && 'list-box__option--active' || ''}`}
                                    onClick={() => setReportAndPoolId(el.report_id, el.pool_id)}
                                    value={el.report_id}
                                    >{el.name}</option>
                        )
                    })}
                </select>
            ) || <Loader class="Loader" />}
        </Fragment>
    );
}

const mapStateToProps = state => {
    return {
        modal: state.modal,
    };
};

export default connect(mapStateToProps, null)(MultipleSelect);