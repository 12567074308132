// Страница "Настройки пользователя"

import React, { Fragment, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import * as UserAPI from "../../helpers/api/User";
import * as userActions from "../../redux/actions/userActions";
import * as modalActions from "../../redux/actions/modalActions";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Routes from "../../helpers/Routes/Routes";
import { parseDate } from "../../helpers/Utils";
import Headers from "../../helpers/Headers";
import Input from "../UI/Input/Input";
import ProfileFreq from "./ProfileFreq";
import Loader from "../Loader/Loader";
import ProfileFreqType from "./ProfileFreqType";
import H1 from "../UI/H1";

const Profile = (props) => {
    const selectOptions = [
        { value: "0", label: "Через каждые n дней" },
        { value: "1", label: "Каждый n-й день месяца" },
        { value: "2", label: "Каждый выбранный день недели" },
    ];
    const { profileSettings, getUser, showModal } = props;
    const [form, setForm] = useState({
        id: profileSettings.userid,
        login: profileSettings.login,
        name: profileSettings.name,
        reports_freq: profileSettings.reports_freq,
        reports_transport: profileSettings.reports_transport,
        telegram: profileSettings.telegram,
        select: "0",
        input: "",
    });
    console.log({profileSettings});
    const [controlsState, setControlsState] = useState({});
    const [selectedOption, setSelectedOption] = useState(selectOptions[0]);
    const dateFormatted = parseDate(profileSettings.last_login);

    if (!profileSettings.isLogin) {
        document.location.href = Routes.auth;
    }

    useEffect(() => {
        document.title = Headers.userSettings;
    }, [])

    useEffect(() => {
        const {
            userid,
            login,
            name,
            reports_freq,
            reports_transport,
            telegram,
        } = profileSettings;

        setForm({
            id: userid,
            login,
            name,
            reports_freq: '2_0011000',
            reports_transport,
            telegram,
            selectValue: reports_freq && reports_freq.split("_")[0],
            inputValue: reports_freq && reports_freq.split("_")[1],
        });

        setSelectedOption({
            value: reports_freq && reports_freq.split("_")[0],
            label:
                reports_freq &&
                selectOptions[+reports_freq.split("_")[0]].label,
        });

        setControlsState({
            name: {
                controlType: "input",
                    controlConfig: {
                    type: "text",
                },
                title: "Имя",
                value: profileSettings.name,
                error: ""
            },
            email: {
                controlType: "input",
                    controlConfig: {
                    type: "email",
                },
                title: "E-mail",
                value: profileSettings.login,
                error: ""
            },
            password: {
                controlType: "input",
                controlConfig: {
                    type: "password",
                    readOnly: true
                },
                title: "Пароль",
                value: '********',
                error: "",
                after: <NavLink className="link" to={{
                    pathname: Routes.changePasswordFromProfile,
                    state: {
                        email: profileSettings.login,
                    },
                }}>
                    Сменить пароль
                </NavLink>
            },
            telegram: {
                controlType: "input",
                controlConfig: {
                    type: "text",
                    placeholder: "Например, @ivan_petrov",
                },
                title: "Телеграм",
                value: profileSettings.telegram ?? '',
                error: ""
            }
        });
    }, [profileSettings]);

    useEffect(() => {
        const { reports_freq } = profileSettings;
        let daysValue = [];

        if (selectedOption.value === '2') {
            if (reports_freq.split('_')[1].length > 2) {
                daysValue = reports_freq.split('_')[1].split('');
            }

            setForm({
                ...form,
                inputValue: daysValue.join('')
            })
        };
    }, [selectedOption.value])

    const updateProfile = async () => {
        const {
            id,
            reports_freq,
            reports_transport,
        } = form;
        const name = controlsState.name.value;
        const login = controlsState.email.value;
        const telegram = controlsState.telegram.value;
        const data = {
            id,
            login,
            name,
            reports_freq,
            reports_transport,
            telegram,
        };
        const response = await UserAPI.update(data);
        const { status, reason } = response.data;

        if (status === "error") {
            return toast.error(`${reason.msg}`);
        }

        toast.success("Личные данные обновлены.");
        getUser();
    };

    const updateReportFrequency = async () => {
        const data = {
            reports_freq: `${selectedOption.value}_${form.inputValue}`,
        };
        const response = await UserAPI.update(data);
        const { status, reason } = response.data;

        if (status === "error") return toast.error(`${reason.msg}`);

        toast.success("Рассылка отчета обновлена.");
        getUser();
    };

    const restoreChanges = () => {
        const { inputValue, selectValue } = form;
        const {
            userid,
            login,
            name,
            reports_freq,
            reports_transport,
            telegram,
        } = profileSettings;
        setForm({
            id: userid,
            login,
            name,
            reports_freq,
            inputValue,
            selectValue,
            reports_transport,
            telegram,
        });
    };

    const formInputHandler = (event, controlName) => {
        const updatedControls = {
            ...controlsState,
            [controlName]: {
                ...controlsState[controlName],
                value: event.target.value,
                error: controlsState[controlName].value && '',
            },
        };
        setControlsState(updatedControls);
        sessionStorage.setItem('userEmail', controlsState.email.value);
    };

    const renderControls = () => {
        let formElementsArray = [];

        for (let type in controlsState) {
            formElementsArray.push({
                id: type,
                config: controlsState[type],
            });
        }

        return formElementsArray.map((formElement) => (
            <Input
                key={formElement.id}
                elementType={formElement.config.controlType}
                elementConfig={formElement.config.controlConfig}
                elementTitle={formElement.config.title}
                elementError={formElement.config.error}
                recovery={formElement.config.recovery}
                value={formElement.config.value}
                after={formElement.config.after}
                changed={(event) => {
                    formInputHandler(event, formElement.id);
                }}
            />
        ));
    };

    const deleteUserData = {
        type: 'deleteUser',
        resource: {
            user: profileSettings.login,
        },
        callBack: [() => UserAPI.remove(), () => getUser()], 
    };

    const controls = renderControls();

    return (
        <Fragment>
            <H1
                text="Настройки профиля"
                smallSize={props.hook}
                user={{}}
            />
            <section className="profile">
                <h2 className="secondary-title">Личные данные</h2>

                {controls}

                <span className="profile__date">
                    Дата последнего входа: {dateFormatted}
                </span>

                <p/>

                <button className="button button--apply profile--button" onClick={() => updateProfile()}>
                    Применить
                </button>


                <h2 className="secondary-title profile--secondary-title">Настройки отчета</h2>
                {form.inputValue ?
                    <ProfileFreq selectedDays={form.inputValue.split("")} getUser={getUser}/>
                :   <Loader/>}
                {form.reports_transport !== undefined ?
                    <ProfileFreqType userTransport={form.reports_transport} getUser={getUser}/>
                : <Loader/>
                }
            </section>
            <button className="button button--red profile__button--delete-user" onClick={() => showModal(deleteUserData)}>Удалить пользователя</button>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        profileSettings: state.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(userActions.getUser()),
        showModal: (data) => dispatch(modalActions.showModal(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
