import {toast} from "react-toastify";

// нахождение среднего значения путей
export const matrixAvg = (a) => {
    let resultArray = [];

    if (a.length) {
        let count = a.length;

        a.forEach((path, primaryKey) => {
            path.forEach((value, key) => {
                if (primaryKey === 0) {
                    resultArray[key] = value;
                } else {
                    resultArray[key] += value;
                }
            })
        })

        resultArray.forEach((value, key) => {
            resultArray[key] = Number((value / count).toFixed(2));
        })
    }

    return resultArray;
}

// транспортирует матрицу
function transpose(matrix) {
    return matrix[0].map((col, i) => matrix.map(row => row[i]));
}

// транспортирует матрицу точек и находит минимальные значения путей
export const matrixMin = (a) => {
    let resultArray = [];

    if (a.length) {
        transpose(a).forEach(arPath => {
            resultArray.push(Math.min.apply(null, arPath));
        })
    }

    return resultArray;
}

// транспортирует матрицу точек и находит максимальные значения путей
export const matrixMax = (a) => {
    let resultArray = [];

    if (a.length) {
        transpose(a).forEach(arPath => {
            resultArray.push(Math.max.apply(null, arPath));
        })
    }

    return resultArray;
}

// текущая дата в формате dd.mm.yyyy
export const formatDate = (d = new Date) => {
    return [d.getDate(), d.getMonth() + 1, d.getFullYear()]
        .map(n => n < 10 ? `0${n}` : `${n}`).join('.');
}

// Парсинг даты в понятный формат
export const parseDate = (date) => {
    if (!date) return;
    const d = new Date(date);
    return d.toLocaleDateString();
};

// Склонение слов
export const getNoun = (a, o, t, f) => {
    let n = Math.abs(a);

    n %= 100;

    if (n >= 5 && n <= 20) {
        return f;
    }

    n %= 10;

    if (n === 1) {
        return o;
    }

    if (n >= 2 && n <= 4) {
        return t;
    }

    return f;
};

export const groupByForArray = (arr, groupValue) =>
    arr.reduce((acc, item) => {
        const field = item[groupValue];

        if (acc[field]) {
            acc[field].push(item);
        } else {
            acc[field] = [item];
        }

        return acc;
    }, {});

export const checkResponse = (result, showMessage = true) => {
    if (!result || result.status === "error") {

        if (result && result.reason.msg && showMessage)
            toast.error(`${result.reason.msg}`);

        return false;
    } else {
        return true;
    }
}

export const getResourceType = (resource) => {
    const ip = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}$/,
        ipv4 = /^[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}(\/\d*)?$/,
        //ipv6 = /^(([a-f0-9:]+:+)+[a-f0-9]+)(\/\d*)?$/,
        ipv6 = /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/,
        asn = /^as[0-9]{1,6}$/i,
        domain = /^(https?:\/\/)?(?!-)(?:[a-zA-Z\d\-]{0,62}[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63}(\/)?$/;

    if (ip.test(resource))
        return "network";
    else if (ipv4.test(resource))
        return "network";
    else if (ipv6.test(resource))
        return "network";
    else if (asn.test(resource))
        return "asn";
    else if (domain.test(resource))
        return "domain"
    else
        return false;
}

export const sortObject = (obj) => {
    return Object.keys(obj).sort().reduce((result, key) => {
        result[key] = obj[key];
        return result;
    }, {});
}

Object.filter = function (obj, predicate) {
    let result = {}, key;

    for (key in obj) {
        if (obj.hasOwnProperty(key) && !predicate(obj[key])) {
            result[key] = obj[key];
        }
    }

    return result;
};
