const parsePrefixData =  (data, prefix, views) => {
	var result = [];
	let id = 0;
	Object.keys(data[prefix.name]).forEach((item, index) => {
		const type = item === 'ix' ? 'local' : item;
		Object.keys(data[prefix.name][item]).forEach((item1, index1) => {
			const as = item1;
			Object.keys(data[prefix.name][item][as]).forEach((item2, index2) => {
				const pr = (item2);
				Object.keys(data[prefix.name][item][as][pr]).forEach((item3, index3) => {
					const as_path = data[prefix.name][item][as][pr][item3]['as-path'];
					result.push({
						id: id++,
						type: type,
						as: as,
						as_path: parseAs_path(as_path),
						as_path_new: '',
						as_length_change: "",
						as_length: getLength(as_path),
						title: getAsName(as, views),
					});
				});
			});
		})
	})

	return result;
}

const parseAs_path = (as_path) =>{
	const path = as_path.split(' ');
	const result = [];
	const map = new Map();
	for (const item of path) {
		map.set(item, map.has(item) ? map.get(item) + 1 : 1)
	}
	for (const key of map){
		if (key[1] > 1) {
			result.push(key[0] +'*'+ key[1]);
		} else {
			result.push(key[0]);
		}
	}

	return result.reverse();
}

const getLength = (as_path) => {
	let length = 0;
	as_path.split(' ').forEach((item) => {
		const count = item.split('*')[1];
		length = count ? length + Number(count) : length + 1;
	});

	return length;
}

const getAsName = (as, views) =>{
	for(const item of views) {
		if (item.view === as){

			return item.name;
		}
	}
}
export {parsePrefixData, parseAs_path, getLength, getAsName};
