// Общий компонент фильтра

import React from "react";
import FilterContainer from "./FilterContainer/FilterContainer";
import {connect} from "react-redux";
import ClickOutside from "../../hoc/ClickOutside";

class Filter extends React.Component {
    constructor(props) {
        super(props)

        this.body = document.body;
        this.closeFilter = this.closeFilter.bind(this);
        this.changeAllExpandersState = this.changeAllExpandersState.bind(this);
    }

    state = {
        allResourcesChecker: true,
        allViewPointsChecker: true,
    }

    componentDidMount() {
        this.setState({
            allResourcesChecker: localStorage.getItem('allResourcesChecker') ? JSON.parse(localStorage.getItem('allResourcesChecker')) : true,
            allViewPointsChecker: localStorage.getItem('allViewPointsChecker') ? JSON.parse(localStorage.getItem('allViewPointsChecker')) : true,
        });
    }

    changeAllExpandersState(type, entry) {
        this.setState({
            [type]: entry,
        });

        localStorage.setItem(type, entry);
    }  

    closeFilter() {
        const [ button, group ] = this.props.filterRefs;
        const filter = document.querySelector('.filter');

        button.classList.remove('filter-expander--active');
        group.classList.remove('group--expanded');

        if (window.innerWidth <= 640) {
            filter.classList.remove('filter--translated');
            return;
        }
    }

    render() {
        return (
            <ClickOutside handler={this.closeFilter}>
                <aside className="filter">
                <button onClick={this.closeFilter} className={`filter-expander filter-expander--mobile`}></button>
                    {!this.props.filterData.reportIsEmpty && (
                        <div className="filter-wrapper">
                            <FilterContainer name="Ресурсы" type="resources" filterData={this.props.filterData} data={this.props.filterData} allResourcesChecker={this.state.allResourcesChecker} changeAllExpandersState={this.changeAllExpandersState} />
                            <FilterContainer name="Точки наблюдения" type="viewPoints" filterData={this.props.filterData} data={this.props.filterData.viewPoints} allViewPointsChecker={this.state.allViewPointsChecker} changeAllExpandersState={this.changeAllExpandersState} />
                        </div>
                    )}
                </aside>
            </ClickOutside>
        );
    }
}

const mapStateToProps = state => {
    return {
        filterData: state.filter
    };
};

export default connect(mapStateToProps, null)(Filter);