import React, { Fragment } from "react";
import PrefixSortColumn from "./PrefixSortColumn";

export const PrefixSort = props => {
	const {sortableColumn, changeSortableColumn, changeDirection, direction, showSortPage, showSortPageAction, showChange} = props;

	return (
			<Fragment>
				<div className={"prefix__sort_desktop__column"}>
					<PrefixSortColumn
						text="Тип"
						text_comment="Глобальная, российская точка наблюдения или точка обмена трафиком"
						type="type"
						width={55}
					/>
				</div>
				<div className={"prefix__sort_desktop__column"}>
					<PrefixSortColumn
						text="Точка наблюдения"
						text_comment="Наименование точки наблюдения"
						type="title"
						width={190}
						/>
					<PrefixSortColumn
						text="Длина"
						text_comment="Длина пути от точки наблюдения до префикса"
						type="as_length"
						width={85}
					/>
					{/*props.showChange ?
					<PrefixSortColumn
						text="&#177;"
						type="as_length_change"
						width={30}
					/> : null */}
					<PrefixSortColumn
						text="AS"
						text_comment="Автономная система точки наблюдения (или город)"
						type="as"
						width={65}
					/>
					<span className={'as-path--wrapper'}>
						<PrefixSortColumn
							text="AS_PATH"
							text_comment="Путь от точки наблюдения до префикса"
							type="as_path"
							align={'center'}
						/>
					</span>
				</div>
			</Fragment>
	)

}



export default PrefixSort;
