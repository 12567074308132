//import {globalSVGIcon, localSVGIcon, regionSVGIcon} from "./Icons";

export const map = [
	{
		title: "Глобальная",
		column: "global",
		comment: "Минимальный, средний и максимальный путь до глобальных точек наблюдения",
		comment_min: "Минимальный путь до глобальных точек наблюдения",
		comment_avr: "Cредняя длина пути до глобальных точек наблюдения",
		comment_max: "Максимальный путь до глобальных точек наблюдения",
	}, {
		title: "Региональная",
		column: "region",
		comment: "Минимальный, средний и максимальный путь до российских точек наблюдения",
		comment_min: "Минимальный путь до российских точек наблюдения",
		comment_avr: "Cредняя длина пути до российских точек наблюдения",
		comment_max: "Максимальный путь до российских точек наблюдения",
	}, {
		title: "Локальная",
		column: "local",
		comment: "Минимальный, средний и максимальный путь до точек обмена трафиком",
		comment_min: "Минимальный путь до точек обмена трафиком",
		comment_avr: "Cредняя длина пути до точек обмена трафиком",
		comment_max: "Максимальный путь до точек обмена трафиком",
	}
];

export const header = [
	["prefix", "Префикс"],
	["info", "Инфо"],
	["as", 'AS']
];

