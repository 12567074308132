import React from "react";
export const UndefinedIcon = (props) => {
	return (
			<svg style={props.style} width = "10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 7L5 12L10 7H0Z" fill="#ABB0BA"/>
				<path d="M0 5L5 0L10 5H0Z" fill="#ABB0BA"/>
			</svg>
			);
};

export const DescIcon = (props) => {
	return (
			<svg style={props.style} width = "10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 7L5 12L10 7H0Z" fill="#ABB0BA"/>
				<path d="M0 5L5 0L10 5H0Z" fill="#1A75FF"/>
			</svg>
			);
}


export const AscIcon = (props) => {
	return (
			<svg style={props.style} width = "10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M0 5L5 0L10 5H0Z" fill="#ABB0BA"/>
				<path d="M0 7L5 12L10 7H0Z" fill="#1A75FF"/>
			</svg>
			);
}

export default {AscIcon, DescIcon, UndefinedIcon};


