export default {
    getStarted: 'Начало работы',
    report: 'Отчет',
    reportForNotLogin: 'Отчет связности ресурса',
    history: 'История',
    pool: 'Пул ресурсов',
    viewPoints: 'Точки наблюдения',
    about: 'О проекте',
    auth: 'Авторизация',
    registration: 'Регистрация',
    passwordRecovery: 'Восстановление пароля',
    userSettings: 'Настройки профиля',
    emailVerification: 'Подтверждение почтового адреса',
};