import axios from "axios";

class AjaxCache {
    constructor() {
        this.storage = {};
        this.waitStorage = {};
    }

    get(url, config) {
        const key = JSON.stringify({method: 'get', url, config});

        if(key in this.storage) return Promise.resolve(this.storage[key]);
        else if(key in this.waitStorage) return this.waitStorage[key];

        return this.waitStorage[key] = new Promise((resolve, reject) => {
            axios.get(url, config)
                .then((response) => {
                    this.storage[key] = response;
                    resolve(response);
                    delete this.waitStorage[key];
                }).catch( reject => console.log());
        });
    }

    post(url, params, config) {
        if(params instanceof URLSearchParams) {
            params = params.toString();
        }
        const key = JSON.stringify({method: 'post', url, params, config});

        if(key in this.storage) return Promise.resolve(this.storage[key]);
        else if(key in this.waitStorage) return this.waitStorage[key];

        return this.waitStorage[key] = new Promise((resolve, reject) => {
            axios.post(url, params, config)
                .then((response) => {
                    this.storage[key] = response;
                    resolve(response);
                    delete this.waitStorage[key];
                }).catch( reject => console.log());
        });
    }

    refresh(urlPart) {
        if(urlPart) {
            for(const key of Object.keys(this.storage)) {
                const url = JSON.parse(key)['url'];
                if(String(url).includes(urlPart)) {
                    delete this.storage[key];
                }
            }
        } else {
            this.storage = [];
        }
    }
}

const ajaxCache = new AjaxCache();
export default ajaxCache;