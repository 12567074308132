import {SET_COMPARE_DATE} from '../constants';

export default (state = {}, action) => {
    const { type, payload } = action;
    if (type === SET_COMPARE_DATE) {
        return payload;
    }

    if(JSON.stringify(state) === '{}') {
        if(localStorage["compareDate"]) {
            try {
                return JSON.parse(localStorage["compareDate"]);
            } catch (e) {
                return state;
            }
        }
    }
	
	return state;
};