import React, { Fragment, useEffect, useRef } from "react";
import Header from "./components/Header/Header";
import {Switch, Route, Redirect, useLocation, withRouter} from "react-router-dom";
import Filter from "./components/Filter/Filter";
import About from "./components/About/About";
import Report from "./components/Report/Report";
import Footer from "./components/Footer/Footer";
import History from "./components/History/History";
import Recovery from "./components/Recovery/Recovery";
import Monitoring from "./components/Monitoring/Monitoring";
import ChangePass from "./components/ChangePass/ChangePass";
import ViewPoints from "./components/ViewPoints/ViewPoints";
import CheckMail from "./components/CheckMail/CheckMail";
import { connect } from "react-redux";
import * as userActions from "./redux/actions/userActions";
import * as ipvActions from "./redux/actions/ipvActions";
import * as asnActions from "./redux/actions/asnActions";
import SignUp from "./components/SignUp/SignUp";
import Auth from "./components/Auth/Auth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Profile from "./components/Profile/Profile";
import Pool from "./components/Pool/Pool";
import Alert from './components/Alert/Alert';
import Routes from './helpers/Routes/Routes';
import ChangePasswordFromProfile from "./components/ChangePasswordFromProfile/ChangePasswordFromProfile";
import ConfirmEmail from "./components/ConfirmEmail/ConfirmEmail";
import Unsubscribe from "./components/Unsubscribe/Unsubscribe";
import Modal from "./components/Modal/Modal";
import { getASN } from "./helpers/api/Misc";
import PrefixAuth from "./components/Prefix/PrefixAuth";
import {isSmallScreen} from "./components/UI/ui-helper";

function App(props) {
    const { getUser, getIPV, receiveASN, user, modal, sortableColumn, direction } = props;
    const location = useLocation();
    const classes = [];
    const showFilterButton = useRef(null);
    const groupSection = useRef(null);
    const smallHook = isSmallScreen();

    useEffect(() => {
        getUser();
        getIPV();
        getASN().then(asn => receiveASN(asn));
    }, [getUser, getIPV, receiveASN])

    const toggleFilter = () => {
        const filter = document.querySelector('.filter');

        if (window.innerWidth <= 640) {
            filter.classList.contains('filter--translated')
                ? filter.classList.remove('filter--translated')
                : filter.classList.add('filter--translated');
        }

        showFilterButton.current.classList.contains('filter-expander--active')
            ? showFilterButton.current.classList.remove('filter-expander--active')
            : showFilterButton.current.classList.add('filter-expander--active');
        groupSection.current.classList.contains('group--expanded')
            ? groupSection.current.classList.remove('group--expanded')
            : groupSection.current.classList.add('group--expanded');
    }

    if (location.pathname.includes(Routes.report || Routes.history))
        classes.push("with-filter", "group", "");

    if (location.pathname.includes(Routes.about))
        classes.push("", "group", "group--about");

    if (location.pathname.includes('/prefix/'))
        classes.push("", "group", "group--prefix");

    if (location.pathname.includes(Routes.monitoring) || location.pathname.includes(Routes.first))
        classes.push("group--monitoring");

    return (
        <Fragment>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                pauseOnHover={false}
                rtl={false}
                limit={3}
            />
            <Route path={Object.values(Routes)} render={() => <Header />} />
            <Alert />
            <section className={classes[0]}>
                <Route path={[Routes.report, Routes.history]} render={() => <Filter ipv={props.ipv} filterRefs={[showFilterButton.current, groupSection.current]} />} />
                <section className={`${classes[1]} ${classes[2]}`} ref={groupSection}>
                    <button ref={showFilterButton} onClick={toggleFilter} className={`filter-expander ${location.pathname.includes(Routes.report || Routes.history) && 'filter-expander--visible' || ""}`}></button>
                    <main>
                        <div className="container">
                            <Switch>
                                <Route exact path={Routes.first} component={Monitoring} />
                                {(user && user.tmpMode === false) ? ( <Redirect from={Routes.auth} to={Routes.first} /> ) : ( <Route path={Routes.auth} component={Auth} /> )}
                                <Route path={Routes.monitoring} render={() => <Monitoring hook={smallHook} user={user} ipv={props.ipv.Report}/>} />
								<Route path={Routes.prefix} render={() => <PrefixAuth hook={smallHook} sortableColumn={sortableColumn} direction={direction}/>} />
                                <Route path={Routes.reportToDate} render={() => <Report ipv={props.ipv.Report} isCompare={true} />} />
                                <Route path={Routes.report} render={() => <Report ipv={props.ipv.Report} />} />
                                <Route path={Routes.history} render={() => <History ipv={props.ipv.History} />} />
                                <Route path={Routes.viewpoints} render={() => <ViewPoints hook={smallHook} />} />
                                <Route path={Routes.about} component={About} />
                                <Route path={Routes.resource} component={Pool} />
                                <Route path={Routes.registration} component={SignUp} />
                                <Route path={Routes.changePasswordFromProfile} component={ChangePasswordFromProfile} />
                                <Route path={Routes.confirmEmail} component={ConfirmEmail} />
                                <Route path={Routes.unsubscribe} component={Unsubscribe} />
                                <Route path={Routes.checkMail} component={CheckMail} />
                                {(user && user.tmpMode === false) ? ( <Redirect from={Routes.recovery} to={Routes.first} /> ) : ( <Route path={Routes.recovery} component={Recovery} /> )}
                                {(user && user.tmpMode === false) ? ( <Redirect from={Routes.changePassword} to={Routes.first} /> ) : ( <Route path={Routes.changePassword} component={ChangePass} /> )}
                                {/*<Route path={Routes.userSettings} component={Profile} />*/}
                                <Route path={Routes.userSettings} render={() => <Profile hook={smallHook} />} />
                                <Redirect from={Routes.index} to={Routes.first} />
                            </Switch>
                        </div>
                    </main>
                    <Footer />
                    <Modal active={modal.active} key={modal.count} resource={modal.resource} callBack={modal.callBack} modalType={modal.type} />
                </section>
            </section>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(userActions.getUser()),
        getIPV: () => dispatch(ipvActions.getIPV()),
        receiveASN: (payload) => dispatch(asnActions.receivedASN(payload)),
    };
};

const mapStateToProps = (state) => {
    return {
        user: state.user, //.isLogin,
        modal: state.modal,
        ipv: state.ipv,
        sortableColumn: state.prefixColumn,
        direction: state.prefixDirection,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
