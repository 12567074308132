import { API_URL } from "../../redux/constants";
import ajaxCache from "./AjaxCache";

export const get = async (reportid, af = 4, date = "") => {
	try {
		while (true) {
			let params = {
				reportid: reportid,
				af: `ipv${af}`,
				date: date
			};

			let queryString = Object.keys(params).map(param => {
				if (params[param]) {
					return encodeURIComponent(param) + '=' + encodeURIComponent(params[param]);
				}
				return null;
			}).join('&');

			const res = await ajaxCache.get(
					`${API_URL}/api/report/get/?${queryString}`,
					{
						withCredentials: true
					}
			);

			if (res.data.pending_update) {
				console.log('pending update...');
				ajaxCache.refresh(`${API_URL}/api/report/get/?${queryString}`);
				continue;
			}

			localStorage.setItem('reportHash', res.data.data.hash);

			return res.data;
		}
	} catch (error) {
		console.error(`ReportAPI get error ${error}`);
		localStorage.removeItem('reportId');
		localStorage.removeItem('reportHash');
		if (window.confirm('Ошибка на странице. Перезагрузить страницу?')){
			window.location.reload();
		};
	}
}

export const getByHash = async (hash, af = 4, date = "") => {
	try {
		let params = {
			hash: hash,
			af: `ipv${af}`,
			date: date
		};

		let queryString = Object.keys(params).map(param => {
			if (params[param]) {
				return encodeURIComponent(param) + '=' + encodeURIComponent(params[param]);
			}
			return null;
		}).join('&');

		const res = await ajaxCache.get(
				`${API_URL}/api/report/get/?${queryString}`,
				{
					withCredentials: true
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI get error ${error}`);
}
}

// Получить список отчетов, ТОЛЬКО ДЛЯ АВТОРИЗОВАННОГО ПОЛЬЗОВАТЕЛЯ
export const getList = async () => {
	try {
		const res = await ajaxCache.get(
				`${API_URL}/api/report/list/`,
				{
					withCredentials: true
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI getList error ${error}`);
	}
}

// создание отчета
export const create = async () => {
	try {
		const res = await ajaxCache.get(
				`${API_URL}/api/report/create/`,
				{
					withCredentials: true
				}
		);

		// add report id to localStorage
		if (res.data.status === "ok" && res.data.data.report_id) {
			localStorage.setItem('reportId', res.data.data.report_id);
			let hash = await getReportHash(res.data.data.report_id);
			localStorage.setItem('reportHash', hash);
		}
		return res.data;
	} catch (error) {
		console.error(`ReportAPI create error: ${error}`);
	}
}

export const updateName = async (reportId, reportName) => {
	try {
		const res = await ajaxCache.post(
				`${API_URL}/api/report/update/`,
				new URLSearchParams({
					report: JSON.stringify({
						id: reportId,
						name: reportName
					})
				}),
				{
					withCredentials: true
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI update error: ${error}`);
	}
}

export const getReportHash = async (reportId) => {
	try {
		const res = await ajaxCache.post(
				`${API_URL}/api/report/tmp_link/`,
				new URLSearchParams({
					reportid: reportId,
				}),
				{
					withCredentials: true
				}
		);

		return res.data.data.hash;
	} catch (error) {
		console.error(`ReportAPI getTmpLink error ${error}`);
	}
}

// создание временного отчета (id отчета храним в LocalStorage)
export const createTmp = async (data) => {
	try {
		const res = await ajaxCache.post(
				`${API_URL}/api/report/create_tmp/`,
				new URLSearchParams({
					resources: data
				}),
				{
					withCredentials: true,
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI createTmp API error ${error}`);
	}
}

// привязка временного отчета к пользователю
export const saveTmpReport = async (hash, userId) => {
	try {
		const res = await ajaxCache.post(
				`${API_URL}/api/report/save_tmp/`,
				new URLSearchParams({
					hash: hash,
					userid: userId
				}),
				{
					withCredentials: true,
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI saveTmpReport API error ${error}`);
	}
}

export const getHistory = async (reportId, af = 'ipv4') => {
	try {

		if (!reportId)
			throw "Не передан id отчета";

		const res = await ajaxCache.get(
				`${API_URL}/api/report/history/?reportid=${reportId}&af=${af}`,
				{
					withCredentials: true
				}
		);

		return res.data;
	} catch (error) {
		console.error(`getHistory API error ${error}`);
}
}

export const getDates = async (reportId) => {
	try {
		if (!reportId)
			return [];

		const res = await ajaxCache.get(
				`${API_URL}/api/report/getdates/?reportid=${reportId}`,
				{
					withCredentials: true
				}
		);

		return res.data;
	} catch (error) {
		console.error(`getHistory API error ${error}`);
	}
}

export const deleteReport = async (reportId) => {
	try {
		if (!reportId)
			return [];

		const res = await ajaxCache.post(
				`${API_URL}/api/report/delete/`,
				new URLSearchParams({
					reportid: reportId
				}),
				{
					withCredentials: true,
				}
		);

		return res.data;
	} catch (error) {
		console.error(`ReportAPI API error, method: deleteReport, error: ${error}`);
	}
}
