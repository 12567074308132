import { VIEW_POINTS_SET, VIEW_POINTS_CHANGED } from "../constants";

export default (state = {}, action) => {
    const { type, payload } = action;
    switch (type) {
        case VIEW_POINTS_SET:
            return payload;
        case VIEW_POINTS_CHANGED:
            return {
                ...payload
            };
        default:
            return state;
    }
};