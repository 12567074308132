import {IPV_GET, IPV_CHANGED} from "../constants";

export const getIPV = () => {
    if (!localStorage.getItem("ipvReport"))
        localStorage.setItem("ipvReport", "4");

    if (!localStorage.getItem("ipvHistory"))
        localStorage.setItem("ipvHistory", "4");

    return {
        type: IPV_GET,
        payload: {
            Report: localStorage.getItem("ipvReport"),
            History: localStorage.getItem("ipvHistory")
        },
    };
};

export const IpvChanged = (payload) => {
    localStorage.setItem(`ipv${payload.component}`, payload.value);
    return {
        type: IPV_CHANGED,
        payload: payload,
    };
};