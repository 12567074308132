import { MODAL_SHOW, MODAL_HIDE } from "../constants";
import * as PoolAPI from "../../helpers/api/Pool";
import * as ReportAPI from "../../helpers/api/Report";
import {toast} from "react-toastify";

export const showModal = (payload) => {
    return {
        type: MODAL_SHOW,
        payload: payload
    };
};

export const hideModal = () => {
    return {
        type: MODAL_HIDE
    };
};

// удаление ресурса
export const deleteSource = (poolId, resource) => {
    return async (dispatch) => {
        try {
            const response = await PoolAPI.deleteResource(poolId, resource);

            if (response.status === "error") {
                dispatch(hideModal());
                toast.error(`${response.reason.msg}`);
                return;
            }

            dispatch(hideModal());
            toast.success(`Ресурс ${resource.resource} успешно удалён`);
        } catch (e) {
            dispatch(hideModal());
            return toast.error(`${e}`);
        }
    };
};

// удаление пула
export const deletePool = (poolId, reportId, reportName) => {
    return async (dispatch) => {
        try {
            const response = await PoolAPI.deletePool(poolId);
            await ReportAPI.deleteReport(reportId);

            if (response.status === "error") {
                dispatch(hideModal());
                toast.error(`${response.reason.msg}`);
                return;
            }

            dispatch(hideModal());
            localStorage.removeItem("reportId");
            localStorage.removeItem("pollId");
            toast.success(`Пул "${reportName}" успешно удалён`);
        } catch (e) {
            dispatch(hideModal());
            return toast.error(`${e}`);
        }
    };
};