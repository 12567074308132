// Компонент просмотра текущих отчетов (пулов) для Отчета

import React, {createRef, Fragment} from "react";
import * as ReportAPI from "../../helpers/api/Report";
import Select from "react-select";
import * as Utils from "../../helpers/Utils";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import * as modalActions from "../../redux/actions/modalActions";
import * as reportActions from "../../redux/actions/reportActions";

class ReportSelector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportList: {},
            selectOptions: {value: "", label: "Загружаем отчеты..."},
            value: {value: "", label: "Загружаем отчеты..."},
            fakeInputActive: false
        };

        this._isMounted = false;
        this.fakeInputRef = createRef();

        this.onChange = this.onChange.bind(this);
        this.removePool = this.removePool.bind(this);
        // fake input events
        this.fakeInputShow = this.fakeInputShow.bind(this);
        this.fakeInputHide = this.fakeInputHide.bind(this);
        this.fakeInputKeyUp = this.fakeInputKeyUp.bind(this);
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.props.user.isLogin) !== JSON.stringify(prevProps.user.isLogin)
        || prevProps.reRender !== this.props.reRender)
            await this.setData();

        if (this.props.reRender) {
            if ('reRenderModal' in this.props) {
                this.props.reRenderModal(false);
                this.setState({
                    value: {
                        value: localStorage.getItem("reportId"),
                        label: `№${localStorage.getItem("reportId")}`
                    }
                })
                return;
            }

            if (this.props.onChangeCallback)
                this.props.onChangeCallback(localStorage.getItem("poolId"));

            await this.setData();
        }
    }

    async componentDidMount() {
        await this.setData();
    }

    async setData() {
        if (typeof this.props?.user?.isLogin === "undefined")
            return;

        if (typeof this.props?.user?.isLogin !== "undefined"
            && (!this.props.user.isLogin || !this.props.user.hasReport)) {
            return;
        }

        try {
            this._isMounted = true;
            let selectOptions = [],
                reportId = localStorage.getItem("reportId"),
                reportExists = false,
                curReport;

            const reportList = await ReportAPI.getList();

            if (!Utils.checkResponse(reportList, false) && !this._isMounted)
                return;

            if (reportList.data !== undefined && reportList.data.length) {
                reportList.data = reportList.data.filter(reportObj => Number(reportObj?.pool_id) > 0);

                selectOptions = reportList.data.map(report => {
                    // проверяем существует ли отчет из localStorage в списке отчетов
                    if (Number(reportId) === Number(report.report_id))
                        reportExists = true;

                    return {
                        value: report.report_id,
                        label: report.name
                    }
                });

                if (!reportExists) {
                    localStorage.setItem("reportId", reportList.data[0].report_id);
                    localStorage.setItem("poolId", reportList.data[0].pool_id)
                }
            }

            if (reportExists)
                curReport = reportList.data.filter(reportObj => Number(reportObj.report_id) === Number(reportId))[0];

            this.setState({
                reportList: reportList.data,
                selectOptions: selectOptions,
                value: {
                    value: reportExists ? Number(reportId) : selectOptions[0].value,
                    label: reportExists ? curReport.name : selectOptions[0].label
                }
            })
        } catch (e) {
            console.error(`component: ReportList, method: componentDidMount, error: ${e}`);
        }
    }

    onChange(value) {
        const date = `${(new Date()).toISOString().substring(0, 10)}T12:00:00`;
        localStorage.setItem('currentDate', new Date(date).toString());

        localStorage.setItem("reportId", value.value);
        let arReport = this.state.reportList.filter(report => report.report_id === value.value);

        if (typeof arReport?.[0]?.pool_id !== "undefined")
            localStorage.setItem("poolId", arReport?.[0]?.pool_id);

        this.setState({
            value: value
        });

        this.props.setReportId(value.value);

        if (this.props.onChangeCallback)
            this.props.onChangeCallback(value.value, new Date(date));
    }

    removePool() {
        let poolId = localStorage.getItem("poolId");
        let reportId = localStorage.getItem("reportId");

        // send event to modal
        this.props.modal.showModal({
            type: 'deletePool',
            resource: {
                poolId: poolId,
                reportId: reportId,
                reportName: this.state.value.label
            },
            callBack: () => this.props.removePoolCallback(),
        });
    }

    fakeInputShow() {
        let input = this.fakeInputRef.current;

        this.setState({
            fakeInputActive: true
        }, () => {
            input.value = this.state.value.label;
            input.focus();
            input.setSelectionRange(0, input.value.length);
        })
    }

    fakeInputHide() {
        this.setState({
            fakeInputActive: false
        })
    }

    async fakeInputKeyUp(e) {
        switch (e.key) {
            case 'Escape':
                this.fakeInputHide();
                break;

            case 'Enter':
                let fakeInputValue = this.fakeInputRef.current.value;
                let resp = await ReportAPI.updateName(this.state.value.value, fakeInputValue);

                if (!Utils.checkResponse(resp))
                    return toast.error("При переименовывании отчета возникла ошибка");

                // находим ключ текущего выбранного отчета
                let curSelectIndex = this.state.selectOptions.findIndex((element, index, array) => {
                    return Number(this.state.value.value) === Number(element.value)
                });

                this.state.selectOptions[curSelectIndex] = {
                    value: this.state.selectOptions[curSelectIndex].value,
                    label: fakeInputValue
                };

                this.setState({
                    value: {
                        ...this.state.value,
                        label: fakeInputValue
                    },
                    selectOptions: this.state.selectOptions
                }, () => {
                    this.fakeInputHide();
                    toast.success("Отчет успешно переименован");
                })
                break;
			default: 
				break;
        }
    }

    getTextWidth(text, font) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        context.font = '500 18px Roboto, sans-serif';

        return context.measureText(text).width;
    }

    render() {
        const inputLength = this.getTextWidth(this.state.value.label.slice(0, 32));
        return (
            <Fragment>
            <div className="select-container">
                {this.props.editable &&
                    <input
                        ref={this.fakeInputRef}
                        type="text"
                        className={`fake-select-input ${this.state.fakeInputActive ? 'active' : ''}`}
                        onBlur={this.fakeInputHide}
                        onKeyUp={this.fakeInputKeyUp}
                    />
                }

                <div style={{width: `${inputLength + 57}px`}} className="report-select" onDoubleClick={this.props.editable && this.fakeInputShow}>
                    <Select
                        classNamePrefix="react-select select"
                        options={this.state.selectOptions}
                        isSearchable={false}
                        placeholder={false}
                        value={{label: this.state.value.label.length > 32 ? `${this.state.value.label.slice(0, 32)}...` : this.state.value.label, value: this.state.value.value}}
                        onChange={this.onChange}
                    />
                </div>
            </div>

            {this.props.editable &&
            <button title="Удалить пул ресурсов" className="button button--white resource__title-button" onClick={this.removePool}>
                Удалить пул ресурсов
            </button>
            }
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        modal: state.modal,
        curReport: state.curReport
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        modal: {
            showModal: (data) => dispatch(modalActions.showModal(data)),
            hideModal: () => dispatch(modalActions.hideModal())
        },
        setReportId: (data) => dispatch(reportActions.setReportId(data))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportSelector);