// Компонент навигации (меню)

import React, { useState, Fragment } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import UserMenu from './UserMenu/UserMenu';
import { connect } from 'react-redux';
import Routes from '../../helpers/Routes/Routes';
import ClickOutside from '../../hoc/ClickOutside';
import * as UserAPI from "../../helpers/api/User";
import * as userActions from "../../redux/actions/userActions";
const Navbar = (props) => {
	let menu;
	let listClasses = 'nav__user-menu user-menu xs-hide xs-landscape-show';
	const {user} = props;
	const [visible, setVisible] = useState(false);
	const [settings, setSettings] = useState(false);
	const nav = document.querySelector('.header__nav');
	const history = useHistory();
	visible && (listClasses += ' user-menu--expanded');
	const toggleMenu = () => {
		props.burgerButton.current.classList.remove('burger-button--active');
		document.querySelector('body').classList.remove('no-scroll');
		nav.classList.remove('header__nav--visible');
	}

	const logout = async (event) => {
		event.preventDefault();
		const userData = {
			userId: user.userid,
			reportId: localStorage.getItem('reportId'),
			poolId: localStorage.getItem('poolId'),
		};
		setVisible(false);
		toggleMenu();
		await UserAPI.logout();
		props.logoutState({isLogin: false});
		localStorage.clear();
		localStorage.setItem('userId', userData.userId);
		localStorage.setItem('reportId', userData.reportId);
		localStorage.setItem('poolId', userData.poolId);
		history.push(Routes.first);
	};
	menu = user.isLogin
			? <UserMenu username={user.name} visible={visible} changeVisible={setVisible} hideMenu={toggleMenu} />
			: <NavLink className="link menu__link" to={Routes.auth} onClick={toggleMenu}>Войти</NavLink>;

	return (
			<nav className="nav header__nav">
				<ul className="nav__list menu">
					<li className="menu__item" onClick={toggleMenu}>
						<NavLink className="link menu__link" activeClassName="menu__link--active" to={Routes.first}>Мониторинг</NavLink>
					</li>
					<li className="menu__item" onClick={toggleMenu}>
						<NavLink className="link menu__link" activeClassName="menu__link--active" to={Routes.about}>О проекте</NavLink>
					</li>
					{user.isLogin && (
									<Fragment>
										<li className="menu__item xs xs-landscape-hide" onClick={toggleMenu}>
											<NavLink className="link menu__link" activeClassName="menu__link--active" to={Routes.userSettings}>{user.name}</NavLink>
										</li>
										<li className="menu__item xs xs-landscape-hide" onClick={toggleMenu}>
											<NavLink className="link menu__link" activeClassName="menu__link--active" to={Routes.viewpoints}>Точки наблюдения</NavLink>
										</li>
										<li className="menu__item xs xs-landscape-hide" onClick={toggleMenu}>
											<a href="" className="link menu__link" onClick={logout}>Выход</a>
										</li>
									</Fragment>
										)}
					{user.isLogin === false && (
							<li className="menu__item xs xs-landscape-hide" onClick={toggleMenu}>
							<NavLink className="link menu__link" activeClassName="menu__link--active" to={Routes.auth}>Войти</NavLink>
							</li>
								)}
				</ul>
				<div className="menu--space"></div>
				<div className={listClasses}>
					{menu}
				</div>
			</nav>
			);
};
const mapStateToProps = state => {
	return {
		user: state.user,
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		logoutState: (data) => dispatch(userActions.logoutUser(data)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
