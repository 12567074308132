import * as ReportAPI from "./api/Report";
import * as PoolAPI from "./api/Pool";

export const extractPrefixes = (report) => {
    let prefixes = [];
    for (const [, resource] of Object.entries(report.data.bgp_data)) {
        for (const [prefixName, prefix] of Object.entries(resource)) {
            prefixes[prefixName] = {
                global: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                regional: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                ix: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                as: undefined,
                info: undefined
            };
            for (const [placeName, place] of Object.entries(prefix)) {
                let placePathCount = 0;
                let placePathSum = 0;
                let placeMin = undefined;
                let placeMax = undefined;

                for (const [, view] of Object.entries(place)) {
                    for (const [, ipBlock] of Object.entries(view)) {
                        for (const stat of ipBlock) {
                            placePathCount++;
                            prefixes[prefixName]['name'] = prefixName;
                            prefixes[prefixName]['as'] = stat['as-path'].split(' ').pop();
                            prefixes[prefixName]['full_path'] = stat['as-path'];

                            let asPathCount = 0;
                            for (let ase of stat['as-path'].split(' ')) {
                                let [v1, v2] = ase.split('*');
                                asPathCount += (v1 && v2) ? (Number.parseInt(v2)) : 1;
                            }

                            placePathSum += asPathCount;

                            if (placeMin === undefined) {
                                placeMin = asPathCount;
                                placeMax = asPathCount;
                            }
                            if (asPathCount < placeMin) {
                                placeMin = asPathCount;
                            }
                            if (asPathCount > placeMax) {
                                placeMax = asPathCount;
                            }
                        }
                    }
                }

                prefixes[prefixName][placeName] = {};
                prefixes[prefixName][placeName]['min'] = Number(placeMin.toFixed(1));
                prefixes[prefixName][placeName]['mid'] = Number((placePathSum / placePathCount).toFixed(1));
                prefixes[prefixName][placeName]['max'] = Number((placeMax).toFixed(1));
            }
        }
    }

    return prefixes;
};

export const updatePrefixesInfo = async (prefixes) => {
    const response = await PoolAPI.getList(localStorage['poolId']);
    for (const resource of response.data?.resource) {
        for (const prefixName of Object.keys(prefixes)) {
            if (prefixName === resource.resource) {
                prefixes[prefixName]['info'] = resource['comment'] ?? 'No info...';
                prefixes[prefixName]['pending_update'] = (resource.pending_update ?? false)
                    || (!resource.prefixes.v4 && !resource.prefixes.v6);
            }
        }
    }
    return prefixes;
};

export const fetchPrefixes = async () => {
    const report4 = await ReportAPI.get(localStorage['reportId'], 4);
    const report6 = await ReportAPI.get(localStorage['reportId'], 6);

    const prefixes4 = extractPrefixes(report4);
    const prefixes6 = extractPrefixes(report6);

    return await updatePrefixesInfo({...prefixes4, ...prefixes6});
};

export const buildGetCompareClass = (compareMode, tableModel) => {
    return (row, kind, subkind) => {
        if (compareMode.on) {
            let current, prev;
            if (subkind !== 'place') {
                current = row[kind + '_' + subkind];
                prev = tableModel.getVirtualProperty(row['id'])['prev_' + kind + '_' + subkind];
            } else {
                let a1 = row[kind + '_min'];
                let b1 = tableModel.getVirtualProperty(row['id'])['prev_' + kind + '_min'];

                let a2 = row[kind + '_mid'];
                let b2 = tableModel.getVirtualProperty(row['id'])['prev_' + kind + '_mid'];

                let a3 = row[kind + '_max'];
                let b3 = tableModel.getVirtualProperty(row['id'])['prev_' + kind + '_max'];

                if ((a1 !== b1 && b1 !== undefined) || (a2 !== b2 && b2 !== undefined) || (a3 !== b3 && b3 !== undefined)
                    || (tableModel.getVirtualProperty(row['id'])['full_path'] !== row['prev_full_path'])) {
                    return 'MonitoringTable__ColumnPlace__Modify';
                } else {
                    return '';
                }
            }
            if (!prev) {
                return 'MonitoringTable__CompareClass__Second';
            } else if (current < prev) {
                return 'MonitoringTable__CompareClass__Down';
            } else if (current > prev) {
                return 'MonitoringTable__CompareClass__Up';
            }
        }
        return '';
    };
};
export const reportToTableElements = async (report) => {
    let prefixes = [];
    for (const [, resource] of Object.entries(report.data.bgp_data)) {
        for (const [prefixName, prefix] of Object.entries(resource)) {

            if (!(prefixName in report.data.bgp_data)) continue;

            prefixes[prefixName] = {
                global: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                regional: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                ix: {
                    min: undefined,
                    mid: undefined,
                    max: undefined
                },
                as: undefined
            };
            for (const [placeName, place] of Object.entries(prefix)) {
                let placePathCount = 0;
                let placePathSum = 0;
                let placeMin = undefined;
                let placeMax = undefined;

                for (const [, view] of Object.entries(place)) {
                    for (const [, ipBlock] of Object.entries(view)) {
                        for (const stat of ipBlock) {
                            placePathCount++;
                            prefixes[prefixName]['as'] = stat['as-path'].split(' ').pop();
                            let asPathCount = 0;
                            for (let ase of stat['as-path'].split(' ')) {
                                let [v1, v2] = ase.split('*');
                                asPathCount += (v1 && v2) ? (Number.parseInt(v2)) : 1;
                            }
                            prefixes[prefixName]['full_path'] = stat['as-path'];
                            placePathSum += asPathCount;
                            if (placeMin === undefined) {
                                placeMin = asPathCount;
                                placeMax = asPathCount;
                            }
                            if (asPathCount < placeMin) {
                                placeMin = asPathCount;
                            }
                            if (asPathCount > placeMax) {
                                placeMax = asPathCount;
                            }
                        }
                    }
                }
                prefixes[prefixName][placeName] = {};
                prefixes[prefixName][placeName]['min'] = Number(placeMin.toFixed(1));
                prefixes[prefixName][placeName]['mid'] = Number((placePathSum / placePathCount).toFixed(1));
                prefixes[prefixName][placeName]['max'] = Number((placeMax).toFixed(1));
            }
        }
    }

    let columns = [];
    for (const [prefixName, prefix] of Object.entries(prefixes)) {
        columns.push({
            prefix: prefixName,
            as: !!prefix.as ? 'AS' + prefix.as : '-',
            info: "Type info...",
            global_min: prefix['global']['min'],
            global_mid: prefix['global']['mid'],
            global_max: prefix['global']['max'],
            region_min: prefix['regional']['min'],
            region_mid: prefix['regional']['mid'],
            region_max: prefix['regional']['max'],
            local_min: prefix['ix']['min'],
            local_mid: prefix['ix']['mid'],
            local_max: prefix['ix']['max'],
            remove: false,
            full_path: prefix['full_path']
        });
    }

    const response = await PoolAPI.getList(localStorage['poolId']);
    for (const resource of response.data.resource) {
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].prefix === resource.resource) {
                columns[i].info = resource.comment ?? 'No info...';
                columns[i].pending_update = (resource.pending_update ?? false)
                    || (!resource.prefixes.v4 && !resource.prefixes.v6);
            }
        }
    }

    return columns;
};
