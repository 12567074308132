import { REPORT_SET } from "../constants";

export default (state = {}, action) => {

    const { type, payload } = action;
    switch (type) {
        case REPORT_SET:
            return payload;
        default:
            return state;
    }
};