import React from 'react';
import { observer } from "mobx-react-lite";

const ColumnTrashDesktop = observer(({ tableModel, onDeletePrefix }) => {
    return <div className="MonitoringTable__HeaderTrash">
        <div className="MonitoringTable__HeaderTrash__Icon">
            <input type="checkbox" onClick={(e) => {
                for(let row of tableModel.elements) {
                    tableModel.setVirtualProperty(row['id'], {remove: e.target.checked});
                }
            }}/>
        </div>
    </div>
});

export default ColumnTrashDesktop;