import store from '../store';
import {FILTER_EMPTY, FILTER_RECEIVED, FILTER_UPDATED} from "../constants";
import { toast } from "react-toastify";

export const filterReceived = (payload) => {
    return {
        type: FILTER_RECEIVED,
        payload: payload,
    };
};

export const filterUpdated = (payload) => {
    return {
        type: FILTER_UPDATED,
        payload: payload,
    };
};

export const filterEmpty = payload => {
    return {
        type: FILTER_EMPTY,
        payload: payload,
    };
};

export const setFilter = (data) => {
    return async (dispatch) => {
        try {
            dispatch(filterReceived(data));
        } catch (e) {
            return toast.error(`${e}`);
        }
    };
};

export const updateFilter = (data) => {
    return async (dispatch) => {
        try {
            let localFilter = {
                resources: {},
                viewPoints: data.viewPoints || {}
            };
            const state = store.getState();

            // сформируем объект фильтра ресурсов (т.к. в изначальном объекте много лишней инфы) для записи в localStorage
            if (data?.resources) {
                Object.keys(data.resources).forEach(res => {
                    let resource = data.resources[res];
                    if (!localFilter.resources?.[res])
                        localFilter.resources[res] = {
                            checked: resource.checked,
                            prefixes: {}
                        };

                    Object.keys(resource.prefixes).forEach(prefix => {
                        localFilter.resources[res].prefixes[prefix] = {
                            checked: resource.prefixes[prefix].checked,
                            views: {}
                        };

                        if (!resource.prefixes[prefix]?.views) return;
                        Object.keys(resource.prefixes[prefix].views).forEach(view => {
                            localFilter.resources[res].prefixes[prefix].views[view] = resource.prefixes[prefix].views[view];
                        })

                    })
                })
            }

            if (state.user.isLogin) {
                localStorage.setItem("filterResources", JSON.stringify(localFilter.resources));
                localStorage.setItem("filterViewPoints", JSON.stringify(localFilter.viewPoints));
            }
            
            dispatch(filterUpdated(data));
        } catch (e) {
            console.error(`${e}`);
        }
    };
};