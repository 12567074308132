import React from "react";

const CalendarIcon = (props) => {

	return (
			<svg className="prefix__calendar-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M17.8 5.59998H6.6C5.71634 5.59998 5 6.31632 5 7.19998V18.4C5 19.2836 5.71634 20 6.6 20H17.8C18.6837 20 19.4 19.2836 19.4 18.4V7.19998C19.4 6.31632 18.6837 5.59998 17.8 5.59998Z" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M15.3999 4V7.2" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M9 4V7.2" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
				<path d="M5 10.4H19.4" stroke="#ABB0BA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>
			)
};


export default CalendarIcon;