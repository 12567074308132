import React, { Fragment, useState } from "react";
import {connect} from "react-redux";
import DatePicker from 'react-datepicker';
import CalendarIcon from "../UI/CalendarIcon";
import {showDate} from "../UI/formatDate";
import * as uiHelper from "../UI/ui-helper";
import Switcher from "../UI/Switcher";
import switchClicked from "../../redux/actions/switchActions";
import datePickerActions from "../../redux/actions/datePickerActions";
import "react-datepicker/dist/react-datepicker.css";


export const DatePickerCustom = props => {
	const [startDate] = useState(props.startDate ?? new Date());
	const {checked, compareDate} = props;
	const {switchStatus, setCompareDate} = props;
	const {includeDates} = props;

	const click = () =>{
		switchStatus(checked);
	};

	const formatUTC = (dateInt, addOffset = false) => {
		let date = (!dateInt || dateInt.length < 1) ? new Date() : new Date(dateInt);
		if (typeof dateInt === "string") {
			return date;
		} else {
			const offset = addOffset ? date.getTimezoneOffset() : -(date.getTimezoneOffset());
			const offsetDate = new Date();
			offsetDate.setTime(date.getTime() + offset * 60000)
			return offsetDate;
		}
	};

	//console.log(checked ? null : showDate(startDate, compareDate));

	const val = checked ? null : showDate(startDate, compareDate);
	const sel = val ? new Date(val.split('-').reverse()) : new Date();

	return (
			<Fragment>
				<div className="prefix__group__datepicker">
					<div className="prefix__datepicker"
					     title={checked ? "" : "Выберете дату для сравнения путей"}>
							<CalendarIcon />
							<DatePicker
								value={val}
								openToDate={sel ?? startDate}
								disabled={checked}
								calendarIcon={ <CalendarIcon /> }
								clearIcon=""
								onChange={data => {
									setCompareDate(formatUTC(data));
								}}
								placeholderText={"Сравнение"}
								dateFormat="dd-MM-y"
								locale='ru'
								maxDetail="month"
								monthsShown={uiHelper.isSmallScreen() ? 1 : 3}
								includeDates={includeDates}
								/>
							<span onClick={click}
								  title="Вкл./выкл. режим сравнения">
							<Switcher
								type={'blue'} 
								className={"compare-icon__blue-white"}
								onClick="{click}"
								/>
							</span>			
					</div>
				</div>
			</Fragment>
					);

		}

		/**
		 * maps передаёт свойство в props
		 **/
		function mapStateToProps(state)
		{
			return {
				checked: state.checked,
				compareDate: state.compareDate,
			}
		}

		/**
		 * отправка maps в props
		 **/
		function mapDispatchToProps(dispatch) {
			return {
				switchStatus: (data) => dispatch(switchClicked(data)),
				setCompareDate: (data) => dispatch(datePickerActions(data)),
			}
		}

		export default connect(mapStateToProps, mapDispatchToProps)(DatePickerCustom);
