import React, { useState } from "react";
import * as UserAPI from "../../helpers/api/User";
import {toast} from "react-toastify";

const ProfileFreq = ({selectedDays, getUser}) => {
    const [daysState, setDaysState] = useState([
        {
            name: 'Пн',
            checked: selectedDays[0] === "1"
        }, {
            name: 'Вт',
            checked: selectedDays[1] === "1"
        }, {
            name: 'Ср',
            checked: selectedDays[2] === "1"
        }, {
            name: 'Чт',
            checked: selectedDays[3] === "1"
        }, {
            name: 'Пт',
            checked: selectedDays[4] === "1"
        }, {
            name: 'Сб',
            checked: selectedDays[5] === "1"
        }, {
            name: 'Вс',
            checked: selectedDays[6] === "1"
        }
    ]);

    const updateReportFrequency = async (newDaysState) => {
        const data = {
            reports_freq: '2_' + newDaysState.map(d => d.checked ? '1' : '0').join(''),
        };
        const response = await UserAPI.update(data);
        const { status, reason } = response.data;

        if (status === "error") return toast.error(`${reason.msg}`);

        toast.success("Рассылка отчета обновлена.");
        getUser();
    };



    return <div className="profile--days">
        <p className="profile__text">
            Настройте расписание, по которому вы хотите получать новые
            отчеты на электронную почту.
        </p>
        <div className="profile-days-container">
        {daysState.map((d, i) => (
            <div key={i} className={['profile--day', ...(d.checked ? ['profile--day-checked'] : [])].join(' ')} onClick={() => {
                const newDaysState = daysState.map((itDay, itIdx) => {
                    if(itIdx === i) return {
                        ...itDay,
                        checked: !itDay.checked
                    };
                    return itDay;
                });
                setDaysState(newDaysState);
                updateReportFrequency(newDaysState);
            }}>
                {d.name}
            </div>
        ))}
        </div>
    </div>;
};

export default ProfileFreq;
