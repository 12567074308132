import React from "react";

export const GlobalIcon = () => {
return (
	<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clipPath="url(#clip0)">
			<path
				d="M0.5 9C0.5 4.58172 4.08172 1 8.5 1C12.9183 1 16.5 4.58172 16.5 9C16.5 13.4183 12.9183 17 8.5 17C4.08172 17 0.5 13.4183 0.5 9Z"
				fill="url(#paint0_linear)"/>
			<path
				d="M15.4383 4.79981C15.3214 4.58856 15.0779 4.49132 14.8539 4.57179L13.2499 5.14182L11.9674 4.92052L11.5453 4.27001C11.4057 4.05541 11.133 3.985 10.9154 4.10236L7.52251 5.93987C7.34393 6.03711 7.24003 6.2383 7.26601 6.44954C7.29198 6.66079 7.43809 6.82844 7.63615 6.87539L9.83101 7.41524L10.4641 8.42788C10.5778 8.60895 10.7888 8.69613 10.9934 8.64248L11.8603 8.41782L12.2694 8.69949V9.54112C12.2694 9.66854 12.3148 9.78925 12.3993 9.88314L13.8246 11.4792V14.0008C13.8246 14.2154 13.9545 14.4065 14.1525 14.4769C14.2045 14.497 14.2597 14.5037 14.3116 14.5037C14.4577 14.5037 14.5974 14.4367 14.6915 14.3126C15.2662 13.5682 15.711 12.74 16.0195 11.8514C16.3377 10.9327 16.5 9.96697 16.5 8.9845C16.5 7.52254 16.1331 6.07399 15.4383 4.79981ZM8.01278 9.8429L6.49326 9.52771L5.21076 8.0691C5.1166 7.9618 4.98348 7.90144 4.84387 7.9048L3.61981 7.92156L3.77566 6.29194L5.4835 5.41007C5.58415 5.35978 5.66207 5.2726 5.70753 5.16865L6.12637 4.21301L7.55498 3.62957C7.71732 3.56251 7.8342 3.40826 7.85693 3.23055C7.87966 3.05283 7.80823 2.87176 7.66862 2.76111L5.5322 1.10131C5.39583 0.994014 5.21401 0.970542 5.05491 1.0376C3.04188 1.8893 1.39573 3.5625 0.538571 5.63138C0.493115 5.74539 0.486621 5.87281 0.525583 5.98681L1.00611 7.47895C1.04183 7.58625 1.10677 7.67678 1.19768 7.73714L2.59382 8.69613V9.77584C2.59382 9.90661 2.64252 10.034 2.73018 10.1279L3.93476 11.4088V12.8305C3.93476 12.9479 3.97372 13.0619 4.0484 13.1524L5.39259 14.8189C5.48674 14.9363 5.62636 15 5.76597 15C5.82117 15 5.87636 14.9899 5.93156 14.9698C6.12312 14.8994 6.253 14.7083 6.253 14.497V13.3603L7.13289 12.6796C7.25627 12.5858 7.3277 12.4349 7.3277 12.2773V11.6536L8.25954 10.6912C8.38617 10.5605 8.43487 10.366 8.37967 10.1883C8.33097 10.0139 8.18811 9.87978 8.01278 9.8429Z"
				fill="url(#paint1_linear)"/>
		</g>
		<path
			d="M8.5 16.5C4.35786 16.5 1 13.1421 1 9H0C0 13.6944 3.80558 17.5 8.5 17.5V16.5ZM16 9C16 13.1421 12.6421 16.5 8.5 16.5V17.5C13.1944 17.5 17 13.6944 17 9H16ZM8.5 1.5C12.6421 1.5 16 4.85786 16 9H17C17 4.30558 13.1944 0.5 8.5 0.5V1.5ZM8.5 0.5C3.80558 0.5 0 4.30558 0 9H1C1 4.85786 4.35786 1.5 8.5 1.5V0.5Z"
			fill="#BDBDBD"/>
		<defs>
			<linearGradient id="paint0_linear" x1="8.50049" y1="17" x2="8.50049" y2="1" gradientUnits="userSpaceOnUse">
				<stop stopColor="#5558FF"/>
				<stop offset="1" stopColor="#00C0FF"/>
			</linearGradient>
			<linearGradient id="paint1_linear" x1="8.50029" y1="15.003" x2="8.50029" y2="0.997377"
							gradientUnits="userSpaceOnUse">
				<stop stopColor="#ADDCFF"/>
				<stop offset="0.5028" stopColor="#EAF6FF"/>
				<stop offset="1" stopColor="#EAF6FF"/>
			</linearGradient>
			<clipPath id="clip0">
				<path
					d="M0.5 9C0.5 4.58172 4.08172 1 8.5 1C12.9183 1 16.5 4.58172 16.5 9C16.5 13.4183 12.9183 17 8.5 17C4.08172 17 0.5 13.4183 0.5 9Z"
					fill="white"/>
			</clipPath>
		</defs>
	</svg>

		);
};

export const RegionalIcon = () => {
return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0)">
		<path d="M1 9.00012C1 4.58184 4.58172 1.00012 9 1.00012C13.4183 1.00012 17 4.58184 17 9.00012C17 13.4184 13.4183 17.0001 9 17.0001C4.58172 17.0001 1 13.4184 1 9.00012Z" fill="white"/>
		<rect x="1" y="1.00012" width="16" height="5" fill="white"/>
		<rect x="1" y="6.00012" width="16" height="6" fill="#0C0CFF"/>
		<rect x="1" y="12.0001" width="16" height="5" fill="#FF0C0B"/>
	</g>
	<path d="M9 16.5001C4.85786 16.5001 1.5 13.1423 1.5 9.00012H0.5C0.5 13.6945 4.30558 17.5001 9 17.5001V16.5001ZM16.5 9.00012C16.5 13.1423 13.1421 16.5001 9 16.5001V17.5001C13.6944 17.5001 17.5 13.6945 17.5 9.00012H16.5ZM9 1.50012C13.1421 1.50012 16.5 4.85799 16.5 9.00012H17.5C17.5 4.3057 13.6944 0.500122 9 0.500122V1.50012ZM9 0.500122C4.30558 0.500122 0.5 4.3057 0.5 9.00012H1.5C1.5 4.85799 4.85786 1.50012 9 1.50012V0.500122Z" fill="#BDBDBD"/>
	<defs>
		<clipPath id="clip0">
			<path d="M1 9.00012C1 4.58184 4.58172 1.00012 9 1.00012C13.4183 1.00012 17 4.58184 17 9.00012C17 13.4184 13.4183 17.0001 9 17.0001C4.58172 17.0001 1 13.4184 1 9.00012Z" fill="white"/>
		</clipPath>
	</defs>
</svg>

		);
}

export const LocalIcon = () => {
return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M1 9C1 4.58172 4.58172 1 9 1C13.4183 1 17 4.58172 17 9C17 13.4183 13.4183 17 9 17C4.58172 17 1 13.4183 1 9Z" fill="#7EE74D"/>
	<path d="M6.19971 12.5547H4.73486V5.44531H6.19971V12.5547Z" fill="#223354"/>
	<path d="M10.1938 7.89648L11.5269 5.44531H13.2114L11.1411 8.9707L13.2651 12.5547H11.561L10.1938 10.0645L8.82666 12.5547H7.12256L9.24658 8.9707L7.17627 5.44531H8.86084L10.1938 7.89648Z" fill="#223354"/>
	<path d="M9 16.5C4.85786 16.5 1.5 13.1421 1.5 9H0.5C0.5 13.6944 4.30558 17.5 9 17.5V16.5ZM16.5 9C16.5 13.1421 13.1421 16.5 9 16.5V17.5C13.6944 17.5 17.5 13.6944 17.5 9H16.5ZM9 1.5C13.1421 1.5 16.5 4.85786 16.5 9H17.5C17.5 4.30558 13.6944 0.5 9 0.5V1.5ZM9 0.5C4.30558 0.5 0.5 4.30558 0.5 9H1.5C1.5 4.85786 4.85786 1.5 9 1.5V0.5Z" fill="#BDBDBD"/>
</svg>

		);
}

export default {GlobalIcon, RegionalIcon, LocalIcon};


