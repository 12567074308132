// Компонент подвала

import React from "react";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="footer">
      <p className="footer__text">
        ©ФРСТ «
        <a target="_blank" rel="noopener noreferrer" href="https://www.ididb.ru/" className="link footer__link">
          ИнДата
        </a>
        », {year}
      </p>
    </footer>
  );
};

export default Footer;
