// Компонент капчи

import React, { Fragment } from "react";
import * as CaptchaAPI from "../../helpers/api/Captcha";
import { withRouter } from "react-router-dom";
import Routes from "../../helpers/Routes/Routes";
import ReCAPTCHA from "react-google-recaptcha";
import {RECAPTCHA_KEY} from '../../redux/constants';
import Loader from "../Loader/Loader";

class Captcha extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            imagesURL: [],
            recaptchaRef: React.createRef()
        }

        this.reCaptchaHandler = this.reCaptchaHandler.bind(this);
    }

    componentDidMount() {
        const self = this;

        CaptchaAPI.getCaptcha()
            .then(response => {
                self.props.callback(response);
                response.imagesURL = response.imagesURL.map(i => ({...i, checked: false}));
                self.setState({
                    ...response,
                    needRerender: false
                });

            })
            .catch(err => {
                console.error(err);
            });
    }

    reCaptchaHandler() {
        this.props.callback({
            'g-recaptcha': this.state.recaptchaRef.current.getValue(),
            showRecaptcha: true
        });
    }

    render() {
        const self = this;

        if (this.props.reRender) {
            const checkedImages = document.querySelectorAll(
                'input[name="captcha[]"]:checked'
            );
            checkedImages.forEach(el => el.checked = false);

            CaptchaAPI.getCaptcha()
                .then(response => {
                    self.props.callback(response);
                    response.imagesURL = response.imagesURL.map(i => ({...i, checked: false}));
                    self.setState({
                        ...self.state,
                        ...response
                    });
                })
                .catch(err => {
                    throw new Error(err);
                });
        }
        if (!this.state.imagesURL.length) return null;

        const images = this.state.imagesURL.map((image, idx) => (
            <label className="captcha__label" key={image.id}>
                <input
                    type="checkbox"
                    className="visually-hidden"
                    name="captcha[]"
                    data-key={image.id}
                    checked={image.checked}
                    onChange={(e) => {
                        this.setState({
                            ...this.state,
                            imagesURL: this.state.imagesURL.map((img, i) => {
                                if(i === idx) {
                                    return {...img, checked: e.target.checked};
                                } else {
                                    return img;
                                }
                            })
                        });
                    }}
                />
                <img className="captcha__img" alt={this.state.question} src={image.url}/>
            </label>
        ));

        return (
            <Fragment>
            {!this.props.showRecaptcha &&
            <div
                className={`captcha ${this.props.history.location.pathname.includes(Routes.registration) && 'captcha--registration' || ''}`}>
                {!this.props.verified && (
                    <Fragment>
                        <p className="captcha__title">{this.state.question}</p>
                        <div className="captcha__images">{images}</div>
                        <input className="button report__button report__button_new captcha__button"
                               type="button"
                               value={"Отправить каптчу"}
                               onClick={() => this.props.onSubmit(
                                   this.state,
                                   this.state.imagesURL
                                       .map((i, idx) => i.checked ? idx : null)
                                       .filter(i => i !== null)
                               )}/>
                    </Fragment>
                ) || <span className="Checkmark"></span>}
            </div>
            }
            {this.props.showRecaptcha &&
            <div className="captcha captcha--registration">
            <ReCAPTCHA sitekey={RECAPTCHA_KEY}
                       ref={this.state.recaptchaRef}
                       onChange={this.reCaptchaHandler} />
            </div>
            }
            </Fragment>
        );
    }
}

export default withRouter(Captcha);
