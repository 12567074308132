import {SHOW_SORT_PAGE} from '../constants';

export default (state = {}, action) => {

    const { type, payload } = action;
    if (type === SHOW_SORT_PAGE) {

        return payload;
    }

    return state;
};