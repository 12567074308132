const columns = [
	{
		key: 'title',
		title: 'Точка наблюдения',
	},
	{
		key: 'type',
		title: 'Тип',
	},
	{
		key: 'as',
		title: 'AS',
	},
	{
		key: 'as_path',
		title: 'AS_PATH',
	},
	{
		key: 'as_length',
		title: 'Длина',
	},
	{
		key: 'as_length_change',
		title: 'Изменение',
	},
];

export default columns;
