// Страница изменения пароля для зарега (через профиль "Сменить пароль")

import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Routes from "../../helpers/Routes/Routes";
import logoAuth from "../../img/logo.svg";
import Input from "../UI/Input/Input";
import * as UserAPI from "../../helpers/api/User";
import SomethingWentWrong from "../SomethingWentWrong/SomethingWentWrong";
import Headers from "../../helpers/Headers";

const ChangePasswordFromProfile = () => {
    const history = useHistory();
    const email = history.location.state && history.location.state.email

    const initialState = {
        form: {
            oldPassword: {
                elementType: "input",
                config: {
                    type: "password",
                },
                title: "Старый пароль",
                value: document.getElementsByClassName('field')[0] ? document.getElementsByClassName('field')[0].value : '',
                error: "",
            },
            newPassword: {
                elementType: "input",
                config: {
                    type: "password",
                },
                title: "Новый пароль",
                value: document.getElementsByClassName('field')[1] ? document.getElementsByClassName('field')[1].value : '',
                error: "",
            },
            confirmPassword: {
                elementType: "input",
                config: {
                    type: "password",
                },
                title: "Повторите пароль",
                value: document.getElementsByClassName('field')[2] ? document.getElementsByClassName('field')[2].value : '',
                error: "",
            },
        },
    };
    const [state, setState] = useState(initialState);
    const [userEmail, setUserEmail] = useState(email);
    const [userError, setUserError] = useState(false);
    if (!userEmail){
        UserAPI.get().then(async responce => {
            if (responce?.data?.data) {
                setUserEmail(
                    responce.data.data.login
                )
            } else {
                setUserError(true)
            }
        });
    }

    useEffect(() => {
        document.title = Headers.passwordRecovery;
    }, [])

    const inputHandler = (event, field) => {
        const updatedState = {
            ...state.form,
            [field]: {
                ...state.form[field],
                value: event.target.value,
                error: state.form[field].value && '',
            },
        };
        setState({ form: updatedState });
    };

    const editHandler = ({ target }) => {
        const input = target.closest("label").querySelector(".field");
        input.type = input.type === "password" ? "text" : "password";
    };

    const formHandler = (event) => {
        event.preventDefault();
        const { oldPassword, newPassword, confirmPassword } = state.form;
        const errors = [];
        
        let nestedState = { ...state };

        for (const key in nestedState.form) {
            nestedState = {
                form: {
                    ...nestedState.form,
                    [key]: {
                        ...nestedState.form[key],
                        error: !nestedState.form[key].value && 'Поле не заполнено1'
                    }
                }
            }

            nestedState.form[key].value ? errors.splice(1, 1) : errors.push('');
        }



        if (errors.length) {
            setState(nestedState);
            return;
        };

        if (newPassword.value !== confirmPassword.value) {
            setState({
                form: {
                    ...state.form,
                    newPassword: {
                        ...state.form.newPassword,
                        error: 'Пароли не совпадают',
                    },
                    confirmPassword: {
                        ...state.form.confirmPassword,
                        error: 'Пароли не совпадают',
                    },
                },
            });

            return;
        }

        UserAPI.changePassword(
            email,
            oldPassword.value,
            newPassword.value
        ).then((response) => {
            const { status, reason } = response.data;

            if (status === "error") {
                switch (reason.code) {
                    // Старый пароль неверный
                    case 1007:
                        setState({
                            form: {
                                ...initialState.form,
                                oldPassword: {
                                    ...initialState.form.oldPassword,
                                    error: reason.msg
                                }
                            }
                        })
                    break;
                    default:
                        toast.error(`Code: ${reason.code} / ${reason.msg}`)
                    break;
                }
                return;
            }

            history.push(Routes.userSettings);
            toast.success("Пароль изменен!");
        });
    };

    const renderForm = () => {
        const formElements = [];

        for (const key in state.form) {
            formElements.push({
                id: key,
                ...state.form[key],
            });
        }

        const form = formElements.map((input) => (
            <Input
                key={input.id}
                elementType={input.elementType}
                elementTitle={input.title}
                elementConfig={input.config}
                elementError={input.error}
                changed={(event) => inputHandler(event, input.id)}
                clicked={(event) => editHandler(event)}
                showPassword={true}
            />
        ));

        return form;
    };

    return (
        <Fragment>
            {userError && <SomethingWentWrong />}
            {userEmail && (
                <section className="start-page">
                    <div className="start-page__logo-container">
                        <img
                            src={logoAuth}
                            alt="DasPath"
                            className="logo start-page__logo"
                        />
                    </div>
                    <p className="start-page__text">Придумайте новый пароль.</p>
                    <form
                        action=""
                        className="start-page__form"
                        onSubmit={(event) => formHandler(event)}
                    >
                        {renderForm()}
                        <button type="submit" className="button">
                            Сохранить
                        </button>
                    </form>
                </section>
            )}
        </Fragment>
    );
};

export default ChangePasswordFromProfile;
