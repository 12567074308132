import {SWITCH_CHANGED} from '../constants';

export default (state = {}, action) => {

   const { type, payload } = action;

   if (type === SWITCH_CHANGED) {
       return payload;
   }

   if(JSON.stringify(state) === '{}') {
       if(localStorage["checked"]) {
           return JSON.parse(localStorage["checked"]);
       }
       return true;
   }

   return state;
};