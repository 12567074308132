import {SET_MONITORING_COLUMN} from '../constants';

export default (state = {}, action) => {
    const { type, payload } = action;
    if (type === SET_MONITORING_COLUMN) {
        localStorage.monitoringColumn = payload;

        return payload;
    }

    return localStorage.monitoringColumn ? localStorage.monitoringColumn : '';
};
