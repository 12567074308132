// Страница "Ресурсы" (Пул ресурсов)

import React, {Fragment, useEffect, useRef, useState, useCallback} from 'react';
import * as PoolAPI from "../../helpers/api/Pool";
import * as ReportAPI from "../../helpers/api/Report";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import * as modalActions from "../../redux/actions/modalActions";
import PoolElement from "./PoolElement/PoolElement";
import ReportSelector from "../ReportSelector/ReportSelector";
import Headers from '../../helpers/Headers';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import * as Utils from "../../helpers/Utils";

const Pool = (props) => {
    let rows = [];
    const [state, setState] = useState({
        resources: false,
        currentReport: localStorage.getItem('poolId'),
        error: '',
        reRender: false,
    });
    const resourceInputRef = useRef();
    const spacerRef = useRef();
	
	const getResources = useCallback(() => {
        try {
            if (props.user.isLogin) {
                PoolAPI.getList(localStorage.getItem("poolId"))
                    .then(result => {
                        if (result.status === "error") {
                            toast.error(result.reason.message ? `${result.reason.message}` : `Не удалось получить список ресурсов`);
                            return;
                        }

                        setState(prevState => {
                            return { 
                                ...prevState,
                                resources: result.data.resource || "undefined",
                                reRender: !props.reRender
                            }
                        });
                    });
            }
        } catch (e) {
            console.error(e);
        }
    }, [props.reRender, props.user.isLogin]);
	
    useEffect(() => {
        document.title = Headers.pool;
    }, [])

    useEffect(() => {
        getResources();
    }, [getResources])

    useEffect(() => {
        getResources();
    }, [getResources])

    const removeResourceHandler = ({ currentTarget }) => {
        // send event to modal
        props.modal.showModal({
            type: 'delete',
            confirmHandler: PoolAPI.deleteResource,
            resource: {
                pool_id: localStorage.getItem("poolId"),
                resource: currentTarget.getAttribute("data-resource"),
                type: currentTarget.getAttribute("data-type")
            },
            callBack: () => getResources(),
        });
    }

    const moveResourceHandler = (obj) => {
        props.modal.showModal({
            type: 'move',
            resource: obj,
        });
    }

    const addResourceHandler = async (e, actionType, droppedData) => {
        e.preventDefault();

        try {
            let poolId = localStorage.getItem("poolId");

            // check & validate resource type
            let type = Utils.getResourceType(resourceInputRef.current.value || droppedData);

            if (!droppedData) {
                if (!resourceInputRef.current.value) {
                    setState({
                        ...state,
                        error: 'Поле не заполнено',
                    });
                    return;
                }
    
                if (!type) {
                    setState({
                        ...state,
                        error: 'Введен некорректный ресурс',
                    });
                    return;
                }
            }

            // remove http/https
            if (type === "domain") {
                let formatedDomain = resourceInputRef.current.value.replace(/https?:\/\/(www\.)?/, '');
                formatedDomain = formatedDomain.replace('/', '');
                resourceInputRef.current.value = formatedDomain;
            }

            const data = {
                "resource": resourceInputRef.current.value || droppedData,
                "name": resourceInputRef.current.value || droppedData,
            };

            switch (actionType) {
                case 'add':
                    const response = await PoolAPI.addResource(poolId, data);

                    if (response.status === "error") {
                        toast.error(`${response.reason.msg}`);
                        return;
                    }

                    toast.success(`Ресурс ${resourceInputRef.current.value} успешно добавлен`);
                    resourceInputRef.current.value = "";

                    getResources();
                break;
                case 'newPool':
                    const responseReport = await ReportAPI.create();

                    if (responseReport.status === "error") {
                        toast.error(`${responseReport.reason.msg}`);
                        return;
                    }

                    const responsePool = await PoolAPI.create(responseReport.data.report_id);

                    if (responsePool.status === "error") {
                        toast.error(`${responsePool.reason.msg}`);
                        return;
                    }

                    const responsePoolAdd = await PoolAPI.addResource(responsePool.data.poolid, data);

                    if (responsePoolAdd.status === "error") {
                        toast.error(`${responsePoolAdd.reason.msg}`);
                        return;
                    }

                    resourceInputRef.current.value = "";
                    setState({
                        ...state,
                        reRender: !state.reRender,
                    });
                break;
			default:
				break;
            }

        } catch (e) {
            toast.error("Не удалось добавить ресурс");
            console.error(`component:Pool, method: addResourceHandler, error: ${e}`);
        }
    }

    const reportChange = (reportId) => {
        setState({
            ...state,
            currentReport: reportId,
            reRender: false,
        });
    }

    const updateReportSelector = boolean => {
        setState({
            ...state,
            reRender: boolean,
        });
    };
	
    const subnetDragEnter = () => {
        spacerRef.current.classList.add('spacer--highlighted')
    }

    const subnetDragLeave = () => {
        spacerRef.current.classList.remove('spacer--highlighted');
    }

    const subnetDrop = event => {
        event.preventDefault();
        addResourceHandler(event, 'add', event.dataTransfer.getData('text'))
    }

    /*const removePool = event => {
        let poolId = localStorage.getItem("poolId");
        let reportId = localStorage.getItem("reportId");

        // send event to modal
        props.modal.showModal({
            type: 'deletePool',
            resource: {
                poolId: poolId,
                reportId: reportId,
                reportName: "test"
            },
            callBack: () => getResources(),
        });
    }*/

    if (typeof state.resources === "object" && state.resources.length) {
        state.resources.map((resource, index) => {
            rows.push(
                <PoolElement key={index} resource={resource} removeEvent={(event) => removeResourceHandler(event)} moveEvent={(obj) => moveResourceHandler(obj)} spacerRef={spacerRef} updateReportSelector={(boolean) => updateReportSelector(boolean)} />
            );
			return null;
        })
    }

    return (
        <Fragment>
            <div className="resource__title">
                <h1 className="main-title main-title--resource">Пул ресурсов <span
                    className="resource-counter">{rows.length > 0 && rows.length}</span>
                </h1>
                <ReportSelector
                    reRender={state.reRender}
                    editable={true}
                    onChangeCallback={value => reportChange(value)}
                    removePoolCallback={getResources}
                />
            </div>
            <section className="resource">
                {(!rows.length && state.resources ) && <span className="resource__empty">Пул ресурсов пустой. <a href="/" className="link resource__empty-link" onClick={(event) => {event.preventDefault(); resourceInputRef.current.focus()}}>Заполнить</a></span>}
                {rows.length > 0 && (
                    <Fragment>
                        <div className="resource__table-header">
                            <table className="resource__table">
                                <thead>
                                    <tr>
                                        <td>Название ресурса</td>
                                        <td>IPv4</td>
                                        <td>IPv6</td>
                                        <td>Комментарий</td>
                                    </tr>
                                </thead>
                            </table>
                            <div ref={spacerRef} 
                                    className="spacer"
                                    onDragEnter={subnetDragEnter}
                                    onDragLeave={subnetDragLeave}
                                    onDragOver={event => event.preventDefault()}
                                    onDrop={(event) => subnetDrop(event)}>Добавить ресурс в пул</div>
                        </div>
                        <SimpleBar style={{ maxHeight: 'calc(100% - 90px)', maxWidth: '820px' }}>
                            <table className="resource__table">
                                <tbody>
                                    {rows}
                                </tbody>
                            </table>
                        </SimpleBar>
                    </Fragment>
                )}
                <form className="resource__label">
                    <input ref={resourceInputRef} type="text" name="" id="" className="field resource__field"
                            placeholder="Введите список ресурсов" onChange={(event) => setState({ ...state, error: event.target.value && '' })}/>
                    <button className="button resource__button" onClick={event => addResourceHandler(event, 'add')}>Добавить</button>
                    <button className="button resource__button" onClick={event => addResourceHandler(event, 'newPool')}>Добавить в новый пул</button>
                </form>
                {state.error && <span className="error resource__error">{state.error}</span>}
            </section>
        </Fragment>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        modal: {
            showModal: (data) => dispatch(modalActions.showModal(data)),
            hideModal: () => dispatch(modalActions.hideModal())
        }
    }
};

const mapStateToProps = state => {
    return {
        user: state.user,
        auth: state.auth,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pool);