import React, {Fragment} from 'react';
import {observer} from "mobx-react-lite";
import CommonTable from "../../CommonTable/CommonTable";
import LabelWithSort from "./LabelWithSort";
import ColumnPrefixRenderer from "./ColumnPrefixRenderer";
import {buildGetCompareClass} from "../../../helpers/ReportUtils";
import {connect} from "react-redux";
import monitoringLastActive from '../../../redux/reducers/monitoringLastActive';
import ColumnTrashDesktop from './ColumnTrashDesktop';
import {setMonitoringLastActive} from '../../../redux/actions/monitoringLastActive'
import {useHistory} from "react-router-dom";
import {prefixEncode} from "../../../helpers/prefix";
import {map} from "./data";
import LocationIcon  from "../../UI/LocationIcon";

const MonitoringTableDesktop = observer((props) => {
    const {tableModel, onDeletePrefix, compareMode, monitoringLastActive} = props;
    const getCompareClass = buildGetCompareClass(compareMode, tableModel);
    const history = useHistory();

    const headerRenderers = [
        ({tableModel}) => (
            <div className="MonitoringTable__HeaderPrefix">
                <LabelWithSort label={(<b title="Префиксы, выбранные вами">Префикс</b>)} column={"prefix"} tableModel={tableModel}/>
                <LabelWithSort label={(<span className="MonitoringTable__HeaderPrefix__Info"
                                             title="Информация о префиксе (можно изменить)">Инфо</span>)}
                               column={"info"} tableModel={tableModel}/>
            </div>
        ),
        ({tableModel}) => (
            <div className="MonitoringTable__HeaderAs">
                <LabelWithSort label={(<b title="Автомномная система, содержащая префикс">AS</b>)} column={"as"} tableModel={tableModel}/>
            </div>
        ),
        ...((() => {

            return map.map(m => ({tableModel}) => (
                <div className="MonitoringTable__HeaderPlace">
                    <div className="MonitoringTable__HeaderPlace__SortContainer">
                        <div className="MonitoringTable__HeaderPlace__Icon"><LocationIcon type={m.column} /></div>
                        <LabelWithSort label={"min"} labelComment={m.comment_min} column={m.column + "_min"} tableModel={tableModel}/>
                        <LabelWithSort label={"avr"} labelComment={m.comment_avr} column={m.column + "_mid"} tableModel={tableModel}/>
                        <LabelWithSort label={"max"} labelComment={m.comment_max} column={m.column + "_max"} tableModel={tableModel}/>
                    </div>
                </div>
            ));
        })()),
        ({tableModel}) => <ColumnTrashDesktop tableModel={tableModel} onDeletePrefix={onDeletePrefix}/>
    ];

    const columnRenderers = [
        //префикс и комент
        ({row, tableModel}) => (
            <ColumnPrefixRenderer row={row} tableModel={tableModel}/>
        ),
        // as
        ({row}) => (row.pending_update) ? <div className="MonitoringTable__ColumnAs">{row["as"]}</div> : (
            <div className="MonitoringTable__ColumnAs"
                 title={"AS, содержащая "+row.prefix} onClick={() => {
                setMonitoringLastActive(row['prefix']);
                history.push('/prefix/' + prefixEncode(row['prefix']));
            }}>
                {row["as"]}
            </div>
        ),
        // плашки с цифрами
        ...((() => {
            return map.map(m => ({row}) => row.pending_update ?
                (
                <div className='MonitoringTable__ColumnPlace' title="Мы ищем ваши префиксы в базе данных. Кликните для обновления."
                    onClick={() => {
                        window.location.reload(false);
                    }}
                >
                    <div className='MonitoringTable__NumPlace'>...</div>
                    <div className='MonitoringTable__NumPlace'>...</div>
                    <div className='MonitoringTable__NumPlace'>...</div>
                </div>
                )
                    :
                (
                <div className={['MonitoringTable__ColumnPlace', getCompareClass(row, m.column, 'place')].join(' ')}
                     onClick={() => {
                         setMonitoringLastActive(row['prefix']);
                         history.push('/prefix/' + prefixEncode(row['prefix']));
                     }}
                    title={m.comment}>
                    <div
                        className={['MonitoringTable__NumPlace', row[m.column + "_min"] ? '' : 'second-text', getCompareClass(row, m.column, 'min')].join(' ')}>
                        {row[m.column + "_min"] ?? '-'}
                    </div>
                    <div
                        className={['MonitoringTable__NumPlace', row[m.column + "_mid"] ? '' : 'second-text', getCompareClass(row, m.column, 'mid')].join(' ')}>
                        {row[m.column + "_mid"] ?? '-'}
                    </div>
                    <div
                        className={['MonitoringTable__NumPlace', row[m.column + "_max"] ? '' : 'second-text', getCompareClass(row, m.column, 'max')].join(' ')}>
                        {row[m.column + "_max"] ?? '-'}
                    </div>
                </div>
            ));
        })()),
        ({row, tableModel}) => (
            <div className="MonitoringTable__ColumnTrash">
                <input type="checkbox" checked={tableModel.getVirtualProperty(row['id'])['remove']} onClick={(e) => {
                    tableModel.setVirtualProperty(row['id'],
					{remove: !tableModel.getVirtualProperty(row['id'])['remove']});
                }}/>
            </div>
        )
    ];

    return (
    <Fragment>
        <CommonTable
            className="MonitoringTable"
            tableModel={tableModel}
            headerRenderers={headerRenderers}
            columnRenderers={columnRenderers}
            appendClasses={[{
                key: 'prefix',
                value: monitoringLastActive,
                class: 'TdActivate'
            }]}
        />
    </Fragment>
    )
});

const mapStateToProps = (state) => {
    return {
        monitoringLastActive: state.monitoringLastActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMonitoringLastActive: (prefixName) => dispatch(monitoringLastActive(prefixName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringTableDesktop);
