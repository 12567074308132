import { GET_USER, RECEIVED_USER, LOGOUT_USER, NOT_AUTHORIZED, RECEIVED_ASN, SET_TMP_MODE } from "../constants";

export default (state = {}, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return state;
    case RECEIVED_USER:
      return {
        ...state,
        ...payload,
      };
    case LOGOUT_USER:
      return {
        asn: {
          ...state.asn,
        },
        ...payload,
      };
    case RECEIVED_ASN:
      return {
        ...state,
        asn: payload,
      };
    case NOT_AUTHORIZED:
      return {
        asn: state.asn ? { ...state.asn } : { ip: [''], autnums: [''] },
        ...payload,
      };
    case SET_TMP_MODE:
      return {
        ...state,
        tmpMode: payload
      };
    default:
      return state;
  }
};
