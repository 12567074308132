// BASE
//export const API_URL = 'https://daspath.ru';
// api - на том же сайте
export const API_URL = '';

// USER
export const GET_USER = 'GET_USER';
export const RECEIVED_USER = 'RECEIVED_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const NOT_AUTHORIZED = 'NOT_AUTHORIZED';
export const SET_TMP_MODE = 'SET_TMP_MODE';

// CAPTCHA
export const CAPTCHA_RECEIVED = 'CAPTCHA_RECEIVED';
export const CAPTCHA_NOT_FOUND = 'CAPTCHA_NOT_FOUND';
export const CAPTCHA_ERROR = 'CAPTCHA_ERROR';
export const RECAPTCHA_KEY = '6Leg3eMZAAAAAKXvx3-nQvykE8U1y_IYDfkG7YQ1';

// MODAL
export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';

// FILTER
export const FILTER_RECEIVED = 'FILTER_RECEIVED';
export const FILTER_UPDATED = 'FILTER_UPDATED';
export const FILTER_EMPTY = 'FILTER_EMPTY';

// IP TOGGLE
export const IPV_CHANGED = 'IPV_CHANGED';
export const IPV_GET = 'IPV_GET';

// VIEW POINTS
export const VIEW_POINTS_CHANGED = 'VIEW_POINTS_CHANGED';
export const VIEW_POINTS_SET = 'VIEW_POINTS_SET';

// ASN
export const RECEIVED_ASN = 'RECEIVED_ASN';

// REPORT
export const REPORT_SET = 'REPORT_SET';

// SWITCH
export const SWITCH_CHANGED = 'SWITCH_CHANGED';

// SET COMPARE DATE
export const SET_COMPARE_DATE = 'SET_COMPARE_DATE';

// SET PREFIX SORT
export const SET_PREFIX_COLUMN = 'SET_PREFIX_COLUMN';
export const SET_PREFIX_DIRECTION = 'SET_PREFIX_DIRECTION';
export const SHOW_SORT_PAGE = 'SHOW_SORT_PAGE';

//MONITORING
export const MONITORING_POSITION_SCROLL_Y = 'MONITORING_POSITION_SCROLL_Y';
export const SET_MONITORING_COLUMN = 'SET_MONITORING_COLUMN';
export const SET_MONITORING_DIRECTION = 'SET_MONITORING_DIRECTION';
export const MONITORING_LAST_ACTIVE = 'MONITORING_LAST_ACTIVE';
export const SET_TABLE_SIZE = 'SET_TABLE_SIZE';

//JUST REGISTER
export const JUST_REGISTER = 'JUST_REGISTER';