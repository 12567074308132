import {useWindowSize} from "../../customHooks/useWindowSize";


export const isSmallScreen = () => {

	// eslint-disable-next-line react-hooks/rules-of-hooks
	return useWindowSize().width < 880;
};

export const adaptiveWidth = () => {
		let prefixInfo = document.getElementsByClassName('prefix-info');
		if (prefixInfo[0] !== undefined) {
			if (prefixInfo[0].offsetWidth > 550) {
				 return {minWidth:  '460px'};
			} else {
			//	return {display: 'flex'};
			return;
			}
		}

		return {width: '100%'};
	};
	
	export default (isSmallScreen, adaptiveWidth);
