// Менюшка для зареганного пользователя при клике на логин

import React from "react";
import userIcon from "../../../img/user-icon.svg";
import { NavLink, useHistory } from "react-router-dom";
import * as UserAPI from "../../../helpers/api/User";
import * as userActions from "../../../redux/actions/userActions";
import Routes from '../../../helpers/Routes/Routes';
import ClickOutside from "../../../hoc/ClickOutside";
import { connect } from "react-redux";

const UserMenu = (props) => {
    const { username, visible, changeVisible, user, hideMenu } = props;
    const history = useHistory();

    const logout = async (event) => {
        event.preventDefault();
        await UserAPI.logout();
        localStorage.clear();
        document.cookie = '';
        document.location.href = Routes.first;
    };

    return (
        <ClickOutside handler={() => changeVisible(false)}>
            <button
                className="user-menu__button"
                onClick={() => changeVisible(!visible)}
            >
                <span className="user-icon">
            <svg width="16" height="12">
              <use xlinkHref={`${userIcon}#user-icon`} />
            </svg>
          </span>
                <span className="user-menu__button-text">{username}</span>
            </button>
            <ul className="user-menu__list">
                <li className="user-menu__item">
                    {(user.isLogin && user.tmpMode === false) ?
                        (<NavLink className="user-menu__link" to={Routes.userSettings} onClick={() => {changeVisible(!visible); hideMenu()}}>
                            Настройки профиля
                        </NavLink>) :
                        (<span className="user-menu__link menu__link--disabled">Настройки профиля</span>)
                    }

                </li>
                <li className="user-menu__item">
                    {(user.isLogin && user.tmpMode === false) ?
                        (<NavLink className="user-menu__link"  to={Routes.viewpoints}>Точки наблюдения</NavLink>) :
                        (<span className="user-menu__link menu__link--disabled">Точки наблюдения</span>)
                    }
                </li>
                <li className="user-menu__item">
                    <a
                        href=""
                        className="user-menu__link"
                        onClick={(user.isLogin && user.tmpMode === false) ? logout : () => {
                            history.push('/entry');
                        }}
                    >
                        {(user.isLogin && user.tmpMode === false) ? "Выход" : "Вход"}
                    </a>
                </li>
            </ul>
        </ClickOutside>
    );
};

const mapStateToProps = state => {
    return {
        user: state.user,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        logoutState: (data) => dispatch(userActions.logoutUser(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserMenu);
