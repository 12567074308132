import React, {useState} from "react";
import {IconPen, IconCheck, IconCross} from './Icons.js';

const Commentary = ({ text, onSave }) => {
    const [ state, setState ] = useState({
        select: false,
        edit: false,
        text
    });

    const onSubmit = () => {
        onSave && onSave(state.text);
        setState({ ...state, edit: false });
    };

    if(state.edit) {
       return <div className="ResourceCommentary">
            <input type="text"
                   className="field"
                   value={state.text}
                   maxLength={32}
                   onKeyPress={(e) => {
                       if(e.key === 'Enter') {
                           onSubmit();
                       }
                   }}
                   onBlur={() => {
                       setTimeout(() => setState({ ...state, edit: false }), 256);
                   }}
                   onChange={(e) => setState({ ...state, text: e.target.value })}
                   onMouseOver={(e) => {
                       e.target.focus();
                       if(state.select) {
                           e.target.select();
                           setState({ ...state, select: false });
                       }
                   }}/>
            <div onClick={() => onSubmit()}>
                <IconCheck className="svg-icon"/>
            </div>
           <div onClick={() => setState({ ...state, edit: false })}>
               <IconCross className="svg-icon"/>
           </div>

        </div>;
    } else {
        return <span className="ResourceCommentary" onClick={() => setState({
            ...state,
            text,
            edit: true,
            select: true
        })}>
            <span className={"resource-commentary__text"}>{text}</span>
            <IconPen className="svg-icon"/>
        </span>;
    }
};

export default Commentary;
