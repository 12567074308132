// Компонент модального окна

import React, { Fragment } from 'react';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import * as modalActions from "../../redux/actions/modalActions";
import * as Utils from '../../helpers/Utils';
import * as PoolAPI from '../../helpers/api/Pool';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import Routes from '../../helpers/Routes/Routes';
import { withRouter } from 'react-router-dom';
import MultipleSelectPrefixes from "../MultipleSelectPrefixes/MultipleSelectPrefixes";

class Modal extends React.Component {
    constructor(props) {
        super(props);

        this.deleteHandler = this.deleteHandler.bind(this);
        this.deletePoolHandler = this.deletePoolHandler.bind(this);
        this.addResourceToPool = this.addResourceToPool.bind(this);
        this.moveResourceToOtherPool = this.moveResourceToOtherPool.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
    }

    deleteHandler() {
        this.props.modal.deleteSource(
            this.props.resource.pool_id,
            {
                "resource": this.props.resource.resource,
                "type": this.props.resource.type
            }
        )
            .then(() => {
                this.props.callBack();
            });
    }

    async deleteHandlerMany(resources) {
        for(const resource of resources) {
            await this.props.modal.deleteSource(
                resource.pool_id,
                {
                    "resource": resource.resource,
                    "type": resource.type
                }
            );
        }
        this.props.callBack();
    }

    deletePoolHandler(resource) {
        this.props.modal.deletePool(resource.poolId, resource.reportId, resource.reportName)
            .then(() => {
                this.props.callBack();
            });
    }

    async addResourceToPool() {
        try {
            let resource = this.props.resource.value;
            const resourceType = Utils.getResourceType(this.props.resource.value);
    
            if (resourceType === 'domain') 
                resource = resource.replace(/https?:\/\/(www\.)?/, '');
                resource = resource.replace('/', '');
        
            const data = {
                resource: resource,
                name: resource,
            };

            const response = await PoolAPI.addResource(+localStorage.getItem('poolId'), data);

            if (response.status === "error") {
                toast.error(`${response.reason.msg}`);
                return;
            }

            toast.success(`Ресурс ${resource} успешно добавлен.`);
            this.props.modal.hideModal();
            this.props.history.push(Routes.report);
        } catch (e) {
            toast.error('Не удалось добавить ресурс.');
        }
    }

    async moveResourceToOtherPool() {
        const { resource, type, currentReport, updateSelector } = this.props.resource;
        const storagePoolId = +localStorage.getItem('poolId');
        const deleteData = { resource, type };
        const addData = { resource, name: resource };

        try {
            const responseDelele = await PoolAPI.deleteResource(currentReport.poolId, deleteData);

            if (responseDelele.status === "error") {
                toast.error('При перемещении ресурса произошла ошибка.');
                return;
            }

            const responseAdd = await PoolAPI.addResource(storagePoolId, addData);

            if (responseAdd.status === "error") {
                toast.error('При перемещении ресурса произошла ошибка.');
                return;
            }
         
            updateSelector(true);
            this.props.modal.hideModal();
            toast.success(`Ресурс ${resource} успешно перемещен в Отчет №${localStorage.getItem('reportId')}.`);
        } catch (e) {
            toast.error('При перемещении ресурса произошла ошибка.');
        }
    }

    closeModal() {
        const { currentReport } = this.props.resource;

        localStorage.setItem('reportId', currentReport.reportId);
        localStorage.setItem('poolId', currentReport.poolId)

        this.props.modal.hideModal();
    }

    async deleteUser() { 
        try {
            const [ deleteUser, getUser ] = this.props.callBack;
            const { status, reason } = await deleteUser();

            if (status === "error") {
                throw new Error(reason.msg);
            }

            this.props.modal.hideModal();
            getUser();
            this.props.history.push(Routes.index);

        } catch (err) {
            throw new Error(err);
        }
    }

    render() {
        const { active, resource = '', modalType } = this.props;
        let modalFragment = null, title = '', titleResource = '';

        // Дабы не плодить компонент модалки, делаем через switch.
        // Прокидываем modalType в action, отвечающий за раскрытие.
        // В modalFragment формируем контент модалки.
        switch (modalType) {
            case 'delete':
                title = 'Хотите удалить префикс?';
                modalFragment = <Fragment>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button" onClick={() => this.deleteHandler(this.props.resource)}>Удалить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.props.modal.hideModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
            case 'delete_many':
                title = 'Хотите удалить выбранные префиксы?';
                modalFragment = <Fragment>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button" onClick={() => this.deleteHandlerMany(this.props.resource)}>Удалить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.props.modal.hideModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
            case 'add':
                modalFragment = <Fragment>
                    <h3 className="modal__title">Добавление ресурса</h3>
                    <p className="modal__text">Выберите имя пула ресурсов.</p>
                    <div className="modal__selector">
                        <MultipleSelect />
                    </div>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button"
                                title="Добавить префикс в список для мониторинга."
                                onClick={this.addResourceToPool}>Добавить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.closeModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
            case 'add_v2':
                title = 'Ресурс';
                titleResource = resource.value;
                modalFragment = <MultipleSelectPrefixes resource={resource.value} doClose={(withoutCallback) => {
                    this.props.modal.hideModal();
                    if(!withoutCallback) {
                        this.props.callBack && this.props.callBack();
                    }
                }}/>
            break;
            case 'incorrect_resource':
                title = 'Некорректный ресурс';
                modalFragment = <div>
                    <p>Проверьте правильность введенных данных</p>
                    <button className="button button--cancel button--white modal__button" onClick={() => {
                        this.props.callBack && this.props.callBack();
                        this.props.modal.hideModal();
                    }}>Ок</button>
                </div>;
            break;
            case 'move':
                modalFragment = <Fragment>
                    <h3 className="modal__title">Перемещение ресурса</h3>
                    <p className="modal__text">Выберите имя пула ресурсов.</p>
                    <div className="modal__selector">
                        <MultipleSelect />
                    </div>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button" onClick={this.moveResourceToOtherPool}>Переместить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.closeModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
            case 'deletePool':
                modalFragment = <Fragment>
                    <h3 className="modal__title">Удаление пула</h3>
                    <p className="modal__text">Вы действительно хотите удалить пул "{this.props.resource.reportName}"?</p>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button" onClick={this.deletePoolHandler}>Удалить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.props.modal.hideModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
            case 'deleteUser':
                modalFragment = <Fragment>
                    <h3 className="modal__title">Удаление пользователя</h3>
                    <p className="modal__text">Вы действительно хотите удалить пользователя "{this.props.resource.user}"?</p>
                    <div className="modal__buttons">
                        <button className="button button--apply modal__button" onClick={this.deleteUser}>Удалить</button>
                        <button className="button button--cancel button--white modal__button" onClick={this.props.modal.hideModal}>Отмена</button>
                    </div>
                </Fragment>
            break;
		default: 
			break;
        }

        return (
            <div className="modal-container">
                <div className={`modal ${active ? "modal--visible" : ""}`}>
                    <div className="Modal__TitleBar">
                        <div className="Modal__TitleBar__Title">
                            {title}
                        </div>
                        <div>{titleResource}</div>
                        <svg className="Modal__TitleBar__Close" onClick={this.props.modal.hideModal} width="24" height="24" viewBox="-3 -3 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.2 8.64605L15.354 7.80005L12 11.154L8.64605 7.80005L7.80005 8.64605L11.154 12L7.80005 15.3541L8.64605 16.2001L12 12.846L15.354 16.2001L16.2 15.3541L12.846 12L16.2 8.64605Z" fill="#223354"/>
                            <path d="M12 23C5.92487 23 1 18.0751 1 12H-1C-1 19.1797 4.8203 25 12 25V23ZM23 12C23 18.0751 18.0751 23 12 23V25C19.1797 25 25 19.1797 25 12H23ZM12 1C18.0751 1 23 5.92487 23 12H25C25 4.8203 19.1797 -1 12 -1V1ZM12 -1C4.8203 -1 -1 4.8203 -1 12H1C1 5.92487 5.92487 1 12 1V-1Z" fill="#223354"/>
                        </svg>

                    </div>
                    {modalFragment}
                </div>
                <div onClick={this.props.modal.hideModal} className={`modal-overlay ${active ? "modal-overlay--visible" : ""}`}></div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        modal: {
            deleteSource: (poolId, resource) => dispatch(modalActions.deleteSource(poolId, resource)),
            deletePool: (poolId, reportId, reportName) => dispatch(modalActions.deletePool(poolId, reportId, reportName)),
            hideModal: () => dispatch(modalActions.hideModal())
        }
    }
};

export default connect(null, mapDispatchToProps)(withRouter(Modal));
