// Контейнер для точек наблюдения и ресурсов

import React, {Fragment} from "react";
import ViewPointsGroup from "../ViewPointsGroup/ViewPointsGroup";
import ResourcesGroup from "../ResourcesGroup/ResourcesGroup";
import * as filterActions from "../../../redux/actions/filterActions";
import {connect} from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars';

class FilterContainer extends React.Component {
    constructor(props) {
        super(props);
        this.bindAllToReport = this.bindAllToReport.bind(this);
    }

    bindAllToReport(e) {
        let filterData = JSON.parse(JSON.stringify(this.props.filterData)),
            type = e.currentTarget.getAttribute("data-type"),
            filterKeys = ["resources", "resourcesCompare"];

        switch (type) {
            case "resources":
                filterKeys.forEach(key => {
                    if (!filterData.hasOwnProperty(key)) return;

                    Object.keys(filterData[key]).forEach(resource => {
                        if (!filterData[key].hasOwnProperty(resource)) return;
                        filterData[key][resource].checked = !filterData[key][resource].checked;

                        Object.keys(filterData[key][resource].prefixes).forEach(prefix => {
                            if (!filterData[key][resource].prefixes.hasOwnProperty(prefix)) return;
                            filterData[key][resource].prefixes[prefix].checked = !filterData[key][resource].prefixes[prefix].checked;
                        })
                    })
                })

                this.props.changeAllExpandersState('allResourcesChecker', !this.props.allResourcesChecker);
            break;

            case "viewPoints":
                filterKeys.forEach(key => {
                    if (!filterData.hasOwnProperty(key)) return;

                    Object.keys(filterData[key]).forEach(resource => {
                        if (!filterData[key].hasOwnProperty(resource)) return;

                        Object.keys(filterData[key][resource].prefixes).forEach(prefix => {
                            if (!filterData[key][resource].prefixes.hasOwnProperty(prefix)) return;

                            Object.keys(filterData[key][resource].prefixes[prefix].views).forEach(view => {
                                if (!filterData[key][resource].prefixes[prefix].views.hasOwnProperty(view)) return;
                                filterData[key][resource].prefixes[prefix].views[view].checked = !filterData[key][resource].prefixes[prefix].views[view].checked;
                            })
                        })
                    })
                })

                Object.keys(filterData.viewPoints).forEach(type => {
                    filterData.viewPoints[type].checked = !filterData.viewPoints[type].checked;
                    Object.keys(filterData.viewPoints[type].items).forEach(view => {
                        filterData.viewPoints[type].items[view].checked = !filterData.viewPoints[type].items[view].checked;
                    })
                })

                this.props.changeAllExpandersState('allViewPointsChecker', !this.props.allViewPointsChecker);
            break;
            default:
                break;
        }

        this.props.updateFilter(filterData);
    }

    getInputs() {
        if (typeof this.props.data === "undefined" || !Object.keys(this.props.data).length)
            return [];

        let inputs = [];
        let checkedRes = undefined, mixedRes = 'normal', checkedVP = undefined, mixedVP = 'normal';

        switch (this.props.type) {
            case "resources":
                let data = this.props.data.resources;

                if (this.props.data.isCompare && this.props.data.hasOwnProperty("resourcesCompare"))
                    data = {...this.props.data.resources, ...this.props.data.resourcesCompare};

                if (typeof data === "undefined")
                    return inputs;

                Object.keys(data).map((resource, key) => {
                    // Перебор тут, потому что любое клонирование не вписывается в лимиты по памяти
                    if (checkedRes === undefined) {
                        checkedRes = data[resource].checked;
                    } else if (checkedRes !== data[resource].checked) {
                        mixedRes = 'grey';
                    }
                    Object.keys(data[resource].prefixes).forEach((value, index) => {
                       if (data[resource].prefixes[value].checked !== checkedRes){
                           mixedRes = 'grey';
                       }
                    });
                    inputs.push(
                        <ResourcesGroup key={key} filterData={this.props.filterData} name={data[resource].name} resource={data[resource]} />
                    );
                });
                localStorage.allResourcesCheckerResources = mixedRes;
                break;

            case "viewPoints":
                Object.keys(this.props.data).map((group, key) => {inputs.push(
                        <ViewPointsGroup key={key} filterData={this.props.filterData} name={this.props.data[group].name} viewPoints={this.props.data[group]} group={group} />
                    )
                });
                Object.keys(inputs).forEach((value, index)=> {
                    if (checkedVP === undefined) {
                        checkedVP = inputs[index].props.viewPoints.checked;
                    } else if (checkedVP !== inputs[index].props.viewPoints.checked) {
                        mixedVP = 'grey';
                    }
                    if (mixedVP !== 'grey') {
                        Object.keys(inputs[index].props.viewPoints.items).forEach((value1, index1) => {
                        //    console.log(inputs[index].props.viewPoints.items[value1], index1, checkedVP);
                           if (inputs[index].props.viewPoints.items[value1].checked !== checkedVP){
                               mixedVP = 'grey';
                           }
                        });
                    }
                });
                localStorage.allResourcesCheckerVP = mixedVP;
                break;
        }

        return inputs;
    }

    render() {
        const inputs = this.getInputs();

        return (
            <Fragment>
                {inputs && inputs.length > 0 &&
                    <div className="filter__container">
                        <div className="filter__header">
                            <h2 className="filter__title">{this.props.name}</h2>
                            {this.props.type === "resources" && (
                                <a onClick={this.bindAllToReport} data-type={this.props.type}
                                   className={localStorage.allResourcesCheckerResources === 'grey'
                                       ? "link filter__header-link color-grey"
                                       : "link filter__header-link"}>
                                    {!this.props.allResourcesChecker ? 'Выбрать все' : 'Сбросить все'}
                                </a>
                            )}
                            {this.props.type === "viewPoints" && (
                                <a onClick={this.bindAllToReport} data-type={this.props.type}
                                   className={localStorage.allResourcesCheckerVP === 'grey'
                                       ? "link filter__header-link color-grey"
                                       : "link filter__header-link"}>
                                    {!this.props.allViewPointsChecker ? 'Выбрать все' : 'Сбросить все'}
                                </a>
                            )}
                        </div>
                        <Scrollbars className="custom-scrollbars" autoHeight autoHeightMax={'calc(50vh - 90px)'} >
                            <ul className="filter__list">
                                {inputs}
                            </ul>
                        </Scrollbars>
                    </div>
                }
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (data) => dispatch(filterActions.updateFilter(data))
    };
};

export default connect(null, mapDispatchToProps)(FilterContainer);