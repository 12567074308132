import React from 'react';
import { observer } from "mobx-react-lite";
import CommonTable from "../../CommonTable/CommonTable";
import LabelWithSortMobile from "./LabelWithSortMobile";
//import { localSVGIcon, regionSVGIcon, globalSVGIcon } from "./Icons1";
import ColumnBodyMobile from "./ColumnBodyMobile";
import {buildGetCompareClass} from "../../../helpers/ReportUtils";
import monitoringLastActive from '../../../redux/reducers/monitoringLastActive';
import {connect} from "react-redux";

const MonitoringTableMobile = observer(({ tableModel, onDeletePrefix, compareMode, monitoringLastActive, inputValue, inputOnChange, buttonClick }) => {
    const getCompareClass = buildGetCompareClass(compareMode, tableModel);

    return (
        <React.Fragment>
            <CommonTable
                className="MonitoringTableMobile"
                tableModel={tableModel}
                headerRenderers={[({ tableModel }) => (
                    <div className="MonitoringTableMobile__Header__Container">
                        <div className="MonitoringTableMobile__Header">
                            {[
                                ["prefix", "Префикс"],
                                ["info", "Инфо"],
								["as", 'AS'],
/*
                                ["global_min", "min", globalSVGIcon],
                                ["global_mid", "mid", globalSVGIcon],
                                ["global_max", "max", globalSVGIcon],

                                ["region_min", "min", regionSVGIcon],
                                ["region_mid", "mid", regionSVGIcon],
                                ["region_max", "max", regionSVGIcon],

                                ["local_min", "min", localSVGIcon],
                                ["local_mid", "mid", localSVGIcon],
                                ["local_max", "max", localSVGIcon]*/
                            ].map((m, i) => <LabelWithSortMobile
                                            key={m[0]}
                                            tableModel={tableModel}
                                            label={m[1]}
                                            column={m[0]}
                                            icon={null}/>)}
                        </div>
                        <div className="MonitoringTableMobile__HeaderTrashColumn">
                            <input type="checkbox" className="MonitoringCheckbox" onClick={(e) => {
                                for(let row of tableModel.elements) {
                                    tableModel.setVirtualProperty(row['id'], {remove: e.target.checked});
                                }
                            }}/>
                        </div>
                    </div>
                )]}
                columnRenderers={[
                    ({row, tableModel}) => <ColumnBodyMobile row={row}
                                                             tableModel={tableModel}
                                                             getCompareClass={getCompareClass}/>
                ]}
                appendClasses={[{
                    key: 'prefix',
                    value: monitoringLastActive,
                    class: 'TdActivate'
                }]}
                disableSpaceTd={true}
            />
        </React.Fragment>
    );
});

const mapStateToProps = (state) => {
    return {
        monitoringLastActive: state.monitoringLastActive,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setMonitoringLastActive: (prefixName) => dispatch(monitoringLastActive(prefixName)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MonitoringTableMobile);
