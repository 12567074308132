import { toast } from "react-toastify";
import { API_URL } from "../../redux/constants";
import ajaxCache from "./AjaxCache";

export const generateData = async (resource) => {
    try {
        const response = await ajaxCache.post(
            `${API_URL}/api/info/get_prefixes/`,
            new URLSearchParams({ resource }),
            {
                withCredentials: true
            }
        );

        return response.data;
    } catch (e) {
        return toast.error(`${e}`);
    }
};

export const getData = async (request_id) => {
    try {
        const response = await ajaxCache.get(
            `${API_URL}/api/info/get_prefixes/?request_id=${request_id}`,
            {
                withCredentials: true
            }
        );

        return response.data;
    } catch (e) {
        return toast.error(`${e}`);
    }
};