import React from 'react';
import { observer } from "mobx-react-lite";
import LocationIcon from "../../UI/LocationIcon";

const LabelWithSortMobile = observer(({ label, column, tableModel, icon }) => {
    const isActive = tableModel.getSortBy() === column;
    const isAsc = tableModel.getSortOrder() === 'asc';

    return (
        <div className="MonitoringTableMobile__LabelWithSort">
            <span onClick={() => {
               /* if(isActive && !isAsc) tableModel.resetSort();
                else */
                if(isActive) tableModel.setSortOrder(isAsc ? "desc" : "asc");
                else {
                    tableModel.setSortOrder("asc");
                    tableModel.setSortBy(column);
                }
            }} className="MonitoringTableMobile__LabelWithSort__Title">
                {<div className="MonitoringTableMobile__LabelWithSort__Icon">

                </div>}
                {label}
            </span>
            <div className="MonitoringTable__LabelWithSort__ArrowsContainer">
                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={() => {
                         tableModel.setSortBy(column);
                         tableModel.setSortOrder('asc');
                     }}>
                    <path d="M0 5L5 0L10 5H0Z" fill={(isActive && isAsc) ? '#1A75FF' : '#ABB0BA'}/>
                </svg>

                <svg width="10" height="5" viewBox="0 0 10 5" fill="none" xmlns="http://www.w3.org/2000/svg"
                     onClick={() => {
                         tableModel.setSortBy(column);
                         tableModel.setSortOrder('desc');
                     }}>
                    <path d="M0 5L5 0L10 5H0Z" fill={(isActive && !isAsc) ? '#1A75FF' : '#ABB0BA'}/>
                </svg>
            </div>
        </div>
    );
});

export default LabelWithSortMobile;
