// Радарный график

import React, { Fragment } from "react";
import Chart from "react-apexcharts";
import { renderToString } from 'react-dom/server';

const RadarChart = (props) => {
    let
        avgKeys = [
            typeof props?.data?.asAvgData !== "undefined" ? Object.keys(props.data.asAvgData) : [],
            typeof props?.data?.asAvgCompareData !== "undefined" ? Object.keys(props.data.asAvgCompareData) : []
        ],
        asAvgData = [
            typeof props?.data?.asAvgData !== "undefined" ? props.data.asAvgData : {},
            typeof props?.data?.asAvgCompareData !== "undefined" ? props.data.asAvgCompareData : {}
        ];

    if (!props.data.categories || !props.data.series || !props.data.categories.length || !Object.keys(props.data.series).length)
        return false;

    const {categories, series, colors} = props.data;

    // находим максимальное значение среди точек для определения верхней границы графика
    let allPoints = (typeof series?.[0]?.data === "undefined" ? [] : series[0].data)
        .concat(typeof series?.[1]?.data === "undefined" ? [] : series[1].data);
    let max = Math.ceil(Math.max(...allPoints));
    // 3 - минимальное max значение, т.к. в радаре минимум 3 кольца
    max = max > 3 ? max : 3;

    let options = {
        avgKeys: avgKeys,
        asAvgData: asAvgData,
        chart: {
            type: "radar",
            dropShadow: {
                enabled: false,
                blur: 1,
                left: 1,
                top: 1,
            },
            toolbar: {
                show: false
            }
        },
        stroke: {
            width: 2,
        },
        xaxis: {
            categories: categories,
            labels: {
                style: {
                    colors: colors
                }
            }
        },
        yaxis: {
            decimalsInFloat: 0,
            forceNiceScale: true,
            min: 0,
            max: max,
        },
        markers: {
            size: 4,
        },
        legend: {
            offsetY: -35,
            showForSingleSeries: true
        },
        tooltip: {
            custom: function({ series, seriesIndex, dataPointIndex, w }) {
                let tooltip = [];

                if (typeof avgKeys?.[seriesIndex]?.[dataPointIndex] !== "undefined") {
                    Object.keys(asAvgData[seriesIndex][avgKeys[seriesIndex][dataPointIndex]])
                        .forEach((el, index) => {
                            if (typeof asAvgData?.[seriesIndex]?.[avgKeys[seriesIndex][dataPointIndex]]?.[el] === "undefined"
                                || asAvgData[seriesIndex][avgKeys[[seriesIndex]][dataPointIndex]][el] === 0)
                                return false;

                            tooltip.push(
                                <div className="RadarChartData" key={index}>{el}: {asAvgData[seriesIndex][avgKeys[seriesIndex][dataPointIndex]][el]}</div>
                            );
                        })

                    if (!tooltip.length)
                        tooltip = <div className="RadarChartTitle">Нет данных</div>;
                }

                return renderToString(
                    <div className="RadarChartTooltip">
                        <div className="RadarChartTitle">{avgKeys[seriesIndex][dataPointIndex]} ({props.data.categories[dataPointIndex]})</div>
                        {tooltip}
                    </div>
                )
            }
        }
    };

    return (
        <Fragment>
            <span className="IPvType">IPv{props.ipv}</span>
            <Chart options={options} series={series} type={options.chart.type} height={'100%'} width={'100%'} />
        </Fragment>
    );
};

export default RadarChart;
