export const getPrefix = (prefixData) => {
    const prefix = {};
    let x;
    if (!prefixData && localStorage.swapPrefix) {
        x = JSON.parse(localStorage.swapPrefix);
    } else if(prefixData) {
        x = prefixData;
    }
    //console.log(x);
    if(x) {
        prefix.name = x.prefix ?? x.name;
        prefix.comment = x.info;
        prefix.as = isNaN(x.as) ? x.as : 'AS' + x.as;
        prefix.global = [
            x.global.min, x.global.mid, x.global.max,
        ];
        prefix.local = [
            x.ix.min, x.ix.mid, x.ix.max,
        ];
        prefix.regional = [
            x.regional.min, x.regional.mid, x.regional.max,
        ];
        prefix.global.forEach((item, index) => {
            prefix.global[index] = prefix.global[index] ? prefix.global[index] : '-';
        });
        prefix.local.forEach((item, index) => {
            prefix.local[index] = prefix.local[index] ? prefix.local[index] : '-';
        });
        prefix.regional.forEach((item, index) => {
            prefix.regional[index] = prefix.regional[index] ? prefix.regional[index] : '-';
        });
    }

    return prefix;
}
