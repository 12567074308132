import {SET_MONITORING_DIRECTION} from '../constants';
import { sortDirections } from '../../components/Prefix/sort';

export default (state = {}, action) => {
    const { type, payload} = action;
    if (type === SET_MONITORING_DIRECTION) {
        let direction = (isNaN(state)?  0 : state);

        return localStorage.monitoringDirection = ((payload +  direction) % sortDirections.length);
    }

    return +localStorage.monitoringDirection;
};
