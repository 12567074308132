import {SET_PREFIX_DIRECTION} from '../constants';
import { sortDirections } from '../../components/Prefix/sort';

export default (state = {}, action) => {
    const { type, payload} = action;
    if (type === SET_PREFIX_DIRECTION) {
        let direction = (isNaN(state)?  0 : state);

		return localStorage.prefixDirection = ((payload +  direction) % sortDirections.length);
    }

	return +localStorage.prefixDirection;
};
