import React from "react";
import {AscIcon, DescIcon, UndefinedIcon} from "./DirectionIcon";
import {connect} from "react-redux";
import { sortDirections } from '../../components/Prefix/sort';

const PrefixSortColumn = props => {
	let icon;
	if (props.type === props.sortableColumn) {
		switch (sortDirections[props.direction]) {
			case 'asc':
				icon = <AscIcon style={{marginLeft: '10px'}} />;
				break;
			case 'desc':
				icon = <DescIcon style={{marginLeft: '10px'}} />;
				break;
			default:
				icon = <UndefinedIcon style={{marginLeft: '10px'}} />;
		}
	} else {
		icon = <UndefinedIcon style={{marginLeft: '10px'}}/>;
	}
	return (
			<div className={'Prefix__' + props.type} style={{
							display: 'inline-block',
							cursor: 'pointer',
							width: props.width ,
							textAlign: props.align ? props.align : 'left',
							 }}
							title={props.text_comment}>
				{props.text}
				{icon}
			</div>

			);
}

/**
 * maps передаёт свойство в props
 **/
function mapStateToProps(state) {
	return {
		sortableColumn: state.prefixColumn,
		direction: state.prefixDirection,
		showSortPage: state.showSortPage,
	}
}

/**
 * отправка maps в props
 **/
function mapDispatchToProps(dispatch) {
	return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(PrefixSortColumn);
