// Компонент таблицы точки наблюдения (строка)

import React from "react";
import * as Views from "../../../../helpers/api/Views";
import {toast} from "react-toastify";
import * as Utils from "../../../../helpers/Utils";
import * as viewPointsActions from "../../../../redux/actions/viewPointsActions";
import {connect} from "react-redux";

class ViewPointsRow extends React.Component {
    constructor(props) {
        super(props);

        this.bindToReport = this.bindToReport.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState) {
        return JSON.stringify(nextProps.view) !== JSON.stringify(this.props.view);
    }

    bindToReport(e) {
        const target = e.currentTarget,
            isChecked = target.checked,
            action = isChecked ? "addView" : "removeView",
            currentView = {
                view: target.getAttribute("data-view"),
                type: target.getAttribute("data-type"),
                asname: target.getAttribute("data-asname"),
                af: target.getAttribute("data-af")
            };

        try {
            Views[action](localStorage.getItem("reportId"), currentView)
                .then(result => {
                        if (!Utils.checkResponse(result))
                            return;

                        // обновим глобальный state
                        let allViewpoints = JSON.parse(JSON.stringify(this.props.allViewPoints));
                        allViewpoints[this.props.view.af].groups[this.props.view.type].items[currentView.view].checked = isChecked;

                        this.props.updateViewPoints(allViewpoints);
                    }
                );

        } catch (e) {
            target.checked = !target.checked;
            toast.error(`${this.state.bindError}`);
        }
    }

    render() {
        const view = this.props.view;
        return (
            <tr>
                <td>
                    <label className="observation-points__label">
                        <input
                            type="checkbox"
                            className="visually-hidden"
                            data-view={view.view}
                            data-type={view.type}
                            data-asname={view.asname}
                            data-af={view.af}
                            onChange={this.bindToReport}
                            checked={this.props.isAuthorized ? view.checked : view.default}
                            disabled={this.props.isAuthorized ? view.disabled : true}
                        />
                        <span className="checkbox"></span>
                    </label>
                </td>
                <td>{view.name}</td>
                <td>{view.view}</td>
            </tr>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateViewPoints: (data) => dispatch(viewPointsActions.updateViewPoints(data))
    };
};

export default connect(null, mapDispatchToProps)(ViewPointsRow);