// Компонент для смены версий ресурсов (IPv4, IPv6)

import React from "react";
import * as ipvActions from "../../redux/actions/ipvActions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

class IpToggle extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }

    onChangeHandler(e) {
        let ipv = e.currentTarget.checked ? "6" : "4";
        this.props.setIPV({
            component: this.props.component,
            value: ipv
        });
    }

    render() {
        return (
            <label className="report-detail__switch-container">
                <span className="report-detail__switch-text">IPv4</span>
                <input type="checkbox"
                       checked={Number(this.props.ipv[this.props.component]) === 6}
                       onChange={this.onChangeHandler}
                       className="visually-hidden"
                />
                <span className="switch report-detail__switch"></span>
                <span className="report-detail__switch-text">IPv6</span>
            </label>
        );
    }
}

const mapStateToProps = state => {
    return {
        ipv: state.ipv
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIPV: (data) => dispatch(ipvActions.IpvChanged(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IpToggle));