import React, {Fragment, useState, useEffect} from "react";
import {batch, connect} from "react-redux";
//import DatePickerCustom from "../UI/DatePicker";
import PrefixInfo from '../UI/PrefixInfo';
import PrefixSort from '../UI/PrefixSort'
//import {observer} from "mobx-react-lite"
//import * as PoolAPI from "../../helpers/api/Pool";
import {compare} from './sort';
import "../../styles/prefix.css";
import {useHistory, useParams} from "react-router-dom";
import {registerLocale} from "react-datepicker";
import ru from 'date-fns/locale/ru';
//import {data, prefix} from "./data";
import columns from "./columns.js";
//import adaptiveWidth from '../UI/ui-helper';
import PrefixDataGridRow from '../UI/PrefixDataGridRow/PrefixDataGridRow';
//import * as switchActions from "../../redux/actions/switchActions";
import showSortPageActions from "../../redux/actions/showSortPageActions";
import {isSmallScreen} from "../UI/ui-helper";
import H1 from "../UI/H1";
import {setPrefixColumn, setPrefixDirection} from "../../redux/actions/prefixSortActions";
import * as ReportAPI from "../../helpers/api/Report";
import {getPrefix} from "./getPrefix";
//import getPrefixData from "./getPrefixData";
import {parsePrefixData} from "./parsePrefixData";
import getPrefixType from "./getPrefixType";
//import setData from "../Monitoring/setData";
//import MonitoringModel from "../Monitoring/MonitoringTable/MonitoringModel";
//import getReportDates from "../../helpers/ApiHelpers/getReportDates";
import {datePickerSetupIncludeDates, getDatePickerStartDate, handleDatePicker} from "../../helpers/DatePickerHelper";
import {fetchPrefixes} from "../../helpers/ReportUtils";
import addDateData from "./addDateData";
//import {PrefixSortPage} from "../Sort/PrefixSortPage";
import resize from './resize';
import Loader from "../Loader/Loader";
import Routes from '../../helpers/Routes/Routes';
import {prefixDecode} from '../../helpers/prefix'

registerLocale('ru', ru);
window.React = require('react');

const Prefix = (props) => {
	const {sortableColumn, direction, setPrefixColumn, setDirectionColumn, compareDate, checked} = props;
	const {user} = props;
	const [prefix, setPrefix] = useState(null);
	const [reportDatesState, setReportDatesState] = useState(null);
	const [compareModeState, setCompareModeState] = useState(false);
	const history = useHistory();
	const base32 = useParams().prefixName;

	const [state, setState] = useState({
		prefix: prefix,
		bgpData: {},
		bgpDataCompare: {},
		data: [],
		views: {},
		viewsCompare:{},
		dates: [],
		loaded: false,
		needRerender:true,
		loadingCompareDate: false,
		needAddDateData: false,
		startDate:  getDatePickerStartDate(compareDate, reportDatesState),
	});
	let prefixName;
	try {
		prefixName = prefixDecode(base32);
	} catch {
		history.push(Routes.first);
	}

	useEffect(() => {
		if(prefix !== null && prefix.name === prefixName) {
			resize();
		}
	});

	const smallSize = props.smallHook;

	let ip = '', asn = '';

	if(prefix === null || prefix.name !== prefixName) {
		fetchPrefixes().then((prefixes) => {
			const pref = getPrefix(prefixes[prefixName]);
			if (pref.name === undefined) {
				alert('Ничего не найдено, переадресация!');
				history.push(Routes.first);
			}
			batch(() => {
				setPrefix(pref);
				setState({
					...state,
					prefix: pref
				});
			})
		});
		return <Loader className="Loader"/>;
	}

	if (props.user.asn) {
		ip = props.user.asn.ip;
		asn = props.user.asn.autnums[0];
	}

	const changeSort = e => {
		columns.forEach((item, index) => {
			if (item.title === e.target.textContent) {
				if (sortableColumn === item.key ) {
					setDirectionColumn();
				} else {
					setPrefixColumn(item.key);
				}
				if (sortableColumn === ''){
					setDirectionColumn();
					setPrefixColumn(item.key);
				}
			}
		});
	};

	const  fetchPrefixData = (prefixName) => {
		const type = getPrefixType(prefixName);
		ReportAPI.get(localStorage['reportId'], type).then(report => {
			setState({
				...state,
				bgpData: report.data.bgp_data,
				views: report.data.views,
				loaded: true,
			});
		});
	}

	if (!state.loaded) {
		fetchPrefixData(state.prefix.name);
	}
	//let newData = JSON.parse(JSON.stringify(data));
	if (Object.keys(state.bgpData).length && state.needRerender) {
		let keyData = null;
		for (let key in state.bgpData){
			for (let key2 in state.bgpData[key]){
				if (key2 === state.prefix.name){
					keyData = key;
				}
			}
		}

		setState({
			...state,
			needRerender: false,
			data: parsePrefixData(state.bgpData[keyData], state.prefix, state.views),
		});
	}
	//compareDate = '2021-05-25'
	datePickerSetupIncludeDates([reportDatesState, setReportDatesState], compareDate, user);
	if (1 || !state.loadingCompareDate) {
		handleDatePicker([compareModeState, setCompareModeState], compareDate, checked).then(async (response) => {
			if (response !== null) {
				let result;
				if (getPrefixType(state.prefix.name) === 4) {
					result = response.report4;
				} else {
					result = response.report6;
				}
				setState({
					...state,
					bgpDataCompare: result.data.bgp_data,
					viewsCompare: result.data.views,
					loadingCompareDate: true,
					needAddDateData: true,
				});
				setCompareModeState({...compareModeState, elements: true, compareDate});
			}
		});
	}

	const startDate = getDatePickerStartDate(compareDate, reportDatesState);

	if(state.loadingCompareDate && state.needAddDateData && state.data.length) {

		let keyDataCompare = null;
		if (Object.keys(state.bgpDataCompare)){
			for (let key in state.bgpDataCompare){
				for (let key2 in state.bgpDataCompare[key]){
					if (key2 === state.prefix.name){
						keyDataCompare = key;
					}
				}
			}
			let ngData = JSON.parse(JSON.stringify(state.data));
			ngData = addDateData(ngData, state.bgpDataCompare[keyDataCompare], prefix, state.views);
			setState({
				...state,
				dataCompare: ngData,
				needAddDateData: false,
			});
			//console.log(state.data);
		}
	}
	let needShowChange = false;
	let maxCountAs = 0;
	if(compareModeState.on && state.dataCompare) {
		state.dataCompare.forEach((item, index) => {
			maxCountAs = (item['as_path'].length > maxCountAs) ? item['as_path'].length : maxCountAs;
			maxCountAs = (item['as_path_new'].length > maxCountAs) ? item['as_path_new'].length : maxCountAs;
			maxCountAs = maxCountAs > 5 ? 5 : maxCountAs;
			//item['as_length_change'] = "+2";
			if (item['as_length_change'] !== "") {
				needShowChange = true;
			}
		});
	}

	if (compareModeState.on && state.dataCompare) {
		state.dataCompare.sort(compare(sortableColumn, direction));
	} else {
		state.data.sort(compare(sortableColumn, direction));
	}

	return (
			<Fragment>
				<H1
					text="Префикс"
					smallSize={smallSize}
					startDate={startDate}
					reportDates={reportDatesState === null ? [] : reportDatesState.map(r => r.date)}
					user={user}
				/>
					<div className="prefix__datagrid__container">
						<PrefixInfo
							prefix={state.prefix}
							setPrefix={(prefixState) => setState({ ...state, prefix: prefixState })}
							smallSize={smallSize}
							/>
							<div className={smallSize ? 'prefix-info__control' : 'prefix-info__control__desktop'}>
								<div className="prefix-info__sort__wrapper" onClick={e => changeSort(e)}>
									<PrefixSort
										smallSize={smallSize}
										showChange={needShowChange}
										/>
								</div>
							</div>
							<div className="prefix__datagrid">
								{((compareModeState.on && state.dataCompare) ? state.dataCompare : state.data).map((item, index) => (
										<PrefixDataGridRow
											data={item}
											key={index + sortableColumn + direction + JSON.stringify(compareModeState) + JSON.stringify(state)}
											innerKey={index}
											smallSize={smallSize}
											maxCount={maxCountAs}
											showChange={needShowChange}
											/>
								))}
							</div>
				</div>
			</Fragment>
			);
}
;


const mapStateToProps = (state) => {

	return {
		//showSortPage: state.showSortPage,
		compareDate: state.compareDate,
		user: state.user,
		checked: state.checked
	};
};

const mapDispatchToProps = dispatch => {
	return {
		showSortPageAction: data => dispatch(showSortPageActions(data)),
		setPrefixColumn: data => dispatch(setPrefixColumn(data)),
		setDirectionColumn: () => dispatch(setPrefixDirection()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Prefix);



