import axios from "axios";
import { toast } from "react-toastify";
import { API_URL } from "../../redux/constants";

export const sendFeedback = async (data) => {
    try {
        const response = await axios.post(
            `${API_URL}/api/feedback/add/`,
            new URLSearchParams({...data}),
            { withCredentials: true }
        );

        return response;
    } catch (e) {
        return toast.error(`${e}`);
    }
};

export const getASN = async () => {
    try {
        const response = await axios.post(
            `${API_URL}/api/ip-json/`,
            new URLSearchParams({ tp: 'my_ip' }),
            { withCredentials: true }
        );

        return response.data;
    } catch (e) {
        return toast.error(`${e}`);
    }
}
