// Кнопка для копирования ссылки на отчет

import React from "react";
import linkIcon from "../../img/link-icon.svg";
import {toast} from "react-toastify";

class CopyButton extends React.Component {
    constructor(props) {
        super(props);

        this.copyLink = this.copyLink.bind(this);
    }

    copyLink() {
        let input = document.createElement("input");

        input.value = this.props.copyValue;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        toast.success('Скопировано!');
        document.body.removeChild(input);
    }

    render() {
        return (
            <button onClick={this.copyLink}
                    className={`button button--white report-detail__header-button ${this.props.class}`}>
                <svg width="16" height="12" viewBox="3 5 23 15">
                    <use xlinkHref={`${linkIcon}#link-icon`}/>
                </svg>
                Копировать ссылку
            </button>
        );
    }
}


export default CopyButton;