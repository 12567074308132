import React, { Component, createRef } from 'react';

export default class ClickOutside extends Component {
    constructor(props) {
        super(props);

        this.ref = createRef();
        this.handler = this.handler.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handler);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handler);
    }

    handler(event) {
        if (this.ref && !this.ref.current.contains(event.target) && event.target && !event.target.classList.contains('filter-expander')) {
            this.props.handler();
        }
    }

    render() {
        return <div className="OutsideFragment" ref={this.ref}>{this.props.children}</div>
    }
}