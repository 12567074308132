export const sortDirections = [
	'asc', 'desc', //'undefined',
];

export function compare(field, order) {

	const weigths = {
		'global': 3,
		'regional': 2,
		'local': 1,
	}
	var len = arguments.length;
	//если ничего не передано - сортируем по умолчанию
	if (len === 0) {
		return (a, b) => (a < b && -1) || (a > b && 1) || 0;
	}
	//если передан первый параметр
	if (len === 1) {
		switch (typeof field) {
			// если тип поля number, т.е. массив с индексами 0, 1, 2 и тд
			case 'number':
				return field < 0 ?
						((a, b) => (a < b && 1) || (a > b && -1) || 0) :
						((a, b) => (a < b && -1) || (a > b && 1) || 0);
				// если ассоциативнй массив
			case 'string':
				return (a, b) => (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0;
			default:
		}
	}
	// если передано два параметра и второй - число
	if (len === 2 && typeof order === 'number') {
	/*	let index;
		columns.forEach((item, i)=>{
			if (field === item.key){
				index = i;
			}
		})*/
		// если второй параметр < 2 (я так придумал)
		if (field === 'title') {
			if (order === 0) {
				return ((a, b) => /*columns[index].sortingUp(a[field], b[field]))*/ (a[field] < b[field] && 1) || (a[field] > b[field] && -1) || 0)
			} else if (order === 1) {
				return ((a, b) => /*columns[index].sortingDown(a[field], b[field]))*/ (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0);
			} else {
				return ((a, b) => (null));
			}
		} else if (field === 'as'){
			if (order === 0) {
				return ((a, b) => {
					if (a[field].indexOf('AS') === 0 && b[field].indexOf('AS') === 0) {
						return (+a[field].substring(2) < +b[field].substring(2) && 1) || (+a[field].substring(2) > +b[field].substring(2) && -1) || 0
					} else if (a[field].indexOf('AS') === 0 && b[field].indexOf('AS') !== 0) {
						return 1;
					} else if (a[field].indexOf('AS') !== 0 && b[field].indexOf('AS') === 0) {
						return -1;
					} else {
						return a[field].localeCompare('b');
					}
				/*	return (+a[field].substring(2) < +b[field].substring(2) && 1) || (+a[field].substring(2) > +b[field].substring(2) && -1) || 0*/
				})
			} else if (order === 1) {
				return ((a, b) => {
					if (a[field].indexOf('AS') === 0 && b[field].indexOf('AS') === 0) {
						return (+a[field].substring(2) < +b[field].substring(2) && -1) || (+a[field].substring(2) > +b[field].substring(2) && 1) || 0
					} else if (a[field].indexOf('AS') === 0 && b[field].indexOf('AS') !== 0) {
						return -1;
					} else if (a[field].indexOf('AS') !== 0 && b[field].indexOf('AS') === 0) {
						return 1;
					} else {
						return a[field].localeCompare('b');
					}
					//(+a[field].substring(2) < +b[field].substring(2) && -1) || (+a[field].substring(2) > +b[field].substring(2) && 1) || 0
				});
			} else {
				return ((a, b) => (null));
			}
		} else if (field === 'as_length'){
			if (order === 0) {
				return ((a, b) => (+a[field] < +b[field] && 1) || (+a[field] > +b[field] && -1) || 0)
			} else if (order === 1) {
				return ((a, b) => (+a[field] < +b[field] && -1) || (+a[field] > +b[field] && 1) || 0);
			} else {
				return ((a, b) => (null));
			}
		} else if (field === 'as_length_change'){
			if (order === 0) {
				return ((a, b) => (+a[field] < +b[field] && 1) || (+a[field] > +b[field] && -1) || 0)
			} else if (order === 1) {
				return ((a, b) => (+a[field] < +b[field] && -1) || (+a[field] > +b[field] && 1) || 0);
			} else {
				return ((a, b) => (null));
			}
		} else if (field === 'type'){
			if (order === 0) {
				return ((a, b) => (weigths[a[field]] < weigths[b[field]] && 1) || (weigths[a[field]] > weigths[b[field]] && -1) || 0);
			} else if (order === 1) {
				return ((a, b) => (weigths[a[field]] < weigths[b[field]] && -1) || (weigths[a[field]] > weigths[b[field]] && 1) || 0);
			} else {
				return ((a, b) => (null));
			}
		} else if (field === 'as_path'){
			if (order === 0) {
				return ((a, b) => (parseAsPath(a[field]) < parseAsPath(b[field]) && 1) || (parseAsPath(a[field]) > parseAsPath(b[field]) && -1) || 0);
			} else if (order === 1) {
				return ((a, b) => (parseAsPath(a[field]) < parseAsPath(b[field]) && -1) || (parseAsPath(a[field]) > parseAsPath(b[field]) && 1) || 0);
			} else {
				return ((a, b) => (null));
			}
		}
	}
		/*return order < 2 ?
				// сортируем в одну сторону
						((a, b) => (a[field] < b[field] && 1) || (a[field] > b[field] && -1) || 0) :

						// иначе в обратную

						((a, b) => (a[field] < b[field] && -1) || (a[field] > b[field] && 1) || 0);
					}*/

					var fields, orders;
					if (typeof field === 'object') {
						fields = Object.getOwnPropertyNames(field);
						orders = fields.map(key => field[key]);
						len = fields.length;
					} else {
						fields = new Array(len);
						orders = new Array(len);
						for (let i = len; i--; ) {
							fields[i] = arguments[i];
							orders[i] = 1;
						}
					}
					return (a, b) => {
						for (let i = 0; i < len; i++) {
							if (a[fields[i]] < b[fields[i]])
								return orders[i];
							if (a[fields[i]] > b[fields[i]])
								return -orders[i];
						}
						return 0;
					};
				}

const parseAsPath = (as_path) => {
	const as_pathStr = as_path.join( ' ');
	const as_pathMod = as_pathStr.replace(/(\*\d)/g, '');
	const arr = as_pathMod.split( ' ');

	return arr.reverse().join( ' ');
}

export default {sortDirections, compare};
