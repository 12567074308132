// Страница "Авторизация"

import React from "react";
import logoAuth from "../../img/logo.svg";
import Input from "../../components/UI/Input/Input";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as userActions from "../../redux/actions/userActions";
import * as asnActions from "../../redux/actions/asnActions";
import * as UserAPI from "../../helpers/api/User";
import Routes from "../../helpers/Routes/Routes";
import { toast } from "react-toastify";
import { EMAIL_REGEX } from "../../helpers/RegEx";
import { getASN } from "../../helpers/api/Misc";
import Headers from "../../helpers/Headers";

class Auth extends React.Component {
    state = {
        controls: {
            email: {
                controlType: "input",
                controlConfig: {
                    type: "email",
                },
                title: "E-mail",
                value: "",
                error: "",
            },
            password: {
                controlType: "input",
                controlConfig: {
                    type: "password",
                },
                recovery: true,
                title: "Пароль",
                value: "",
                error: "",
            },
        },
    };

    componentDidMount() {
        document.title = Headers.auth;
    }

    inputHandler(event, controlName) {
        const updatedControls = {
            ...this.state.controls,
            [controlName]: {
                ...this.state.controls[controlName],
                value: event.target.value,
                error: this.state.controls[controlName].value && ''
            },
        };
        this.setState({ controls: updatedControls });
    }

    async formHandler(event) {
        event.preventDefault();

        if (
            (!this.state.controls.email.value &&
            !this.state.controls.password.value) || (!this.state.controls.email.value ||
            !this.state.controls.password.value)
        ) {
            this.setState({
                controls: {
                    ...this.state.controls,
                    email: {
                        ...this.state.controls.email,
                        error: !this.state.controls.email.value && "Поле не заполнено",
                    },
                    password: {
                        ...this.state.controls.password,
                        error: !this.state.controls.password.value && "Поле не заполнено",
                    },
                },
            });
            return;
        }

        if (!EMAIL_REGEX.test(this.state.controls.email.value)) {
            this.setState({
                controls: {
                    ...this.state.controls,
                    email: {
                        ...this.state.controls.email,
                        error: 'Введен некорректный e-mail'
                    }
                }
            })
            return;
        }

        UserAPI.auth(
            this.state.controls.email.value,
            this.state.controls.password.value
        ).then(async (response) => {
            const { reason, status } = response.data;

            if (status === "error") {
                switch (reason.code) {
                    // Неверный логин (почта)
                    case 1006:
                        this.setState({
                            controls: {
                                ...this.state.controls,
                                email: {
                                    ...this.state.controls.email,
                                    error: reason.msg,
                                },
                                password: {
                                    ...this.state.controls.password,
                                    error: "",
                                },
                            },
                        });
                    break;
                    // Неверный пароль
                    case 1007:
                        this.setState({
                            controls: {
                                ...this.state.controls,
                                password: {
                                    ...this.state.controls.password,
                                    error: reason.msg,
                                },
                                email: {
                                    ...this.state.controls.email,
                                    error: "",
                                },
                            },
                        });
                    break;
                    // Другие коды ошибок
                    default:
                        toast.error(`Code: ${reason.code} / ${reason.msg}`);
                    break;
                }
                return;
            }

            // let userData = await this.props.getUser();
            getASN().then(asn => this.props.receiveASN(asn));

            //let redirectPath = userData.hasReport ? Routes.report : Routes.first;
            const redirectPath = Routes.first;
            document.location.href = redirectPath;
        });
    }

    render() {
        let formElementsArray = [];

        for (let type in this.state.controls) {
            formElementsArray.push({
                id: type,
                config: this.state.controls[type],
            });
        }

        const form = formElementsArray.map((formElement) => (
            <Input
                key={formElement.id}
                elementType={formElement.config.controlType}
                elementConfig={formElement.config.controlConfig}
                elementTitle={formElement.config.title}
                recovery={formElement.config.recovery}
                value={formElement.config.value}
                elementError={formElement.config.error}
                changed={(event) => {
                    this.inputHandler(event, formElement.id);
                }}
            />
        ));

        return (
            <section className="start-page">
                <div className="start-page__logo-container">
                    <img
                        src={logoAuth}
                        alt=""
                        className="logo start-page__logo"
                    />
                </div>
                <p className="start-page__text">
                    Войдите, чтобы продолжить работу с<br />
                    системой AS-Path.
                </p>
                <form
                    noValidate
                    className="start-page__form"
                    onSubmit={this.formHandler.bind(this)}
                >
                    {form}
                    <button type="submit" className="button">
                        Войти
                    </button>
                </form>
                <NavLink
                    className="link start-page__registration"
                    to={Routes.registration}
                >
                    Регистрация
                </NavLink>
            </section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: () => dispatch(userActions.getUser()),
        receiveASN: (payload) => dispatch(asnActions.receivedASN(payload))
    };
};

export default connect(null, mapDispatchToProps)(withRouter(Auth));
