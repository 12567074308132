import {parseAs_path, getLength, getAsName} from "./parsePrefixData";

export default (data, datedata, prefix, views) => {
 //   console.log(datedata, prefix);
    if (datedata  && data.length) {
        Object.keys(datedata).forEach((item) => {
            // нашли нужный префикс
            if (item === prefix.name){
				//console.log(datedata[item]);
                //
                Object.keys(datedata[item]).forEach((type) => {
                    Object.keys(datedata[item][type]).forEach((as) => {
                        const typeMod = type === 'ix' ? 'local' : type;
                        const asPathNew = datedata[item][type][as][prefix.name][0]["as-path"];
                        let found = false;
                        data.forEach((view, i) => {
                            if (view['type'] === typeMod && view['as'] === as){
                                data[i]['as_path_new'] = parseAs_path(asPathNew);
                                data[i]['as_length_change'] = getChangeLength(asPathNew, data[i]['as_length']);
                                found = true;
                            }
                        });
                        // Если на сравниваемую дату путь был, а сейчас нет - показываем пустую строку (second_row)
                        if (!found) {
                            data.push({
                                id: data.length,
                                type: type,
                                as: as,
                                as_path: ['-'],
                                as_path_new: parseAs_path(asPathNew),
                                as_length_change: "",
                                as_length: "",
                                title: getAsName(as, views),
                            });
                        }
                    });
                });
            }
        });
        // Если на сравниваемую дату пути не было - показываем пустую строку (three_row)
        data.forEach((view, i) => {
            if (data[i]['as_path_new']==='') {
                data[i]['as_path_new'] = ['-'];
            }
        });
    }

    return data;
}

const getChangeLength = (as_path, as_length_old) => {
    const as_length_new = getLength(as_path);
    return as_length_old - as_length_new;
}
