// Страница "История"

import React, {Fragment} from "react";
import ApexCharts from "apexcharts";
import IpToggle from "../IpToggle/IpToggle";
import * as ReportAPI from '../../helpers/api/Report';
import {connect} from "react-redux";
import * as filterActions from "../../redux/actions/filterActions";
import LineChart from "../LineChart/LineChart";
import * as Utils from "../../helpers/Utils";
import * as ViewsAPI from "../../helpers/api/Views";
import moment from "moment/moment";
import ReportSelector from "../ReportSelector/ReportSelector";
import * as PoolAPI from "../../helpers/api/Pool";
import arrayOfColors from "../../helpers/Chroma";
import {withRouter} from "react-router-dom";
import Headers from "../../helpers/Headers";
import Loader from "../Loader/Loader";

class History extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preparedResources: [],
            preparedGraph: [],
            dates: [],
            curGraph: "avg",
            currentReport: localStorage.getItem("reportId"),
            reportLoaded: false,
            resourcesNames: {},
            graphColors: []
        }

        this._isMounted = false;
        this.changeGraphHandler = this.changeGraphHandler.bind(this);
        this.body = document.body;
        this.reportChange = this.reportChange.bind(this);
        this.chartUpdateCallback = this.chartUpdateCallback.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.ipv !== prevProps.ipv
            || this.state.currentReport !== prevState.currentReport
            || JSON.stringify(this.props.user.isLogin) !== JSON.stringify(prevProps.user.isLogin)) {
            this.setData();
        }

        if (JSON.stringify(prevProps.filterData) !== JSON.stringify(this.props.filterData)) {
            // при изменении состояния перерисуем графики
            let preparedGraph = this.prepareGraph(this.props.filterData.resources, this.state.curGraph, this.state.dates, this.props.filterData.viewPoints);
            this.setState({
                preparedGraph: preparedGraph,
                preparedResources: this.props.filterData.resources
            })
        }
    }

    changeGraphHandler(e) {
        let curGraph = e.currentTarget.getAttribute("data-graph");
        let preparedGraph = this.prepareGraph(this.state.preparedResources, curGraph, this.state.dates, this.props.filterData.viewPoints);

        this.setState({
            preparedGraph: preparedGraph,
            curGraph: curGraph
        })
    }

    prepareGraph(preparedResources, type = "avg", arDates = this.state.dates, viewPoints) {
        let preparedData = this.getGraphValues(preparedResources, type, viewPoints);
        let arColors = this.getGraphColors(preparedResources);
        this.setState({
            graphColors: arColors
        })

        return this.getGraphData(preparedData, arDates);
    }

    getGraphColors(preparedResources) {
        let arColors = [];

        if (!preparedResources || !Object.keys(preparedResources).length)
            return arColors;

        Object.keys(preparedResources).forEach(res => {
            arColors.push(preparedResources[res].color);
        });

        return arColors;
    }

    getGraphData(preparedGraph, arDates) {
        // приводим к необходимому виду для графика
        let graphPoints = [];

        Object.keys(preparedGraph).map(resource => {
            let arPoints = [];
            preparedGraph[resource].map((pathValue, key) => {
                arPoints.push([
                    moment(arDates[key], "YYYY-MM-DD").valueOf(),
                    pathValue
                ]);
            });

            // добавим текущую дату, если ее нет в массиве с датами
            if (arDates.concat().pop() !== moment().format("YYYY-MM-DD")) {
                arPoints.push([
                    moment().valueOf(),
                    preparedGraph[resource].concat().pop() // берем последнее значение из массива значений
                ]);
            }

            graphPoints.push({
                checked: this.props?.filterData?.resources?.[resource]?.checked ? true : false,
                name: resource,
                data: arPoints
            })
        })

        return graphPoints;
    }

    prepareResources(resources, viewPointsObj = {}, resourcesNames = this.state.resourcesNames) {
        // группировка для данных исторического графика
        try {
            if (!Object.keys(resources).length)
                return {};

            let arResources = {};
            // группируем по ресурсу
            Object.keys(resources).map((resource, resIndex) => {
                Object.keys(resources[resource]).map(prefix => {
                    if (!arResources.hasOwnProperty(resource))
                        arResources[resource] = {
                            resource: resource,
                            checked: true,
                            prefixes: {},
                            color: arrayOfColors[resIndex]
                        };

                    // попробуем найти имя ресурса
                    if (Object.keys(resourcesNames).length && resourcesNames.hasOwnProperty(resource))
                        arResources[resource].name = resourcesNames[resource];

                    let views = {};
                    Object.keys(resources[resource][prefix]).map(viewType => {
                        Object.keys(resources[resource][prefix][viewType]).map(view => {

                            // если viewPointsObj задан, добавляем только точки которые есть в viewPointsObj
                            if (Object.keys(viewPointsObj).length) {
                                if (viewPointsObj.hasOwnProperty(view)) {
                                    views[view] = {
                                        checked: true,
                                        type: viewPointsObj[view].type,
                                        value: resources[resource][prefix][viewType][view]
                                    };
                                }
                            } else {
                                views[view] = {
                                    checked: true,
                                    value: resources[resource][prefix][viewType][view]
                                };
                            }

                        })
                    })

                    arResources[resource].prefixes[prefix] = {
                        checked: true,
                        views: views
                    };
                })
            })

            arResources = Utils.sortObject(arResources);
            // set filter from localStorage
            arResources = this.setResourcesFilter(arResources);

            return arResources;
        } catch (e) {
            console.error(e);
        }
    }

    prepareViewPoints(viewPoints) {
        Object.keys(viewPoints).forEach(view => {
            viewPoints[view].checked = true;
        })

        viewPoints = ViewsAPI.groupByType(viewPoints);
        viewPoints = this.setViewPointsFilter(viewPoints);

        return viewPoints;
    }

    setResourcesFilter(preparedResources) {
        try {
            let filterResources = localStorage.getItem("filterResources"),
                filterVP = localStorage.getItem("filterViewPoints");

            if (filterResources && Object.keys(filterResources).length) {
                // сопоставим данные фильтра по ресурсам из localStorage с текущим
                filterResources = JSON.parse(filterResources);
                filterVP = JSON.parse(filterVP);

                Object.keys(filterResources).forEach(res => {
                    // проверяем ресурсы
                    if (!preparedResources?.[res]) return;
                    let prepResource = preparedResources[res],
                        localResource = filterResources[res];

                    prepResource.checked = localResource.checked;

                    // проверяем префиксы
                    if (!prepResource?.prefixes || !localResource?.prefixes) return;
                    let prepPrefixes = prepResource.prefixes,
                        localPrefixes = localResource.prefixes;

                    Object.keys(localPrefixes).forEach(prefix => {
                        if (!prepPrefixes?.[prefix]) return;
                        prepPrefixes[prefix].checked = localPrefixes[prefix].checked;

                        if (!prepPrefixes[prefix]?.views || !filterVP || !Object.keys(filterVP).length) return;
                        // сопоставим точки наблюдения
                        Object.keys(localPrefixes[prefix].views).forEach(view => {
                            if (!prepPrefixes[prefix].views?.[view]) return;
                            prepPrefixes[prefix].views[view].checked = localPrefixes[prefix].views[view].checked;
                        })
                    })

                })
            }

            return preparedResources;
        } catch (e) {
            console.error(e);
        }
    }

    setViewPointsFilter(viewPoints) {
        try {
            let filterVP = localStorage.getItem("filterViewPoints");

            if (filterVP && Object.keys(filterVP).length) {

                filterVP = JSON.parse(filterVP);
                // проверим были ли чекнуты точки наблюдения в фильтре
                Object.keys(filterVP).forEach(group => {
                    if (!viewPoints?.[group]) return;
                    viewPoints[group].checked = filterVP[group].checked;

                    if (!viewPoints[group]?.items) return;

                    Object.keys(filterVP[group].items).forEach(view => {
                        if (!viewPoints[group].items?.[view]) return;

                        viewPoints[group].items[view].checked = filterVP[group].items[view].checked;
                    })
                })

            }

            return viewPoints;
        } catch (e) {
            console.error(e);
        }
    }

    getGraphValues(preparedResources = {}, type = "avg", viewPoints) {
        let graphValues = {};
        // находим средние/мин/макс показатели
        Object.keys(preparedResources)
            // .filter(resource => {
            //     return preparedResources[resource].checked;
            // })
            .map((resource) => {

                // сформируем матрицу длин путей для подсчета средние/мин/макс значений
                let matrix = [];
                Object.keys(preparedResources[resource].prefixes).map(prefix => {
                    // активен ли префикс в фильтре
                    if (preparedResources[resource].prefixes[prefix].checked) {
                        Object.keys(preparedResources[resource].prefixes[prefix].views).map(view => {
                            let viewObj = preparedResources[resource].prefixes[prefix].views[view];
                            // если точка или группа неактивна, то пропускаем
                            if (!viewObj.checked || !viewPoints[viewObj.type].checked)
                                return;

                            matrix.push(preparedResources[resource].prefixes[prefix].views[view].value);
                        })
                    }
                })

                switch (type) {
                    case "avg":
                        graphValues[resource] = Utils.matrixAvg(matrix);
                        break;

                    case "min":
                        graphValues[resource] = Utils.matrixMin(matrix);
                        break;

                    case "max":
                        graphValues[resource] = Utils.matrixMax(matrix);
                        break;
                }
            })

        return graphValues;
    }

    async setData(graphType = this.state.curGraph) {
        if (typeof this.props?.user?.isLogin === "undefined" || !this.props.ipv)
            return;

        if (typeof this.props?.user?.isLogin !== "undefined"
            && (!this.props.user.isLogin || !this.props.user.hasReport)) {
            this.props.history.push('/first');
            return;
        }

        this.setState({
            reportLoaded: false,
            preparedGraph: {}
        })

        try {
            let result = await ReportAPI.getHistory(this.state.currentReport, `ipv${this.props.ipv}`);

            if (!this._isMounted || !Utils.checkResponse(result)) return;

            // получение имена ресурсов
            let resourcesNames = {};
            let poolResponse = await PoolAPI.getList(localStorage.getItem("poolId"));
            if (Utils.checkResponse(poolResponse)) {
                poolResponse.data.resource.forEach(res => {
                    resourcesNames[res.resource] = res.name;
                })
            }

            // фильтруем точки наблюдения в зависимости от ipv
            // сформируем объект с кодами view для удаления лишних точек из preparedResources
            let viewPoints = {};

            if (typeof result.data.views !== "undefined") {
                result.data.views.forEach(view => {
                    viewPoints[view.view] = view;
                });
            }

            // обновляем состояние для дальнейшей группировки и других манипуляций
            let preparedResources = this.prepareResources(result.data.bgp_data, viewPoints, resourcesNames);
            // проставим точкам значение по умолчанию checked и сгруппируем по типу
            viewPoints = this.prepareViewPoints(viewPoints);

            // подготовка данных для построения графика
            let preparedGraph = this.prepareGraph(preparedResources, graphType, result.data.dates, viewPoints);

            this.setState({
                dates: result.data.dates,
                preparedResources: preparedResources,
                preparedGraph: preparedGraph,
                curGraph: graphType,
                minDate: result.data.dates[0],
                maxDate: result.data.dates[result.data.dates.length - 1],
                reportLoaded: true,
                resourcesNames: resourcesNames
            })

            // кидаем в глобальный state
            this.props.setFilter({
                resources: preparedResources,
                viewPoints: viewPoints
            });

            this.props.setFilterEmpty({ reportIsEmpty: this.state.preparedGraph.length ? false : true });
        } catch (e) {
            console.error(e);
        }
    }

    async componentDidMount() {
        document.title = Headers.history;
        this._isMounted = true;
        await this.setData();
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.setFilter({});
    }

    reportChange(reportId) {
        this.setState({
            currentReport: reportId
        });
    }

    chartUpdateCallback() {
        for (let res in this.state.preparedResources) {
            ApexCharts.exec(
                "chart1",
                this.state.preparedResources[res].checked ? "showSeries" : "hideSeries",
                [res]
            );
        }
    }

    render() {
        let isDataValid = typeof this.state.preparedGraph !== "undefined"
            && typeof this.state.preparedGraph === "object",
            hash = localStorage.getItem("reportHash") || localStorage.getItem("tmpReportHash"),
            reportUrl = `${document.location.origin}${document.location.pathname}?hash=${hash}`;

        return (
            <section className="report-detail">
                <section className="report-detail__header">
                    <h1 className="main-title main-title--report-detail">История наблюдений</h1>
                    <ReportSelector onChangeCallback={this.reportChange}/>
                    <IpToggle component="History"/>
                </section>
                {!this.state.reportLoaded && <Loader class="Loader"/>}
                {this.state.reportLoaded && !this.state.preparedGraph.length &&
                <span>Нет данных для построения исторического графика.</span>
                }
                {(isDataValid === true && this.state.preparedGraph.length > 0) &&
                <Fragment>
                    <section className="observation-points__wrapper">
                        <span className="observation-points__text">Расстояние</span>
                        <ul className="observation-points__list">
                            <li className="observation-points__item">
                                <a href="#" data-graph="min" onClick={this.changeGraphHandler}
                                className={`observation-points__link ${this.state.curGraph === "min" ? "observation-points__link--active" : ""}`}>Мин.</a>
                            </li>
                            <li className="observation-points__item">
                                <a href="#" data-graph="avg" onClick={this.changeGraphHandler}
                                className={`observation-points__link ${this.state.curGraph === "avg" ? "observation-points__link--active" : ""}`}>Среднее</a>
                            </li>
                            <li className="observation-points__item">
                                <a href="#" data-graph="max" onClick={this.changeGraphHandler}
                                className={`observation-points__link ${this.state.curGraph === "max" ? "observation-points__link--active" : ""}`}>Макс.</a>
                            </li>
                        </ul>
                    </section>
                    <section className="report-detail__graphics">
                        <span className="IPvType IPvType--History">IPv{this.props.ipv}</span>
                        <LineChart data={this.state.preparedGraph} colors={this.state.graphColors} updateCallback={this.chartUpdateCallback}/>
                    </section>
                </Fragment>
                }
                {isDataValid === false &&
                <p>Нет данных для построения графика.</p>}
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        filterData: state.filter,
        user: state.user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilter: (data) => dispatch(filterActions.setFilter(data)),
        setFilterEmpty: payload => dispatch(filterActions.filterEmpty(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(History));