// Кастомный компонент поля для ввода данных

import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import showPasswordIcon from "../../../img/show-password-icon.svg";
import Routes from "../../../helpers/Routes/Routes";

const Input = (props) => {
  let inputElement = null;

  switch (props.elementType) {
    case "input":
      inputElement = (
        <Fragment>
          <input
            className="field"
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
          {props.elementError && <span className="error label-field__error">{props.elementError}</span>}
          {props.showPassword && <button onClick={props.clicked} type="button" className="button-password label-field__button-password">
              <svg width="18" height="12">
                <use xlinkHref={`${showPasswordIcon}#show-password-icon`} />
              </svg>
            </button>}
          <br/>
          {props.after}
        </Fragment>
      );
	  break;
	default:
		  break;
  }

  return (
    <label className={`label-field ${props.elementError && 'label-field--error'}`}>
      <div className="label-field__header">
        <span className="label-field__name">{props.elementTitle}</span>
        {props.recovery && (
          <NavLink className="link label-field__link" to={Routes.recovery}>Забыли пароль?</NavLink>
        )}
      </div>
      {inputElement}
    </label>
  );
};

export default Input;
