import * as ReportAPI from "./api/Report";

export const handleDatePicker = async ([compareModeState, setCompareModeState], compareDate, checked, force, setForceCompare) => {
    if(!compareModeState.on && checked === false) {
        if(compareDate && JSON.stringify(compareDate) !== '{}' && checked === false) {
            setCompareModeState({
                on: true
            });
        }
    } else if(compareModeState.on && checked === true) {
        setCompareModeState({
            on: false
        });
    }

    if(compareModeState.on && (force || (!compareModeState['elements'] && !compareModeState['report']) || (compareModeState['compareDate'] !== compareDate))) {
		if(force) {
            setForceCompare(false);
        }
        if (!localStorage['reportId']) {
			return null;
		}
        let date = new Date(compareDate);
        let dateStr = date.toISOString().slice(0, 10).replace('T', ' ');
        const report6 = await ReportAPI.get(localStorage['reportId'], 6,  dateStr);
        const report4 = await ReportAPI.get(localStorage['reportId'], 4, dateStr);

        return { report6, report4, dateStr };
    }

    return null;
};

export const datePickerSetupIncludeDates = ([reportDatesState, setReportDatesState], compareDate, user) => {
    if(user.isLogin && reportDatesState === null) {
        const reportDates = [];
        ReportAPI.getDates(localStorage['reportId']).then((dates) => {
            if(dates && !('reason' in dates)) {
                for (const date of dates.data) {
                    reportDates.push({date: new Date(date + 'T00:00:00.000Z')});
                }
                setReportDatesState(reportDates);
            } else if('reason' in dates) {
                if(dates.reason.code === "1003") {
                    delete localStorage['reportId'];
                    delete localStorage['poolId'];
                    document.location.reload();
                }
            }
        });
    }
};

export const getDatePickerStartDate = (compareDate, reportDatesState) => {
    return JSON.stringify(compareDate) !== '{}' ? (new Date(compareDate)) : ((reportDatesState && reportDatesState.length) ? new Date(reportDatesState[reportDatesState.length - 1].date) : new Date())
};
