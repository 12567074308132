import {JUST_REGISTER} from '../constants';

export default (state = {}, action) => {
    const { type, payload} = action;
    //console.log(payload)
    if (type === JUST_REGISTER) {

        return payload
    }

    return state;
};
