// Линейный график

import React, { Fragment } from "react";
import Chart from "react-apexcharts";
import { useHistory } from "react-router-dom";
import Routes from "../../helpers/Routes/Routes";
import moment from "moment/moment";
import {connect} from "react-redux";
import * as ipvActions from "../../redux/actions/ipvActions";

const LineChart = (props) => {
    const history = useHistory();
    const {data} = props;
    const options = {
        chart: {
            id: "chart1",
            events: {
                markerClick: function (event, chartContext, config) {
                    // т.к. ipv на странице /report должно быть такимм же, как на /reports_history
                    props.setIPV({
                        component: "Report",
                        value: props.ipv.History
                    });

                    const date = moment(config.w.globals.initialSeries[config.seriesIndex].data[config.dataPointIndex][0]).format("YYYY-MM-DD");
                    history.push(`${Routes.report}/${date}`);
                },
                updated: function(chartContext, config) {
                    props.updateCallback();
                }
            }
        },
        xaxis: {
            type: "datetime",
            tickAmount: 1,
            labels: {
                formatter: function(val, timestamp) {
                    return moment(timestamp).format("DD MMM YYYY");
                }
            }
        },
        markers: {
            size: 4,
        },
        tooltip: {
            shared: false,
            intersect: true,
        },
        colors: props.colors,
    };
    const brushOptions = {
        chart: {
            id: "brush1",
            brush: {
                target: "chart1",
                enabled: true,
                autoScaleYaxis: false,
            },
            selection: {
                enabled: true,
            },
        },
        xaxis: {
            type: "datetime",
            tickAmount: 1,
            labels: {
                formatter: function(val, timestamp) {
                    return moment(timestamp).format("DD MMM YYYY");
                }
            }
        },
        colors: props.colors
    };

    return (
        <Fragment>
                <Chart
                    options={options}
                    series={data}
                    type="line"
                    height={350}
                    width={'100%'}
                />
                <Chart
                    options={brushOptions}
                    series={data}
                    type="line"
                    width={'100%'}
                    height={200}
                />
        </Fragment>
    );
};

const mapStateToProps = state => {
    return {
        ipv: state.ipv
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setIPV: (data) => dispatch(ipvActions.IpvChanged(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LineChart);
