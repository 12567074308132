// Страница "Отчет"

import React, {Fragment} from "react";
import * as filterActions from "../../redux/actions/filterActions";
import {connect} from "react-redux";
import IpToggle from "../IpToggle/IpToggle";
import "react-day-picker/lib/style.css";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPickerEssentials from "../../helpers/datepickerEssentials/Essentials";
import RadarChart from "../RadarChart/RadarChart";
import ReportTableCompare from "./ReportTableCompare/ReportTableCompare";
import * as ReportAPI from "../../helpers/api/Report";
import * as PoolAPI from "../../helpers/api/Pool";
import * as ViewsAPI from "../../helpers/api/Views";
import * as Utils from "../../helpers/Utils";
import { withRouter } from "react-router-dom";
import ReportSelector from "../ReportSelector/ReportSelector";
import CopyButton from "../CopyButton/CopyButton";
import Headers from "../../helpers/Headers";
import Loader from '../Loader/Loader';
import moment from "moment/moment";

class Report extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            calculatedResources: {},
            radarData: [],
            currentDate: localStorage.getItem("currentDate") ? new Date(localStorage.getItem("currentDate")) : new Date(),
            compareDate: localStorage.getItem("compareDate") ? new Date(localStorage.getItem("compareDate")) : "",
            isCompare: this.props.isCompare || false,
            allViewPoints: {},
            currentReport: false,
            resourcesNames: {},
            reportCreateDate: '',
            pendingUpdate: false
        }

        // если в url указана дата отчета
        if (this.props.match.params.date) {
            const date = `${this.props.match.params.date}T12:00:00`;
            const strDate = new Date(date);
            this.state.currentDate = strDate;
            //localStorage.setItem("currentDate", strDate.toString());
        }

        this._isMounted = false;
        this.reportDateChange = this.reportDateChange.bind(this);
        this.compareDateChange = this.compareDateChange.bind(this);
        this.isCompare = this.isCompare.bind(this);
        this.body = document.body;
        this.reportChange = this.reportChange.bind(this);
    }

    componentWillUnmount() {
        this._isMounted = false;
        this.props.setFilter({});
    }

    async componentDidMount() {
        document.title = !this.props.user.isLogin
            ? Headers.reportForNotLogin
            : `${Headers.report} ${localStorage.getItem('reportId') && `№${localStorage.getItem('reportId')}` || ''}`;

        this._isMounted = true;
        await this.setData();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.user.isLogin) {
            document.title = Headers.reportForNotLogin;
        } else {
            document.title = `${Headers.report} ${localStorage.getItem('reportId') && `№${localStorage.getItem('reportId')}` || ''}`;
        }
        // TODO по хорошему нужно вынести обновление данных в отдельный метод

        // при изменении ipv сбросим флаг и дату сравнения
        if (this.props.ipv !== prevProps.ipv && prevProps.ipv) {
            this.setState({
                isCompare: false,
                //compareDate: ""
            });

            this.props.setFilter({
                ...this.props.filterData,
                resourcesCompare: {},
                isCompare: false
            })
        }

        // при изменении id отчета (т.е. выбор другого отчета в выпадающем списке) обновляем state, который далее вызовет перерисовку отчета
        if (this.props.curReportId !== prevProps.curReportId) {
            localStorage.setItem("currentDate", (new Date()).toString());

            this.setState({
                currentDate: new Date()
            })
        }

        // при изменении ipv/пользователя/даты обновим основные данные
        if ((prevProps.ipv && this.props.ipv !== prevProps.ipv)
            || this.props.user.isLogin !== prevProps.user.isLogin
            || this.state.currentDate !== prevState.currentDate
        ) {
            await this.setData();

            if (this.state.isCompare && this.state.compareDate) {
                let calculatedResourcesCompare = await this.getCompareReport();
                calculatedResourcesCompare = this.calculateAsPath(calculatedResourcesCompare);
                let calculatedResources = this.calculateAsPath(this.props.filterData.resources);
                // объект сравнения (по факту тоже самое что и calculatedResources)
                let comparedResources = this.compare(calculatedResources, calculatedResourcesCompare);
                // получаем данные для графика сравнения
                let radarData = this.getRadarData(this.props.filterData.viewPoints, calculatedResources, calculatedResourcesCompare);

                this.setState({
                    calculatedResourcesCompare: calculatedResourcesCompare,
                    radarData: radarData,
                    comparedResources: comparedResources
                });
            }
        }

        // вызывается при изменении даты/чекбокса сравнения
        if (prevState.compareDate !== this.state.compareDate
            || prevState.isCompare !== this.state.isCompare) {
            if (this.state.isCompare && this.state.compareDate) {
                let calculatedResourcesCompare = await this.getCompareReport();
                calculatedResourcesCompare = this.calculateAsPath(calculatedResourcesCompare);
                let calculatedResources = this.calculateAsPath(this.props.filterData.resources);
                // объект сравнения (по факту тоже самое что и calculatedResources)
                let comparedResources = this.compare(calculatedResources, calculatedResourcesCompare);
                // получаем данные для графика сравнения
                let radarData = this.getRadarData(this.props.filterData.viewPoints, calculatedResources, calculatedResourcesCompare);

                this.setState({
                    calculatedResourcesCompare: calculatedResourcesCompare,
                    radarData: radarData,
                    comparedResources: comparedResources
                });
            }
        }

        // ипользуется при фильтрации
        if (JSON.stringify(prevProps.filterData) !== JSON.stringify(this.props.filterData)
            || prevState.isCompare !== this.state.isCompare
        ) {
            let calculatedResourcesCompare = {}, comparedResources = {};

            // при изменении состояния перерисуем графики
            let calculatedResources = this.calculateAsPath(this.props.filterData.resources);

            if (this.props.filterData.resourcesCompare) {
                calculatedResourcesCompare = this.calculateAsPath(this.props.filterData.resourcesCompare);
                // объект сравнения (по факту тоже самое что и calculatedResources)
                comparedResources = this.compare(calculatedResources, calculatedResourcesCompare);
            }

            let radarData = this.getRadarData(this.props.filterData.viewPoints, calculatedResources, calculatedResourcesCompare);

            this.setState({
                calculatedResources: calculatedResources,
                calculatedResourcesCompare: calculatedResourcesCompare,
                radarData: radarData,
                comparedResources: comparedResources
            });
        }
    }

    // получение отчета для сравнения
    async getCompareReport() {
        if (!this.props.ipv || !this.props.user.isLogin)
            return false;

        let resourcesCompare = {}, calculatedResourcesCompare = {};
        const reportMethod = this.props.user.isLogin && !(new URL(document.location)).searchParams.get("hash") ? "get" : "getByHash",
            reportId = localStorage.getItem(this.props.user.isLogin ? "reportId" : "reportHash");

        // доп. данные для сравнения
        if (this.state.isCompare && this.state.compareDate) {
            let result = await ReportAPI[reportMethod](reportId, this.props.ipv, moment(this.state.compareDate).format("YYYY-MM-DD"));
            if (!Utils.checkResponse(result)) return;
            // подготовка списка ресурсов под формат необходимый для фильтра
            resourcesCompare = this.prepareResources(result.data.bgp_data);
            // вычисление средних путей и тд
            calculatedResourcesCompare = this.calculateAsPath(resourcesCompare);
        }

        // прокидываем данные в фильтр
        this.props.setFilter({
            ...this.props.filterData,
            resourcesCompare: resourcesCompare
        })

        return calculatedResourcesCompare;
    }

    // получение основных данных для отчета
    async setData() {
        if (!this.props.ipv)
            return false;

        let viewPoints, resources, calculatedResources = {}, resourcesNames = {},
            compareDate = this.state.compareDate;

        // для неавторизованных пользователей берем хеш из url/localStorage
        const reportMethod = this.props.user.isLogin && !(new URL(document.location)).searchParams.get("hash") ? "get" : "getByHash",
            reportId = (new URL(document.location)).searchParams.get("hash") ?? localStorage.getItem(this.props.user.isLogin ? "reportId" : "tmpReportHash");

        // если у пользователя нет никаких отчетов и нет hash в url, то редиректим на стартовую страницу
        if (!reportId) {
            if (typeof this.props?.user?.isLogin !== "undefined"
                && (!this.props.user.isLogin || !this.props.user.hasReport)) {
                this.props.history.push('/first');
                return;
            }

            return;
        }

        // получение данных для основного отчета
        let result = await ReportAPI[reportMethod](reportId, this.props.ipv, this.state.currentDate.toISOString().substring(0, 10));
        if (!Utils.checkResponse(result) || !this._isMounted) return;

        // проверяем сформировался ли отчет
        if (result?.data?.pending_update === true || result?.data?.completed === false) {
            await new Promise(resolve => setTimeout(resolve, 3000));
            await this.setData();
            return;
        }

        // получаем дату последнего отчета
        if (!compareDate && (typeof this.props?.user?.isLogin !== "undefined" && this.props.user.isLogin)) {
            let reportDates = await ReportAPI.getDates(reportId);
            if (!Utils.checkResponse(reportDates)) return;

            //compareDate = moment(reportDates.data.slice(-2)[0], "YYYY-MM-DD").toDate();
            let curDateIndex = reportDates.data.indexOf(moment(this.state.currentDate).format("YYYY-MM-DD"));
            if (curDateIndex > 0) {
                compareDate = moment(reportDates.data[curDateIndex - 1], "YYYY-MM-DD").toDate();
            }
        }

        // получаем список всех возможных точек наблюдения, далее этот список используется для определения группы, названия группы
        if (!Object.keys(this.state.allViewPoints).length) {
            let allViewPoints = await ViewsAPI.getList();

            this.setState({
                allViewPoints: allViewPoints
            })
        }

        // получение имена ресурсов
        if (this.props.user?.isLogin) {
            let poolResponse = await PoolAPI.getList(localStorage.getItem("poolId"));

            if (Utils.checkResponse(poolResponse)) {
                poolResponse.data.resource.forEach(res => {
                    resourcesNames[res.resource] = res.name;
                })
            }
        }

        // добавим точкам наблюдения поля checked сгруппируем
        viewPoints = this.prepareViewPoints(result.data.views);
        // подготовка списка ресурсов под формат необходимый для фильтра
        resources = this.prepareResources(result.data.bgp_data, resourcesNames);
        // вычисление средних путей и тд
        calculatedResources = this.calculateAsPath(resources);

        let radarData = this.getRadarData(viewPoints, calculatedResources);

        // прокидываем данные в фильтр
        this.props.setFilter({
            ...this.props.filterData,
            resources: resources,
            viewPoints: viewPoints
        })

        this.setState({
            calculatedResources: calculatedResources,
            radarData: radarData,
            reportReady: !Boolean(result.data.pending_update),
            reportEmpty: !Boolean(Object.keys(result.data.bgp_data).length), // есть ли данные в отчете
            resourcesNames: resourcesNames,
            reportCreateDate: result.data.created,
            compareDate: compareDate
        })

        this.props.setFilterEmpty({ reportIsEmpty: this.state.reportEmpty });
    }

    prepareViewPoints(arViewPoints) {
        // по умолчанию все точки наблюдения выбраны
        let viewPoints = {};

        arViewPoints.forEach(view => {
            viewPoints[view.view] = view;
            viewPoints[view.view].checked = true;
        });

        viewPoints = ViewsAPI.groupByType(viewPoints);
        viewPoints = this.setViewPointsFilter(viewPoints);

        return viewPoints;
    }

    prepareResources(resources, resourcesNames = this.state.resourcesNames) {
        // группируем ресурсы, проставляем по дефолту свойства checked
        try {
            if (!Object.keys(resources).length)
                return {};

            let arResources = {};
            // группируем по ресурсу
            Object.keys(resources).forEach(resource => {
                Object.keys(resources[resource]).forEach(prefix => {
                    if (!arResources.hasOwnProperty(resource))
                        arResources[resource] = {
                            resource: resource,
                            checked: true,
                            prefixes: {}
                        };

                    // попробуем найти имя ресурса
                    if (Object.keys(resourcesNames).length && resourcesNames.hasOwnProperty(resource))
                        arResources[resource].name = resourcesNames[resource];

                    let views = {};
                    Object.keys(resources[resource][prefix]).forEach(viewType => {
                        Object.keys(resources[resource][prefix][viewType]).forEach(view => {
                            views[view] = {};
                            views[view].checked = true;

                            if (typeof resources[resource][prefix][viewType][view]?.[0] !== "undefined") {
                                views[view] = {
                                    ...views[view],
                                    ...resources[resource][prefix][viewType][view][0]
                                };
                            }

                        })
                    })

                    arResources[resource].prefixes[prefix] = {
                        checked: true,
                        views: views
                    };
                })
            })

            arResources = Utils.sortObject(arResources);
            // set filter from localStorage
            arResources = this.setResourcesFilter(arResources);

            return arResources;
        } catch (e) {
            console.error(e);
        }
    }

    getRadarData(viewPoints, calculatedRes, calculatedResCompare = {}) {
        let result = {categories: [], series: [], colors: []},
            avgView = {},
            tmpViewPoints = {},
            viewGroupColor = ["#bf4e3d", "#04c75d", "#534084", "#4d2139", "#316c7a"];

        if (typeof viewPoints === "undefined")
            return result;

        // сформиуем массив имён точек наблюдения
        Object.keys(viewPoints).forEach((group, groupIndex) => {
            if (!viewPoints[group].checked)
                return;

            Object.keys(viewPoints[group].items).forEach(view => {
                if (viewPoints[group].items[view].checked)
                    result.colors.push(viewGroupColor[groupIndex]);

                if (viewPoints[group].items[view].checked)
                    tmpViewPoints[view] = `${viewPoints[group].items[view].name}`;
            })
        })

        // Объект для средних значений ресурсов по точке наблюдения
        let asAvgData;

        // найдем средний путь от группы ресурсов (т.е. всех ресурсов) до каждой точки наблюдения
        function getPoints(calculatedRes, seriesName = "") {
            asAvgData = {};

            Object.keys(calculatedRes).forEach(res => {
                if (!calculatedRes[res].checked)
                    return;

                Object.keys(calculatedRes[res].avgPathToView).forEach(view => {
                    // проверяем есть ли точка в списке выбранных, т.к. avgPathToView содержит все возможные точки
                    if (!tmpViewPoints.hasOwnProperty(view))
                        return;

                    if (!avgView.hasOwnProperty(view))
                        avgView[view] = {};

                    avgView[view] = {
                        pathSum: avgView[view].hasOwnProperty("pathSum") ? avgView[view].pathSum + calculatedRes[res].avgPathToView[view] : calculatedRes[res].avgPathToView[view],
                        count: avgView[view].hasOwnProperty("count") ? avgView[view].count + 1 : 1,
                    }
                    avgView[view].avgPath = Number((avgView[view].pathSum / avgView[view].count).toFixed(2));

                });

                // объект со средними путями от ресурса до точки наблюдения
                Object.keys(tmpViewPoints).forEach(view => {
                    if (!asAvgData.hasOwnProperty(view))
                        asAvgData[view] = {};

                    asAvgData[view][res] = calculatedRes[res].avgPathToView[view];
                })

            })

            // если каких-то данных по точкам нехватает, то заполним недостающее
            Object.keys(tmpViewPoints).forEach(view => {
                if (!avgView.hasOwnProperty(view)) {
                    avgView[view] = {avgPath: 0};
                }
            })

            let series = {name: seriesName, data: []};

            // формируем массив с точками, в том порядке, в котором у нас получены точки наблюдения
            Object.keys(tmpViewPoints).forEach(view => {
                series.data.push(avgView[view].avgPath);
            })

            return series;
        }

        // получаем точки для основного графика
        let points = getPoints(calculatedRes, Utils.formatDate(this.state.currentDate));
        result.asAvgData = asAvgData;
        result.series.push(points);

        // получаем точки для графика сравнения
        if (this.state.isCompare && this.state.compareDate && Object.keys(calculatedResCompare).length) {
            let points = getPoints(calculatedResCompare, Utils.formatDate(this.state.compareDate));
            result.asAvgCompareData = asAvgData;
            result.series.push(points);
        }

        result.categories = Object.values(tmpViewPoints);

        return result;
    }

    calculateAsPath(preparedResources) {
        // пересчеты для нахождения средних значений aspath
        if (!preparedResources || !Object.keys(preparedResources).length)
            return {};


        function avgPathToGroup(prefix, self) {
            // считаем средний aspath ресурса до группы точек (т.е. до региональных/локальных/глобальных)
            let avgPathToGroup = {},
                result = {};

            Object.keys(prefix.views).map(view => {
                let viewObj = prefix.views[view];

                let type = self.state.allViewPoints[`v${self.props.ipv}`].hasOwnProperty(view)
                    ? self.state.allViewPoints[`v${self.props.ipv}`][view].type
                    : false;

                if (!type)
                    return;

                if (!viewObj.checked) {
                    return;
                }

                if (!avgPathToGroup.hasOwnProperty(type)) avgPathToGroup[type] = {sum: 0, count: 0};

                avgPathToGroup[type] = {
                    sum: avgPathToGroup[type].sum + viewObj.aspath,
                    count: avgPathToGroup[type].count + 1
                }

                if (!result.hasOwnProperty(type)) result[type] = {};

                result[type] = avgPathToGroup[type].count > 0
                    ? Number((avgPathToGroup[type].sum / avgPathToGroup[type].count).toFixed(2))
                    : 0;
            })

            return result;
        }


        let resources = JSON.parse(JSON.stringify(preparedResources));

        try {
            if (!Object.keys(resources).length)
                return {};

            Object.keys(resources).forEach(res => {
                // вспомогательный объект, куда складываем средние значения пути от ресурса до точки
                let avgView = {};
                resources[res].avgPathToView = {};

                Object.keys(resources[res].prefixes).forEach(prefix => {

                    // если префикс неактивен
                    if (!resources[res].prefixes[prefix].checked) {
                        delete resources[res].prefixes[prefix];
                        return;
                    }

                    Object.keys(resources[res].prefixes[prefix].views).forEach(view => {
                        let viewObj = resources[res].prefixes[prefix].views[view];

                        // если точка наблюдения неактивна, то не считаем ее aspath
                        if (!viewObj.checked) {
                            viewObj.aspath = 0;
                            return;
                        }

                        viewObj.aspath = viewObj["as-path"].length ? (viewObj["as-path"].split(" ")).length : 0;

                        // считаем средний aspath для каждого ресурса до точки наблюдения
                        if (!avgView.hasOwnProperty(view)) avgView[view] = {};

                        avgView[view] = {
                            count: avgView[view].hasOwnProperty("count") ? avgView[view].count + 1 : 1,
                            pathSum: avgView[view].hasOwnProperty("pathSum") ? avgView[view].pathSum + resources[res].prefixes[prefix].views[view].aspath : resources[res].prefixes[prefix].views[view].aspath
                        }

                    })
                })

                // если у ресурса нет префиксов, то удалим ресурс
                if (!Object.keys(resources[res].prefixes).length || !resources[res].checked) {
                    delete resources[res];
                    return;
                }

                // считаем средний aspath для каждого ресурса до точки наблюдения
                Object.keys(avgView).forEach(view => {
                    resources[res].avgPathToView[view] = Object.keys(resources[res].prefixes).length
                        ? Number((avgView[view].pathSum / avgView[view].count).toFixed(2))
                        : 0;
                })

            })

            Object.keys(resources).forEach(res => {
                // считаем средний aspath ресурса до группы точек (т.е. до региональных/локальных/глобальных)
                // для таблицы
                Object.keys(resources[res].prefixes).map(prefix => {
                    resources[res].prefixes[prefix].avgPathToGroup = avgPathToGroup(resources[res].prefixes[prefix], this);

                })
            })

            return resources;
        } catch (e) {
            console.error(e);
        }
    }

    compare(calculatedResources, calculatedResourcesCompare) {
        let resources = JSON.parse(JSON.stringify(calculatedResources)),
            resourcesCompare = JSON.parse(JSON.stringify(calculatedResourcesCompare));
        let compared = {};

        // находим общие ресурсы и ресурсы относящие к первому объекту сравнения
        Object.keys(resources).forEach(res => {
            compared[res] = resources[res];

            if (resourcesCompare.hasOwnProperty(res)) {
                // вычисляем разницу между длинами префиксов
                Object.keys(resources[res].prefixes).forEach(prefix => {
                    if (!resourcesCompare[res].prefixes.hasOwnProperty(prefix))
                        return;
                    
                    // Длина пути для сравнения
                    if (typeof resourcesCompare?.[res]?.prefixes?.[prefix] !== "undefined") {
                        compared[res].prefixes[prefix].viewsCompared = resourcesCompare[res].prefixes[prefix].views;
                    }

                    // разница между средними длинами префиксов до группы точек local/global/regional
                    let avgPathToGroupDiff = {};
                    Object.keys(compared[res].prefixes[prefix].avgPathToGroup).forEach(group => {
                        let prevViewPath = typeof resources[res]?.prefixes?.[prefix]?.avgPathToGroup?.[group] === "undefined" ? 0 : parseFloat(resources[res].prefixes[prefix].avgPathToGroup[group]);
                        let nextViewPath = typeof resourcesCompare[res]?.prefixes?.[prefix]?.avgPathToGroup?.[group] === "undefined" ? 0 : parseFloat(resourcesCompare[res].prefixes[prefix].avgPathToGroup[group]);
                        avgPathToGroupDiff[group] = Number((prevViewPath - nextViewPath).toFixed(2));
                    })
                    compared[res].prefixes[prefix].avgPathToGroupDiff = avgPathToGroupDiff;


                    Object.keys(this.state.allViewPoints[`v${this.props.ipv}`]).forEach(view => {
                        let prevViewPath = typeof resources[res]?.prefixes[prefix]?.views[view]?.aspath === "undefined" ? 0 : parseFloat(resources[res].prefixes[prefix].views[view].aspath);
                        let nextViewPath = typeof resourcesCompare[res]?.prefixes[prefix]?.views[view]?.aspath === "undefined" ? 0 : parseFloat(resourcesCompare[res].prefixes[prefix].views[view].aspath);


                        if (typeof compared[res].prefixes[prefix] === "undefined")
                            compared[res].prefixes[prefix] = {};

                        if (typeof compared[res].prefixes[prefix].views[view] === "undefined")
                            compared[res].prefixes[prefix].views[view] = {};

                        compared[res].prefixes[prefix].views[view].aspathDiff = Number((prevViewPath - nextViewPath).toFixed(2));
                    })
                })
            } else {
                compared[res].isOldReport = false;
            }
        })

        // находим ресурсы относящие ко второму объекту сравнения
        Object.keys(resourcesCompare).forEach(res => {

            if (!resources.hasOwnProperty(res)) {
                compared[res] = resourcesCompare[res];
                compared[res].isOldReport = true;
            }
        })

        return compared;
    }

    setResourcesFilter(preparedResources) {
        try {
            let filterResources = localStorage.getItem("filterResources"),
                filterVP = localStorage.getItem("filterViewPoints");

            if (filterResources && Object.keys(filterResources).length) {
                // сопоставим данные фильтра по ресурсам из localStorage с текущим
                filterResources = JSON.parse(filterResources);
                filterVP = JSON.parse(filterVP);

                Object.keys(filterResources).forEach(res => {
                    // проверяем ресурсы
                    if (!preparedResources?.[res]) return;
                    let prepResource = preparedResources[res],
                        localResource = filterResources[res];

                    prepResource.checked = localResource.checked;

                    // проверяем префиксы
                    if (!prepResource?.prefixes || !localResource?.prefixes) return;
                    let prepPrefixes = prepResource.prefixes,
                        localPrefixes = localResource.prefixes;

                    Object.keys(localPrefixes).forEach(prefix => {
                        if (!prepPrefixes?.[prefix]) return;
                        prepPrefixes[prefix].checked = localPrefixes[prefix].checked;

                        if (!prepPrefixes[prefix]?.views || !filterVP || !Object.keys(filterVP).length) return;
                        // сопоставим точки наблюдения
                        Object.keys(localPrefixes[prefix].views).forEach(view => {
                            if (!prepPrefixes[prefix].views?.[view]) return;
                            prepPrefixes[prefix].views[view].checked = localPrefixes[prefix].views[view].checked;
                        })
                    })

                })
            }

            return preparedResources;
        } catch (e) {
            console.error(e);
        }
    }

    setViewPointsFilter(viewPoints) {
        try {
            let filterVP = localStorage.getItem("filterViewPoints");

            if (filterVP && Object.keys(filterVP).length) {

                filterVP = JSON.parse(filterVP);
                // проверим были ли чекнуты точки наблюдения в фильтре
                Object.keys(filterVP).forEach(group => {
                    if (!viewPoints?.[group]) return;
                    viewPoints[group].checked = filterVP[group].checked;

                    if (!viewPoints[group]?.items) return;

                    Object.keys(filterVP[group].items).forEach(view => {
                        if (!viewPoints[group].items?.[view]) return;

                        viewPoints[group].items[view].checked = filterVP[group].items[view].checked;
                    })
                })

            }

            return viewPoints;
        } catch (e) {
            console.error(e);
        }
    }

    reportDateChange(selectedDay, modifiers, dayPickerInput) {
        localStorage.setItem("currentDate", selectedDay.toString());
        this.setState({
            currentDate: selectedDay
        });
    }

    compareDateChange(selectedDay, modifiers, dayPickerInput) {
        // TODO ПРИМЕНЯТЬ ТЕКУЩИЙ ФИЛЬТР СО СРАВНЕНИЕМ, UPD: вероятно не понадобится
        //this.setFilterToCompare();

        localStorage.setItem("compareDate", selectedDay.toString());
        this.setState({
            compareDate: selectedDay
        });
    }

    isCompare(e) {
        this.setState({
            isCompare: e.currentTarget.checked
        })

        this.props.setFilter({
            ...this.props.filterData,
            isCompare: e.currentTarget.checked
        })
    }

    reportChange(reportId, date) {
        this.setState({
            ...this.state,
            currentReport: reportId,
            currentDate: date || this.state.currentDate,
        });
    }

    render() {
        return (
            <section className="report-detail">
                <section className="report-detail__header">
                    <h1 className="main-title main-title--report-detail">
                        Пул
                    </h1>
                    {this.props.user.isLogin &&
                    <ReportSelector />
                    }
                    <IpToggle component="Report"/>
                    {(this.state.reportReady && !this.state.reportEmpty) &&
                    <Fragment>
                    <CopyButton copyValue={document.location.origin + document.location.pathname + "?hash=" + (localStorage.getItem("reportHash") || localStorage.getItem('tmpReportHash'))}/>
                    </Fragment>
                    }
                </section>
                {(this.state.reportReady && this.props.user.isLogin) &&
                    <Fragment>
                        <section className="report-detail__date-container">
                            <div className="report-detail__date">
                                Дата:
                                <DayPickerInput
                                    inputProps={{readOnly: true}}
                                    value={Utils.formatDate(this.state.currentDate || new Date())}
                                    formatDate={DayPickerEssentials.formatDate}
                                    parseDate={DayPickerEssentials.parseDate}
                                    dayPickerProps={{
                                        locale: DayPickerEssentials.lang,
                                        localeUtils: DayPickerEssentials.localeUtils,
                                        numberOfMonths: DayPickerEssentials.months,
                                        modifiers: {
                                            disabled: [
                                                {
                                                    before: new Date(this.state.reportCreateDate),
                                                    after: new Date()
                                                }
                                            ]
                                        }
                                    }}
                                    onDayChange={this.reportDateChange}
                                    placeholder={'Выбрать дату отчета'}
                                />
                            </div>
                            {!this.state.reportEmpty && (
                                <Fragment>
                                    <div className={`report-detail__date ${this.state.isCompare ? "" : "disabled"} ${this.state.compareDate ? '' : 'no-value'}`}>
                                        <label className="filter__label">
                                            <input
                                                type="checkbox"
                                                className="visually-hidden"
                                                checked={this.state.isCompare}
                                                onChange={this.isCompare}
                                            />
                                            <span className="checkbox compare"></span>
                                        </label>
                                        <DayPickerInput
                                            inputProps={{readOnly: true, disabled: !this.state.isCompare}}
                                            value={this.state.compareDate ? Utils.formatDate(this.state.compareDate) : ""}
                                            formatDate={DayPickerEssentials.formatDate}
                                            parseDate={DayPickerEssentials.parseDate}
                                            dayPickerProps={{
                                                locale: DayPickerEssentials.lang,
                                                localeUtils: DayPickerEssentials.localeUtils,
                                                numberOfMonths: DayPickerEssentials.months,
                                                modifiers: {
                                                    disabled: [
                                                        {
                                                            before: new Date(this.state.reportCreateDate),
                                                            after: new Date(this.state.currentDate - 86400000)
                                                        }
                                                    ]
                                                }
                                            }}
                                            onDayChange={this.compareDateChange}
                                            placeholder={'Сравнить с другой датой'}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            <div className="xs xs-landscape-hide">
                                <CopyButton
                                    copyValue={document.location.origin + document.location.pathname + "?hash=" + (localStorage.getItem("reportHash") || localStorage.getItem('tmpReportHash'))}/>
                            </div>
                        </section>
                    </Fragment>
                }

                {(!this.state.reportEmpty && !this.state.reportReady) && <Loader class="Loader"/>}
                {('reportReady' in this.state && !this.state.reportReady) && <span>Ваш отчет формируется, зайдите позднее.</span>}
                {(this.state.reportEmpty && this.state.reportReady) && <span>В отчете отсутствуют данные.</span>}

                <section className="report-detail__graphics report-detail__graphics--report">
                    {!this.state.reportEmpty && <RadarChart data={this.state.radarData} ipv={this.props.ipv}/>}
                    {/*<SunburstChart data={this.state.sunburstData}/>*/}
                </section>
                <section className="report-detail__info">
                    <Fragment>
                        <ReportTableCompare data={this.state.isCompare && this.state.compareDate ? this.state.comparedResources : this.state.calculatedResources} viewPoints={this.props.filterData.viewPoints} isCompare={this.state.isCompare}/>
                    </Fragment>
                </section>
            </section>
        );
    }
}

const mapStateToProps = state => {
    return {
        filterData: state.filter,
        user: state.user,
        curReportId: state.curReportId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setFilter: (data) => dispatch(filterActions.setFilter(data)),
        setFilterEmpty: payload => dispatch(filterActions.filterEmpty(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Report));