import Axios from "axios";
import {API_URL} from "../../redux/constants";
import * as ReportAPI from "../api/Report";
import ajaxCache from "./AjaxCache";

export const create = async (reportid) => {
    try {
        const res = await ajaxCache.post(
            `${API_URL}/api/pool/create/`,
            new URLSearchParams({
                reportid: reportid
            }),
            {
                withCredentials: true
            }
        );

        if (res.data.status !== 'ok') {
            console.error("create pool API error");
        }

        // add poolId to LocalStorage
        if (res.data.status === 'ok' && res.data.data.poolid > 0)
            localStorage.setItem('poolId', res.data.data.poolid);

        return res.data;
    } catch (error) {
        console.error(`create pool API error ${error}`);
    }
}

// список пулов с ресурсами в них
export const getList = async (poolId) => {
    try {
        const res = await ajaxCache.get(
            `${API_URL}/api/pool/get/${poolId ? "?poolid=" + poolId : ""}`,
            {
                withCredentials: true
            }
        );

        return res.data;
    } catch (error) {
        console.error(`PoolAPI getList error ${error}`);
    }
}

// добавление ресурсов к пулу
export const addResource = async (poolId, resource) => {
    let result = [];

    try {
        const res = await ajaxCache.post(
            `${API_URL}/api/pool/addresource/`,
            new URLSearchParams({
                poolid: poolId,
                resource: JSON.stringify(resource)
            }),
            {
                withCredentials: true
            }
        );

        result = res.data;

        return result;
    } catch (error) {
        console.error(`Pool addResource API error ${error}`);
    }
}

// удаление ресурсов из пула
export const deleteResource = async (poolId, resource) => {
    try {
        const res = await ajaxCache.post(
            `${API_URL}/api/pool/delresource/`,
            new URLSearchParams({
                poolid: poolId,
                resource: JSON.stringify(resource)
            }),
            {
                withCredentials: true
            }
        );

        return res.data;
    } catch (error) {
        console.error(`Pool deleteResource API error ${error}`);
    }
}

// обновление имени/коммента ресурса
export const updateResource = async (poolId, resource) => {
    try {
        const res = await ajaxCache.post(
            `${API_URL}/api/pool/updateresource/`,
            new URLSearchParams({
                poolid: poolId,
                resource: JSON.stringify(resource)
            }),
            {
                withCredentials: true
            }
        );

        ajaxCache.refresh();

        return res.data;
    } catch (error) {
        console.error(`Pool deleteResource API error ${error}`);
    }
}

// метод записывает poolId, reportId в localStorage для АВТОРИЗОВАННЫХ ПОЛЬЗОВАТЕЛЕЙ
export const setPoolReport = async () => {
    try {
        let result = {
            reportId: localStorage.getItem("reportId"),
            poolId: localStorage.getItem("poolId")
        };
        const reportList = await ReportAPI.getList();

        // получение ID отчета
        if (!localStorage.getItem("reportId")) {
            // если у пользователя нет отчета, сгенерим
            if (reportList.hasOwnProperty("data")) {
                if (reportList.data.length) {
                    result.reportId = reportList.data[0].report_id;
                    localStorage.setItem("reportId", reportList.data[0].report_id);
                    localStorage.setItem("reportHash", reportList.data[0].hash);
                    document.location.reload();
                } /*else {
                    await ReportAPI.create();
                }*/
            }/* else {
                await ReportAPI.create();
            }*/
        }
        
        // проверим наличие пула
        if (!localStorage.getItem("poolId")) {
            if (result.reportId) {
                const currentReport = reportList.data.filter(el => el.report_id === +result.reportId);
                if(currentReport.length) {
                    result.poolId = currentReport[0].pool_id;
                    localStorage.setItem("poolId", result.poolId);
                }
            } else {
                const poolList = await getList();

                // если у пользователя нет пула, сгенерим
                if (poolList.hasOwnProperty("data") && poolList.data.hasOwnProperty("pool_id")) {
                    result.poolId = poolList.data.pool_id;
                    localStorage.setItem("poolId", poolList.data.pool_id);
                }
            }
        }

        return result;

    } catch (error) {
        console.error(`PoolAPI setPoolReport error ${error}`);
    }
}

// удаление пула
export const deletePool = async (poolId) => {
    try {
        const res = await Axios.post(
            `${API_URL}/api/pool/delete/`,
            new URLSearchParams({
                poolid: poolId
            }),
            {
                withCredentials: true
            }
        );

        return res.data;
    } catch (error) {
        console.error(`Pool deleteResource API error ${error}`);
    }
}
