import { combineReducers } from "redux";

import captchaReducer from "./captchaReducer";
import userReducer from "./userReducer";
import modalReducer from "./modalReducer";
import filterReducer from "./filterReducer";
import ipvReducer from "./ipvReducer";
import viewPointsReducer from "./viewPointsReducer";
import reportReducer from "./reportReducer";
import switchReducer from "./switchReducer";
import datePickerReducer from "./datePickerReducer";
import prefixSortReducer from "./prefixSortReducer"
import prefixDirectionReducer from "./prefixDirectionReducer"
import showSortPageReducer from "./showSortPageReducer";
import monitoringScrollYReducer from "./monitoringScrollYReducer";
import monitoringSortReducer from "./monitoringSortReducer";
import monitoringDirectionReducer from "./monitoringDirectionReducer";
import monitoringLastActive from "./monitoringLastActive";
import justRegisterReducer from "./justRegisterReducer";

export default combineReducers({
    user: userReducer,
    captcha: captchaReducer,
    modal: modalReducer,
    filter: filterReducer,
    ipv: ipvReducer,
    viewPoints: viewPointsReducer,
    curReportId: reportReducer,
    checked: switchReducer,
    compareDate: datePickerReducer,
	prefixColumn: prefixSortReducer,
    monitoringColumn: monitoringSortReducer,
	prefixDirection: prefixDirectionReducer,
    monitoringDirection: monitoringDirectionReducer,
    showSortPage: showSortPageReducer,
    monitoringScrollY: monitoringScrollYReducer,
    monitoringLastActive: monitoringLastActive,
    justRegister: justRegisterReducer,
});
