// Формирование ресурсов в фильтре

import React, {Fragment} from "react";
import * as filterActions from "../../../redux/actions/filterActions";
import {connect} from "react-redux";

class ResourcesGroup extends React.Component {
    constructor(props) {
        super(props);

        this.bindPrefixToReport = this.bindPrefixToReport.bind(this);
        this.bindGroupToReport = this.bindGroupToReport.bind(this);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return JSON.stringify(nextProps.resource) !== JSON.stringify(this.props.resource)
            || nextProps.resource.checked !== this.props.resource.checked;
    }

    bindPrefixToReport(e) {
        let prefix = e.currentTarget.getAttribute("data-prefix"),
            filterData = JSON.parse(JSON.stringify(this.props.filterData)),
            filterKeys = ["resources", "resourcesCompare"];

        filterKeys.forEach(key => {
            if (filterData.hasOwnProperty(key)
                && filterData[key].hasOwnProperty(this.props.resource.resource)
                && filterData[key][this.props.resource.resource].prefixes.hasOwnProperty(prefix)
            )
                filterData[key][this.props.resource.resource].prefixes[prefix].checked = e.currentTarget.checked;
        })

        // is all prefixes checked?
        /*let allChecked = true;
        Object.keys(filterData.resources[this.props.resource.name].prefixes).map(prefix => {
            if (!filterData.resources[this.props.resource.name].prefixes[prefix].checked) {
                allChecked = false;
                return false;
            }
        })

        filterData.resources[this.props.resource.name].checked = allChecked;
        */

        // update global state
        this.props.updateFilter(filterData);
    }

    bindGroupToReport(e) {
        let isChecked = e.currentTarget.checked,
            filterData = JSON.parse(JSON.stringify(this.props.filterData)),
            filterKeys = ["resources", "resourcesCompare"];

        // update global state
        filterKeys.forEach(key => {
            if (filterData.hasOwnProperty(key)
                && filterData[key].hasOwnProperty(this.props.resource.resource)
                && filterData[key][this.props.resource.resource].hasOwnProperty("prefixes")
            ) {
                filterData[key][this.props.resource.resource].checked = isChecked;
                /*Object.keys(filterData[key][this.props.resource.name].prefixes).map(prefix => {
                    filterData[key][this.props.resource.name].prefixes[prefix].checked = isChecked;
                })*/
            }
        })

        this.props.updateFilter(filterData);
    }

    render() {
        let resource = this.props.resource;

        return (
            <Fragment>
            <li className={`filter__item ${resource.checked === true ? "filter__item--expanded" : ""}`}>
                <div title={resource.name} className="filter__item-header" data-resource={resource.resource}>
                    <label className="filter__label">
                        <input type="checkbox"
                               checked={resource.checked}
                               className="visually-hidden"
                               onChange={this.bindGroupToReport}
                        />
                        <span className="checkbox"></span>
                        <span
                        className={`filter__item-title ${Object.keys(resource.prefixes).length > 0 && "filter__item-title--nested"} ${resource.checked === true ? "filter__item-title--expanded" : ""}`}>{resource.resource}</span>
                    </label>
                </div>

                {Object.keys(resource.prefixes).length > 0 &&
                <ul className={`filter__list filter__list--nested ${resource.checked === true && "filter__item-title--expanded"}`}>
                    {
                        Object.keys(resource.prefixes).map((prefix, key) => {
                            return (
                                <li key={key} className="filter__item">
                                    <div className="filter__item-header">
                                        <label className="filter__label">
                                            <input
                                                type="checkbox"
                                                className="visually-hidden"
                                                checked={resource.prefixes[prefix].checked}
                                                onChange={this.bindPrefixToReport}
                                                data-prefix={prefix}
                                            />
                                            <span className="checkbox"></span>
                                            <span className="filter__item-title">{prefix}</span>
                                        </label>
                                    </div>
                                    {/*<span className="filter__location">Москва-ФС</span>*/}
                                </li>
                            );
                        })
                    }
                </ul>
                }
            </li>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateFilter: (data) => dispatch(filterActions.updateFilter(data))
    };
};

export default connect(null, mapDispatchToProps)(ResourcesGroup);