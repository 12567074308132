import {SET_PREFIX_COLUMN} from '../constants';

export default (state = {}, action) => {
    const { type, payload } = action;
    if (type === SET_PREFIX_COLUMN) {
		localStorage.prefixColumn = payload;
		
        return payload;
    }
	
	return localStorage.prefixColumn ? localStorage.prefixColumn : '';
};