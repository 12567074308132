import * as UserAPI from "../../helpers/api/User";
import {GET_USER, RECEIVED_USER, LOGOUT_USER, NOT_AUTHORIZED, SET_TMP_MODE} from "../constants";
import * as PoolAPI from "../../helpers/api/Pool"
import * as ReportAPI from "../../helpers/api/Report";
import * as Utils from "../../helpers/Utils";

export const getUser = () => {
    return async (dispatch) => {
        let hash = localStorage.getItem("tmpReportHash");
		
        try {
            const response = await UserAPI.get();
            const {status, data} = response.data;

            if (localStorage.getItem('userId') && data?.userid) {
                if (+localStorage.getItem('userId') !== data.userid) {
                    localStorage.removeItem('userId');
                    localStorage.removeItem('reportId');
                    localStorage.removeItem('poolId');
                }
            }

            if (status === 'error')
                return dispatch(notAuthorized({
                    hasReport: Boolean(hash),
                    isLogin: false,
                    tmpMode: true
                }));

            const transformData = {
                ...data,
                reports_freq: !data.reports_freq ? "0_1" : data.reports_freq, 
                isLogin: true,
                tmpMode: data.tmp_user
            };

            // привязка временного отчета к зареганному пользователю
            if (hash) {
                const tmpReport = await ReportAPI.saveTmpReport(hash, data.userid);
                if (!Utils.checkResponse(tmpReport, false))
                    return;

                // add report id to localStorage
                if (tmpReport.data.reportid) {
                    localStorage.setItem('reportId', tmpReport.data.reportid);
                    localStorage.removeItem('tmpReportHash');
                }
            }

            let reportData = await PoolAPI.setPoolReport();
            transformData.hasReport = Boolean(reportData.reportId);

            dispatch({type: GET_USER});
            dispatch(receivedUser(transformData))
            return transformData;
        } catch (e) {
            console.error(e);
            return dispatch(notAuthorized({
                hasReport: Boolean(hash),
                isLogin: false
            }));
        }
    };
};

export const receivedUser = (data) => {
    return {
        type: RECEIVED_USER,
        payload: data,
    };
};

export const notAuthorized = (data) => {
    return {
        type: NOT_AUTHORIZED,
        payload: data
    }
}

export const logoutUser = (data) => {
    return {
        type: LOGOUT_USER,
        payload: data,
    }
}

export const setTmpMode = (data) => {
    return {
        type: SET_TMP_MODE,
        payload: data
    }
}
