export default () => {
    const unit = 95;
    const divs = document.getElementsByClassName('prefix__data-grid__item');
    let maxScrollWidth = 0;
    // Нашли максимальную длину путей
    for (let div of divs) {
        let dataCount = div.children[1].dataset.count;
        div.children[1].style.width = 5 * unit + 'px';
        div.children[1].style.borderRadius = '7px';
        div.children[1].style.border = '1px solid lightgray';
        div.children[1].style['overflow-x'] = 'auto';
        div.children[1].style['overflow-y'] = 'hidden';
        dataCount = (dataCount === 1) ? (2) : dataCount;
        maxScrollWidth = (dataCount > maxScrollWidth) ? dataCount : maxScrollWidth;
    }
    maxScrollWidth = (maxScrollWidth > 5) ? 5 : maxScrollWidth;
    maxScrollWidth = (maxScrollWidth < 2) ? 2 : maxScrollWidth;
    const containerWidth = document.getElementsByClassName('container')[1].clientWidth;
    let restWidth, full;
    if (divs[0]){
        restWidth = containerWidth - 400 - 49;
    }
    if (restWidth > (maxScrollWidth * unit)){
        restWidth = maxScrollWidth * unit;
    }
    if (restWidth < (2 * unit)) {
        restWidth = containerWidth;
        full = true;
    }
    for (let div of divs){
        let dataCount = div.children[1].dataset.count;
        div.children[1].style.width = restWidth  + 'px';
        if (dataCount > maxScrollWidth){
            div.children[1].style.overflow = 'auto';
        }
    }
    const as_path = document.getElementsByClassName('prefix-info__control__desktop')[0].children[0].children[1].children[3];
    if (!full) {
        as_path.style.marginLeft = restWidth / 2 - 12 + 'px';
    } else {
        as_path.style.marginLeft = 'auto';
    }
    if (containerWidth < 520){
        document.getElementsByClassName('prefix-info__control__desktop')[0].children[0].style.overflow = 'auto';
    } else {
        //document.getElementsByClassName('prefix-info__control__desktop')[0].children[0].style.overflow = 'auto';
    }
}
